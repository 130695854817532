import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { switchMap, tap, timer } from 'rxjs';
import { PaymentGatewayResolver } from '../appResolvers/payment-gateway.resolver';
import { IStore } from '../appModels/IStore';
import { StoreResolver } from '../appResolvers/store.resolver';
import { CashMgmtService } from './cash-mgmt.service';
import { RestaurantInfoResolver } from '../appResolvers/restaurant-info.resolver';


@Injectable({
  providedIn: 'root'
})
export class InstantDepositeSchedularService {

  selectedStore: IStore;

  // Configurable times in hours
  private firstCallHour: number = 16; // 4 PM
  // private secondCallHour: number = 19; // 9 PM
  private secondCallHour: number = 21; // 9 PM
  private isTesting: boolean = false; // Set this to false for actual use
  isInstantDepositEnabled: boolean = false;

  constructor( 
    private paymentGatewayResolver: PaymentGatewayResolver,
    private _storeResolver: StoreResolver,
    private cashMgmtService: CashMgmtService,
    private _basicInfoResolver: RestaurantInfoResolver,
  ) {
  }
  
  initInstantDepositeSchedular(){
    this.subscribeBasicInfo();
  }

  /**
   * subscribe basic info
   */
  subscribeBasicInfo() {
    this._basicInfoResolver.restaurantBasicInfo.subscribe(
      (basicInfo) => {
        if (basicInfo?.businessType !== 'cannabis'){
          this.subscribeSelectedStore();
        }
      }
    );
  }


  /**
   * Subscribe selected store
   */
  subscribeSelectedStore() {
    this._storeResolver.selectedStore.subscribe((res) => {
      if (res) {
        this.selectedStore = res;
        this.getInstantDepositConfig(this.selectedStore.id)
      }
    });
  }


  /**
   * Get instant deposit config
   */
  getInstantDepositConfig(storeId:number){
    this.cashMgmtService.getInstantDepositConfig(storeId).subscribe(
      (res) => {
        this.isInstantDepositEnabled = res.value === '1';
        this.scheduleSync();
      }
    );
  }

  

  scheduleSync() {
    this.scheduleDailyAt(this.firstCallHour).subscribe(() => {
      this.instantDepositDailySchedular();
    });

    this.scheduleDailyAt(this.secondCallHour).subscribe(() => {
      this.instantDepositDailySchedular();
    });
  }

  private scheduleDailyAt(hour: number) {
    return timer(0, 60 * 1000).pipe(
      switchMap(() => {
        const now = this.isTesting ? moment().tz('Asia/Kolkata') : moment().tz('America/Los_Angeles');
        const target = this.isTesting ? moment().tz('Asia/Kolkata').set({ hour, minute: 0, second: 0, millisecond: 0 }) 
                                      : moment().tz('America/Los_Angeles').set({ hour, minute: 0, second: 0, millisecond: 0 });

        const isTargetTime = now.hour() === target.hour() && now.minute() === target.minute();

        return isTargetTime ? timer(0) : timer(60 * 1000);
      }),
      tap(() => console.log(`Scheduled task for hour ${hour} executed at ${moment().tz(this.isTesting ? 'Asia/Kolkata' : 'America/Los_Angeles').format()}`))
    );
  }



   


  /**
   * Instant deposit daily schedular
  */
 private instantDepositDailySchedular(){
    if(!this.isInstantDepositEnabled){
      return;
    };
    this.paymentGatewayResolver.instantDepositDailySchedular(this.selectedStore.id).subscribe(
      (res) => {
      },
      (err) => {
        console.error(err);
      }
    );
  }
}
