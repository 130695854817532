import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UserService } from 'src/app/appServices/user/user.service';
import { ToastMessageService } from 'src/app/core/utilities/toast-message.service';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';
import { environment } from 'src/environments/environment';
import { IBasicInfo } from 'src/app/appModels/IBasicInfo';
import { RestaurantInfoResolver } from 'src/app/appResolvers/restaurant-info.resolver';
import { IApiResponse } from 'src/app/appModels/IApiResponse';

@Component({
  selector: 'app-text-customer-modal',
  templateUrl: './text-customer-modal.component.html',
  styleUrls: ['./text-customer-modal.component.scss'],
})
export class TextCustomerModalComponent implements OnInit {
  @Input() customers: any[];

  messageTemplates = [
    {
      label: 'Recall Notification 1',
      content: 'Dear customer, we received a notification from the State of New Mexico Regulation Department that an item you purchased at {store} has been recalled. Please call us at {phone} for more information.',
    },
    {
      label: 'Recall Notification 2',
      content: 'Important: A product you bought from {store} has been recalled according to the State of New Mexico Regulation Department. Contact us at {phone} immediately for details.',
    },
    {
      label: 'Recall Notification 3',
      content: 'Attention! We were informed by the State of New Mexico Regulation Department about a recall on a product you purchased at {store}. Please reach out to us at {phone} as soon as possible.',
    }
  ];

  selectedMessageTemplate: any;
  message: string = '';
  isAcknowledged: boolean = false;
  storePhoneNumber: string = '';
  storeEmail: string = '';
  includeEmail: boolean = true;
  storeName: string = '';
  basicInfo: IBasicInfo;
  selectedCustomer: any;

  constructor(
    private modalCtrl: ModalController,
    private userService: UserService,
    private toastMessageService: ToastMessageService,
    private _basicInfoResolver: RestaurantInfoResolver,
  ) {}

  ngOnInit() {
    // Subscribe to basic info and set the values
    this.subscribeBasicInfo();

    // Console log the environment and input customers
    console.log('Environment:', environment.production ? 'Production' : 'Development/Testing');
    console.log('Input Customers before environment check:', this.customers);

    // Environment check to ensure only Gonzalo is texted in non-production environments
    if (!environment.production) {
      this.customers = [{
        mobileNumber: '5754897070',
        firstName: 'Gonzalo',
        lastName: 'Chavez',
        purchasedProducts: [
          {
            barcodeId: "6537977194908115",
            productName: "1G Disp. Ice Cream Cake"
          },
          {
            barcodeId: "9728203734013977",
            productName: "1G Disp. Bubba Gum"
          }
        ]
      }]; // Gonzalo's number with dummy products
    }

    // Console log the customers after environment check
    console.log('Customers after environment check:', this.customers);

    this.selectedMessageTemplate = this.messageTemplates[0];
    this.updateMessage();
  }


  subscribeBasicInfo() {
    this._basicInfoResolver.restaurantBasicInfo.subscribe(res => {
      this.basicInfo = res;
      this.storeName = this.basicInfo.restaurantName || 'our store';
      this.storePhoneNumber = this.basicInfo.mobileNumber || '';
      this.storeEmail = this.basicInfo.emailId || '';
      this.updateMessage(); // Update the message after setting the basic info
    });
  }

  updateMessage() {
    const phone = this.storePhoneNumber;
    let message = this.selectedMessageTemplate.content
      .replace('{store}', this.storeName)
      .replace('{phone}', phone);

    // Conditionally include the email in the message based on the checkbox state
    if (this.includeEmail && this.storeEmail) {
      message = message.replace('for more information.', `or email us at ${this.storeEmail} for more information.`);
    }

    this.message = message;
  }

  sendText() {
    if (this.isAcknowledged && this.message.trim()) {
      const mobileNumbers = this.customers.map(customer => {
        let mobileNumber = customer.mobileNumber;

        // Remove country code by keeping only the last 10 digits
        mobileNumber = mobileNumber.replace(/^\D*(\d{10})$/, '$1');

        return mobileNumber;
      });

      console.log(mobileNumbers);
      // Send the SMS notifications first
      this.userService.sendRecallNotification(this.message, mobileNumbers).subscribe(
        (response: IApiResponse<any>) => {
          console.log(response);
          if (response.success) {
            this.toastMessageService.successToast('Message sent successfully');
          } else {
            this.toastMessageService.errorToastWithClose(response.message || 'Failed to send message');
          }
          this.sendEmailNotification(response.success); // Send email notification after SMS is sent or failed
          this.closeModal();
        },
        (error) => {
          this.toastMessageService.errorToastWithClose('Failed to send message');
          this.sendEmailNotification(false); // Send email notification if SMS fails
        }
      );
    }
  }


  // Method to send email notification after SMS is sent or failed
  private sendEmailNotification(success: boolean): void {
    console.log(this.customers);
    const customerDetails = this.customers.map(customer => {
      // Check if purchasedProducts is defined and is an array
      const productDetails = (customer.purchasedProducts || []).map(product => {
        return `Product: ${product.productName}, Barcode ID: ${product.barcodeId}`;
      }).join('\n');

      return `
        Customer: ${customer.firstName} ${customer.lastName}
        Mobile Number: ${customer.mobileNumber}
        Purchased Products:
        ${productDetails ? productDetails : 'No purchased products available.'}
      `;
    }).join('\n\n');

    const reportContent = `
      Store ${this.storeName} (${this.storeEmail}) has ${success ? 'successfully' : 'unsuccessfully'} sent recall notifications.
      Customer Details:
      ${customerDetails}
      Acknowledgement: ${this.isAcknowledged ? 'Accepted' : 'Declined'}
      Timestamp: ${new Date().toLocaleString()}
    `;

    const subject = `Recall Notification ${success ? 'Success' : 'Failure'}`;

    const templateParams = {
      subject: subject,
      reportContent: reportContent
    };

    emailjs.send('service_r2e7qet', 'template_frmv0ft', templateParams, 'P6dUNR0zfjoIGYgH4')
      .then((response: EmailJSResponseStatus) => {
        console.log('Email sent successfully', response);
      }, (error) => {
        console.error('Error sending email', error);
        this.toastMessageService.errorToastWithClose('Failed to send email notification');
      });
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
