import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpConfigService } from '../core/httpConfig/httpConfigService';
import { environment } from 'src/environments/environment';
import { IStore } from '../appModels/IStore';
import { StoreResolver } from '../appResolvers/store.resolver';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CdsService {
  store: IStore;
  api = this.apiConfig.getBaseUrl;
  activeOrder = new BehaviorSubject(null);
  constructor(
    private apiConfig: HttpConfigService,
    private _storeResolver: StoreResolver
    ) {
      this.subscribeSelectedStore();
  }


  
  /**
   * Subscribe Selected Store 
   */
   subscribeSelectedStore() {
    this._storeResolver.selectedStore.subscribe(res =>{
      this.store = res;
    });
  }


  /**
   * get all routes
   * @returns 
   */
  getAllRoutes(): Observable<any> {
    return this.apiConfig.get(this.api + 'kds-route-config');
  }

  /**
   * get order status list
   * @returns 
   */
  getOrderStatusList(): Observable<any> {
    return this.apiConfig.get(this.api + 'orderStatus/getAll');
  }

  /**
* get store Id
*/
  getStoreId() {
    return this.store?.id || null;
  }

  getOrdersByRouteAndStatus(routeName, status, storeId): Observable<any> {
    const from = this.getCurrentDate().getTime();
    const to = this.getTimeSpanToDays(1).getTime();
    return this.apiConfig.get(this.api + 'order/' + from + '/' + to + '/' + routeName + '/' + status + '?storeId=' + storeId, { withCredentials: true }).pipe(take(1));
  }


  getTimeSpanToDays(days: number): Date {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const calculatePreviousDate = new Date(today);
    calculatePreviousDate.setDate(calculatePreviousDate.getDate() + days);
    return calculatePreviousDate;
  }
  getCurrentDate(): Date {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  }
}
