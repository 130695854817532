import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { MenuResolver } from 'src/app/appResolvers/menu.resolver';
import { GlobalAuthResolver } from 'src/app/appServices/global-auth/global-auth.resolver';
import { ThemeService } from 'src/app/appServices/theme.service';
import { BreakpointService } from '../utilities/breakpoint.service';
import { CheckForUpdateService } from '../utilities/check-for-update.service';
import { InstantDepositeSchedularService } from 'src/app/appServices/instant-deposite-schedular';

@Injectable({
  providedIn: 'root',
})
export class AppInitializerService {
  constructor(
    private platform: Platform,
    private _breakpointService: BreakpointService,
    private _sw: CheckForUpdateService,
    private _globalAuthResolver: GlobalAuthResolver,
    private themeService: ThemeService,
    private instantDepositeSchedular: InstantDepositeSchedularService,
    private menuResolver: MenuResolver
  ) {}

  initializeApp() {
    this.platform.ready().then(() => {
      this.themeService.detectSystemTheme();
      this._breakpointService.onResize(this.platform.width());
      this.checkLogin();
    });
    this._sw.checkIfUpdateAvailable();
  }

  /**
   * check login
   */
  checkLogin() {
    const token = localStorage.getItem('token');
    if (token) {
      this._globalAuthResolver.loggedIn.next(true);
      this.instantDepositeSchedular.initInstantDepositeSchedular();
      // this.menuResolver.getAllMenus();
    }
  }
}
