import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IMenu } from '../appModels/IMenu';


@Injectable({
  providedIn: 'root'
})
export class MenuServices {

  api = environment.baseUrl;
  constructor(
    private httpClient: HttpClient
  ) { }


  /**
   * get all menu
   * @returns
   */
  getAllMenus(): Observable<any> {
    return this.httpClient.get(this.api+'menu');
  }

  getAllCategory(menuId: number): Observable<any> {
    return this.httpClient.get(this.api + "category/getAll/"+menuId);
  }

  
  /**
   * Get all products by page with category
   * @param categoryId 
   * @param pageSize 
   * @param pageIndex 
   * @param search 
   * @returns 
   */
  getAllProductsByPageWithCategory(categoryId:number, pageSize: number, pageIndex: number, search: string, menuId:number): Observable<any> {
    if(search.length>0){
      return this.httpClient.get(`${this.api}product/${pageSize}/${pageIndex}?search=${search}&menuId=${menuId}&client=cashier`);
    }
    return this.httpClient.get(`${this.api}product/${pageSize}/${pageIndex}?search=&categoryId=${categoryId}&client=cashier`);
  }

  getProductByProductId(productId: number): Observable<any> {
    return this.httpClient.get(this.api + 'product/' + productId);
  }


  /**
   * Get Reports (Counts)
   * @param menuId
   * @returns
   */
  getReports(menuId: number, count: string): Observable<any>{
    if(count===null){
      return this.httpClient.get(`${this.api}menu/report/${menuId}`);
    }
    return this.httpClient.get(`${this.api}menu/report/${menuId}/${count}`);
  }


  /**
   * create menu
   * @param menu
   * @returns
   */
  createMenu(menu: IMenu): Observable<any> {
    return this.httpClient.post(this.api+'menu', menu);
  }



  /**
   * update menu
   * @param menuId
   * @param name
   * @returns
   */
  updateMenuName(menuId: number, name: string): Observable<any> {
    return this.httpClient.patch(`${this.api}menu/${menuId}/${name}`, null);
  }


  /**
   * get menu by id
   * @returns
   */
  getMenuById(menuId:number): Observable<any> {
    return this.httpClient.get(`${this.api}menu/${menuId}`);
  }


  /**
   * delete menu
   * @param menuId
   * @returns
   */
  deleteMenu(menuId: number): Observable<any> {
    return this.httpClient.delete(`${this.api}menu/${menuId}`);
  }





  /**
   * Map menu with store
   * @param storeId
   * @param menuId
  */
  mapMenuWithStore(storeId: number, menuId: number) {
    return this.httpClient.patch(`${this.api}menu/mapStore/${storeId}/${menuId}`, null);
  }



  /**
   * Duplicate product
   * @param newMenuId
   * @param  productId
   * @returns
   */
  duplicateProduct(menuId: number, productId: number): Observable<any> {
    return this.httpClient.get(`${this.api}menu/duplicate/product/${menuId}/${productId}`);
  }

}
