import { Injectable } from '@angular/core';
import { ToastMessageService } from '../../core/utilities/toast-message.service';
import { RestaurantInfoResolver } from 'src/app/appResolvers/restaurant-info.resolver';
import { ReceiptPrinterService } from './receipt-printer.service';
import { HttpConfigService } from 'src/app/core/httpConfig/httpConfigService';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PrinterService {
  basicInfo: any;
  api =  this.apiConfig.getBaseUrl  + 'printer';

  constructor(
    private receiptPrinterService: ReceiptPrinterService,
    private toasterService: ToastMessageService,
    private _basicInfoResolver: RestaurantInfoResolver,
    private apiConfig: HttpConfigService
  ) {
    this.subscribeBasicInfo();
    if (window.electronAPI) {
      window.electronAPI.receive('execute-print-label', (data) => {
        this.handlePrintLabelData(data);
      });
    }
  }

  isElectronApp() {
    return window.electronAPI && window.electronAPI.isElectron();
  }

  private subscribeBasicInfo() {
    this._basicInfoResolver.restaurantBasicInfo.subscribe(
      (res) => {
        this.basicInfo = res;
      },
      (error) => {
        console.error('Error fetching basic info:', error);
      }
    );
  }




  /**
   * Print Reciept
   * @param orderId
   * @param change
   */
  printStuff(orderId: number, change?: any) {
    this.receiptPrinterService.printStuff(orderId, change);
  }




  /**
   * Get Printer List
   */
  getPrinterList(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      if (window.electronAPI.isElectron()) {
        window.electronAPI.send('get-printer-list');
        window.electronAPI.receive('printer-list', (printers) => {
          resolve(printers);
        });
      } else {
        reject('Electron is not available');
      }
    });
  }






  /**
   * Label Print
   * @param content
   */
  labelPrint(content: any, copies: number) {
    const storedPrinters = localStorage.getItem('printers');
    const printerList = storedPrinters ? JSON.parse(storedPrinters) : [];

    console.log("Stored Printers:", storedPrinters);
    console.log("Printer List:", printerList);

    let usbPrinterFound = false;

    // First, remove any existing listeners to prevent multiple triggers
    if (window.electronAPI) {
      window.electronAPI.receive('execute-print-label', () => { });
    }

    for (const printer of printerList) {
      if (printer.brand === 'Zebra') {
        if (printer.connectionType === 'ip' && printer.connected) {
          this.printZebra(content, printer);
          return;
        } else if (printer.connectionType === 'usb' && printer.connected) {
          usbPrinterFound = true;
          if (window.electronAPI) {
            const selectedPrinterName = printer.usbPrinter ? printer.usbPrinter.name : printer.deviceName;
            const printData = {
              content,
              selectedPrinterName,
              copies
            };

            // Send the print command
            window.electronAPI.send('print-label', printData);
            return; // Make sure to return to avoid setting up multiple listeners
          }
        }
      }
    }

    if (!usbPrinterFound) {
      this.toasterService.errorToastWithClose('Zebra printer not configured.');
    }
  }


  handlePrintLabelData(data) {
    const { zplData, copies, selectedPrinterName } = data;
    console.log('Selected Printer Name:', selectedPrinterName); // Log the selected printer name
    console.log('Expected Copies:', copies); // Log the expected number of copies

    if (window.BrowserPrint) {
      window.BrowserPrint.getLocalDevices(devices => {
        console.log('Devices:', devices);

        if (devices && devices.printer) {
          devices.printer.forEach(device => {
            console.log('Device Name:', device.name, 'Manufacturer:', device.manufacturer);
          });

          // Find a printer where the manufacturer includes 'Zebra'
          const printer = devices.printer.find(device => device.manufacturer.toLowerCase().includes('zebra'));

          if (printer) {
            console.log('Matched Printer:', printer); // Log the matched printer

            for (let i = 0; i < copies; i++) {
              console.log("Printing copy number: ", i + 1); // Log each iteration of the loop
              printer.send(zplData, undefined, error => {
                if (error) {
                  console.error('Print failed:', error);
                } else {
                  console.log('Print successful');
                }
              });
            }
          } else {
            console.error('Zebra printer not found in devices');
          }
        } else {
          console.error('No printers found in devices object');
        }

      }, error => {
        console.error('Error getting devices:', error);
      });
    } else {
      console.error('BrowserPrint is not installed or not accessible');
    }
  }

  printZebra(content: any, printer: any) {
    let zplData;
    // console.log(environment);
    // console.log("Non-Electron app logic for Zebra IP Printer");

    if (this.basicInfo.businessType === 'restaurant') {
      zplData = this.createRestaurantZPLData(content);
    } else {
      zplData = this.createZPLData(content);
    }

    this.sendToZebraPrinter(zplData, printer)
      .then(success => {
        if (!success) {
          this.toasterService.errorToastWithClose('Print failed.');
        }
      })
      .catch(error => {
        console.error('Printing error:', error);
        this.toasterService.errorToastWithClose('Printing error.');
      });
  }

  sendToZebraPrinter(zplData: any, printer: any) {
    return new Promise((resolve, reject) => {
      var http = new XMLHttpRequest();
      let url = "https://api.zebra.com/v2/devices/printers/send"; // Production endpoint
      // Hardcoded values for apiKey, tenant, and serialNumbers
      const apiKey = printer.apiKey;
      const tenant = printer.tenant;
      const serialNumbers = Array.isArray(printer.serialNumber) ? printer.serialNumber : [printer.serialNumber];
      // const apiKey = "0ZvjJ3ncv5eaF05tMr4VcbZUJtg4yzAH";
      // const tenant = "41225a1f93f5f765a2eec973085057f6";
      // const serialNumbers = ["50J215200994"]; // Example serial number

      http.open("POST", url, true);
      http.setRequestHeader("apikey", apiKey);
      http.setRequestHeader("tenant", tenant);
      // Do NOT set the Content-Type header manually for FormData
      // http.setRequestHeader("Content-Type", "multipart/form-data");

      http.onreadystatechange = () => {
        if (http.readyState == 4) {
          if (http.status == 200) {
            console.log('ZPL data sent successfully');
            resolve(http.responseText);
          } else {
            console.error('Error in sending ZPL data:', http.statusText);
            reject(http.statusText);
          }
        }
      };

      var fd = new FormData();
      // Append ZPL file data as a blob or directly as a string if the API supports it
      fd.append("zpl_file", new Blob([zplData], { type: 'text/plain' }), "HelloWorld.txt");

      // Assuming the API expects serial numbers with the key 'sn'
      serialNumbers.forEach(sn => {
        fd.append("sn", sn);
      });

      http.send(fd);
    });
  }

  createZPLData(printData) {
    // Increase the offset to move the content further up
    let yOffset = 50; // Adjust this as needed
    let zplData = '^XA\n';
    zplData += '^LL2000\n';
    zplData += '^PW800\n';
    zplData += '^LT0\n';
    zplData += '^XB0\n';
    zplData += '^MMT\n';
    zplData += '^FR0\n';

    // Font sizes are adjusted to 25 for titles and 18 for details to fit the label width
    zplData += `^FO40,${104 - yOffset}^A0N,35,35^FD${printData.name}^FS`; // Strain Name
    zplData += `^FO40,${144 - yOffset}^A0N,18,18^FDTHC: ${printData.productCannabisModel.thc}^FS`; // THC
    zplData += `^FO40,${164 - yOffset}^A0N,18,18^FDTHCA: ${printData.productCannabisModel.thcA}^FS`; // THCA
    zplData += `^FO40,${184 - yOffset}^A0N,18,18^FDCBD: ${printData.productCannabisModel.cbd}^FS`; // CBD
    zplData += `^FO40,${204 - yOffset}^A0N,18,18^FDCBDA: ${printData.productCannabisModel.cbdA}^FS`; // CBDA
    zplData += `^FO40,${224 - yOffset}^A0N,18,18^FDLot Number: ${printData.productCannabisModel.barcodeId}^FS`; // Lot Number
    zplData += `^FO40,${244 - yOffset}^A0N,18,18^FDHarvest Date: ${new Date(printData.productCannabisModel.harvestDate).toLocaleDateString()}^FS`; // Harvest Date
    zplData += `^FO40,${264 - yOffset}^A0N,18,18^FDExpiration Date: ${new Date(printData.productCannabisModel.expirationDate).toLocaleDateString()}^FS`; // Expiration Date
    zplData += `^FO40,${284 - yOffset}^A0N,18,18^FDNet Weight: ${printData.productCannabisModel.weight}^FS`; // Net Weight
    zplData += `^FO40,${304 - yOffset}^A0N,18,18^FDManufacturer: ${printData.productCannabisModel.manufacturer}^FS`; // Manufacturer
    zplData += `^FO40,${324 - yOffset}^A0N,18,18^FDNot FDA Approved^FS`; // Not FDA Approved

    // Disclaimer and warnings with smaller font size to fit in the label
    zplData += `^FO40,${364 - yOffset}^A0N,8,8^FDThis product is not approved by the FDA to treat, cure, or prevent any disease.^FS`;
    zplData += `^FO40,${404 - yOffset}^A0N,8,8^FDFDA has not evaluated this product for safety, effectiveness, and quality.^FS`;
    zplData += `^FO40,${444 - yOffset}^A0N,8,8^FDThere may be long term adverse health effects from consumption of cannabis.^FS`;
    zplData += `^FO40,${484 - yOffset}^A0N,12,12^FDWARNING: For use only by adults 21 and older.^FS`;
    zplData += `^FO40,${524 - yOffset}^A0N,12,12^FDKeep out of reach of children.^FS`;
    zplData += `^FO40,${564 - yOffset}^A0N,8,8^FDDo not drive a motor vehicle or operate machinery while under the influence of cannabis.^FS`;
    zplData += `^FO40,${604 - yOffset}^A0N,8,8^FDBE CAUTIOUS. Cannabinoid edibles can take up to 2 hours or more to take effect.^FS`;
    zplData += `^FO40,${644 - yOffset}^A0N,12,12^FDContain no known allergens.^FS`;
    zplData += `^FO40,${684 - yOffset}^A0N,12,12^FDProduct intended for inhalation.^FS`;
    zplData += `^FO40,${724 - yOffset}^A0N,12,12^FDThere are no pharmacologically active ingredients.^FS`;
    zplData += `^FO40,${764 - yOffset}^A0N,12,12^FDNM Poison & Drug Info Center Phone Number: (800) 222-1222.^FS`;

    // Logo adjustment (if necessary, adjust the yOffset as needed)
    zplData += `^FO40,${804 - yOffset}^GFA,480,480,8,M06,M0F,L01F8,L039C,L070E,L0E07,K01C038,K03801C,K07060E,K0E0607,J01C06038,J0380601C,J0700600E,J0EK07,I01C0060038,I038006001C,I07M0E,I0EM07,001CM038,00383FB0C7C1C,00703FB0CFC0E,00E00C30CC007,01C00C3FDC0038,03800C3FDC001C,07I0C38CCI0E,0EI0C30CEI07,1CI0C30C7C0038,38M018001C,7S0E,EI01KF8I07,:7S0E,38Q01C,1CQ038,0EQ07,07I063386I0E,038007338E001C,01C00733CE0038,00E007B3DE007,007007B37E00E,003806F33601C,001C067336038,I0E06730607,I070421020E,I038K01C,I01CK038,J0EK07,J07K0E,J038I01C,J01CI038,K0EI07,K07I0E,K03801C,K01C038,L0E07,L070E,L039C,L01F8,M0F,M06,^FS`;

    zplData += '^XZ';

    return zplData;
  }









  createRestaurantZPLData(printData: any): string {
    // The amount by which to move the content up
    let yOffset = 50;
    let zplData = '^XA\n';
    zplData += '^LL2000\n';
    zplData += '^PW800\n';
    zplData += '^LT0\n';
    zplData += '^XB0\n';
    zplData += '^MMT\n';
    zplData += '^FR0\n';

    // Print the name with a font size of 48
    zplData += `^FO40,${204 - yOffset}^A0N,48,48^FDName: ${printData.productModel.name}^FS`;

    // Format toppings with hyphen and newline, using a font size of 42
    const toppings = this.getToppings(printData);
    const toppingsLines = toppings.split('\n');
    toppingsLines.forEach((toppingLine, index) => {
      zplData += `^FO40,${244 + index * 44 - yOffset}^A0N,42,42^FD${toppingLine}^FS`;
    });

    zplData += '^XZ';

    return zplData;
  }




  getToppings(item: any): string {
    const { cartDetailsVariantModelList = [], cartDetailsAddOnModelList = [] } = item;
    let toppings = [];
    (cartDetailsVariantModelList || []).forEach((variant) => {
      toppings.push(`- ${variant.childVariantModel.variantName}`);
    });
    if (cartDetailsAddOnModelList && cartDetailsAddOnModelList.length > 0) {
      (cartDetailsAddOnModelList || []).forEach((addon) => {
        toppings.push(`- ${addon.addOnModel.addOnName}`);
      });
    }
    return `\n${toppings.join('\n')}`;
  }


  /**
   * get all printers
   * @param storeId 
   * @returns 
   */
  getAllPrinters(stationId: number): Observable<any> {
    return this.apiConfig.get(`${this.api}/${stationId}`);
  }


 
  /**
   * save printer in database
   * @param printer
   */
  savePrinterInDB(printer: any): Observable<any> {
     return this.apiConfig.post(this.api, printer);
  }


  /**
   * update printer in database
   * @param printer 
   * @returns 
   */
  updatePrinterInDB(printer: any): Observable<any> {
    return this.apiConfig.put(this.api, printer);
  }


  /**
   * update printer status
   * @param printerId 
   * @param status 
   * @returns 
   */
  updatePrinterStatus(printerId: number, status: boolean): Observable<any> {
    return this.apiConfig.patch(`${this.api}/${printerId}/${status}`);
  }


  /**
   * delete printer
   * @param printerId
   * @returns 
   */
  removePrinterStatus(printerId: number): Observable<any> {
    return this.apiConfig.delete(`${this.api}/${printerId}`);
  }

}
