import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SetupTerminalComponent } from './setup-terminal.component';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { DesignUtilitiesModule } from 'src/app/appModules/design-utilities.module';

@NgModule({
  declarations: [SetupTerminalComponent],
  imports: [IonicModule, CommonModule, ReactiveFormsModule, DesignUtilitiesModule],
  exports: [SetupTerminalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SetupTerminalModule {}
