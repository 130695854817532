import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CashPaymentModule } from '../includes/payments/cash-payment/cash-payment.module';
import { CardPaymentModule } from '../includes/payments/card-payment/card-payment.module';
import { CardReaderPaymentModule } from '../includes/payments/card-reader-payment/card-reader-payment.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChargeActionSheetPageModule } from '../includes/payments/charge-action-sheet/charge-action-sheet.module';
import { CommonPageModule } from '../pages/common/common.module';
import { AddInitialCashModule } from '../core/components/add-initial-cash/add-initial-cash.module';
import { PricingPlansModule } from '../core/components/pricing-plans/pricing-plans.module';
import { ManageAliasModule } from '../core/components/manage-alias/manage-alias.module';
import { SetupTerminalModule } from '../core/components/setup-terminal/setup-terminal.module';
import { SetLoyaltyPointsModule } from '../core/components/set-loyalty-points/set-loyalty-points.module';
import { TermsStripeCardReaderModule } from '../includes/terms-stripe-card-reader/terms-stripe-card-reader.module';

@NgModule({
  imports: [
    CommonModule,
    CashPaymentModule,
    CardPaymentModule,
    CardReaderPaymentModule,
    FormsModule,
    ReactiveFormsModule,
    ChargeActionSheetPageModule,
    AddInitialCashModule,
    SetLoyaltyPointsModule,
    SetupTerminalModule,
    ManageAliasModule,
    PricingPlansModule,
    TermsStripeCardReaderModule,
    CommonPageModule
  ]
})
export class CoreModule {}
