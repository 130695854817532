import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';
import { DesignUtilityServices } from '../../utilities/design-utility.service';
import { CashMgmtService } from 'src/app/appServices/cash-mgmt.service';
import { ToastMessageService } from '../../utilities/toast-message.service';
import { SquareService } from 'src/app/appServices/square.service';
import { IStore } from 'src/app/appModels/IStore';
import { StoreResolver } from 'src/app/appResolvers/store.resolver';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-setup-terminal',
  templateUrl: './setup-terminal.component.html',
  styleUrls: ['./setup-terminal.component.scss'],
})
export class SetupTerminalComponent extends HandleSubscriptionsComponent implements OnInit {

  @Input() intialCash:any;
  
  loading: boolean = false;
  deviceCode:string;
  selectedStore: IStore = null;
  synced: boolean = false;
  constructor(
    private modalCtrl: ModalController,
    private _du: DesignUtilityServices,
    private toaster: ToastMessageService,
    private squareService: SquareService,
    private _storeResolver: StoreResolver,
  ) {
    super();
  }


  ngOnInit() {
    this.subscribeSelectedStore();
  }



  /**
   * Subscribe selected store
   */
  subscribeSelectedStore() {
    this.handleSubscription(this._storeResolver.selectedStore,
      (res) => {
        if (res) {
          this.selectedStore = res;
        }
      }
    )
  }



  /**
   * Generate device code
   */
  generateDeviceCode(){
    this.loading = true;
    this.handleSubscription(this.squareService.generateDeviceCode(this.selectedStore.id),
      (res) => {
        this.deviceCode = res.deviceId;
        // console.log('deviceCode ',this.deviceCode);
        this.toaster.successToastWithClose('Device code generated');
        this.loading = false;
        // this.closeModal('success');
      },
      (err) => {
        this.toaster.errorToastWithClose('Error: '+ err);
        this.loading = false;
        // this.closeModal('error');
      }
    );
  }



  syncPairStatus(){
    if (environment.production) {
      this.handleSubscription(this.squareService.syncPairStatus(this.selectedStore.id),
        (res) => {
          if(res.type ==='device.code.paired'){
            this.synced = true;
            localStorage.setItem('squareTerminalDeviceId', res.data.object.device_code.device_id);
          }
        },
        (err) => {
          this.toaster.errorToastWithClose('Error: '+ err);
        }
      );
    }else{
      localStorage.setItem('squareTerminalDeviceId', '9fa747a2-25ff-48ee-b078-04381f7c828f');
      this.synced = true; 
    }
  }

  

  closeModal(action?:string) {
    this.modalCtrl.dismiss(action || 'closed');
  }


}
