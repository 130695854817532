import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageAliasComponent } from './manage-alias.component';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { DesignUtilitiesModule } from 'src/app/appModules/design-utilities.module';

@NgModule({
  declarations: [ManageAliasComponent],
  imports: [IonicModule, CommonModule, ReactiveFormsModule, DesignUtilitiesModule],
  exports: [ManageAliasComponent],
})
export class ManageAliasModule {}
