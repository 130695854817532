import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StripeCardReaderComponent } from './stripe-card-reader/stripe-card-reader.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { StripeBluetoothCardReaderComponent } from './stripe-bluetooth-card-reader/stripe-bluetooth-card-reader.component';
import { ReaderLocationPageModule } from 'src/app/includes/popups/reader-location/reader-location.module';
import { SquareTerminalPaymentComponent } from './square-terminal-payment/square-terminal-payment.component';
import { SquareCardReaderComponent } from './square-card-reader/square-card-reader.component';



@NgModule({
  declarations: [
    StripeCardReaderComponent,
    SquareTerminalPaymentComponent,
    SquareCardReaderComponent,
    StripeBluetoothCardReaderComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReaderLocationPageModule
  ],
  exports: [
    StripeCardReaderComponent,
    SquareTerminalPaymentComponent,
    SquareCardReaderComponent,
    StripeBluetoothCardReaderComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CardReaderPaymentModule { }
