import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';  // Import throwError here
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpConfigService } from 'src/app/core/httpConfig/httpConfigService';
import { IBiotrackSendInventoryRequest, TransferData as SendTransferData } from 'src/app/appModels/IBiotrackSendInventoryRequest';
import { IBiotrackLookupResponseModel } from 'src/app/appModels/IBiotrackLookupResponseModel';
import { IBiotrackReceiveInventoryPayload, TransferData, UOM } from 'src/app/appModels/IBiotrackReceiveInventoryPayload';
import { SplitData } from 'src/app/appModels/IBiotrackInventorySplitRequestModel';
import { IBiotrackInventorySplitRequestModel } from 'src/app/appModels/IBiotrackInventorySplitRequestModel';
import { IBiotrackSyncManifest } from 'src/app/appModels/IBiotrackSyncManifest';


@Injectable({
  providedIn: 'root'
})
export class BiotrackService {

  api = environment.baseUrl + "biotrack";

  constructor(private apiConfig: HttpConfigService) { }

  patientLookup(mmjStateIDNumber: string, storeId:number): Observable<IBiotrackLookupResponseModel> {
    return this.apiConfig.get(`${this.api}/look-up/${mmjStateIDNumber}/${storeId}`).pipe(
      catchError(error => {
        console.error('Error during patient lookup:', error);
        return throwError(() => new Error('Error during patient lookup'));
      })
    );
  }

  splitItem(splitData: SplitData, storeId:number): Observable<any> {
    const payload = [splitData]; // Wrap splitData in an object with a data field
    return this.apiConfig.post(`${this.api}/inventory/split/${storeId}`, payload);
  }

  getBiotrackProductInfo(barcodeId: any, storeId:number): Observable<any> {
    return this.apiConfig.get(`${this.api}/productDetails/${barcodeId}/${storeId}`);
  }

  lookupAvailableManifests(storeId:number): Observable<any> {
    return this.apiConfig.get(this.api + '/manifest/lookup/'+storeId);
  }
  
  getManifestData(manifestId: any, storeId:number): Observable<any> {
    return this.apiConfig.get(this.api + '/manifest/lookup/' + manifestId + '/'+storeId);
  }

  receiveBiotrackOrder(payload: IBiotrackReceiveInventoryPayload, storeId:number): Observable<any> {
    return this.apiConfig.post(this.api + '/manifest/receive/'+storeId, payload);
  }
  
  receiveManufacturingBiotrackOrder(payload: IBiotrackReceiveInventoryPayload, storeId:number): Observable<any> {
    return this.apiConfig.post(this.api + '/manifest/receive/manufacturing/'+storeId, payload);
  }

  createManifest(manifestData: any, storeId:number): Observable<any> {
    return this.apiConfig.post(this.api + '/create/manifest/'+storeId, manifestData).pipe(
      catchError((error) => {
        // Handle errors here
        return throwError(error);
      })
    );
  }

  getBiotrackInventory(storeId:number): Observable<any> {
    return this.apiConfig.get(this.api + '/inventory/getInventory/'+storeId);
  }

  destroyInventory(barcodeId: string, storeId:number): Observable<any> {
    return this.apiConfig.post(this.api + '/inventory/destroy/'+storeId, { barcodeId }).pipe(
      catchError(error => {
        // Handle errors here
        return throwError(error);
      })
    );
  }

  scheduleInventoryDestroy(name: string, barcodeId: string, reason: string, reasonExtended: string, storeId:number): Observable<any> {
    // The payload now includes a single barcodeId instead of an array
    const payload = { name, barcodeId, reason, reasonExtended };
    return this.apiConfig.post(this.api + '/inventory/destroy/schedule/'+storeId, payload).pipe(
      catchError(error => {
        // Handle errors here
        return throwError(error);
      })
    );
  }

  getProductInventoryInfo(barcodeId: any, storeId:number): Observable<any> {
    return this.apiConfig.get(this.api + '/inventory/check/' + barcodeId + '/'+storeId);
  }

  
  createEmployee(driverData: any, storeId:number): Observable<any> {
    return this.apiConfig.post(this.api + '/create/employee/'+storeId, driverData).pipe(
      catchError((error) => {
        // Handle errors here
        return throwError(error);
      })
    );
  }

  createVehicle(vehicleData: any, storeId:number): Observable<any> {
    return this.apiConfig.post(this.api + '/create/vehicle/'+storeId, vehicleData).pipe(
      catchError((error) => {
        // Handle errors here
        return throwError(error);
      })
    );
  }

  sendManifest(manifestId: string, data: SendTransferData[], storeId:number): Observable<any> {
    return this.apiConfig.post(`${this.api}/manifest/send${storeId}?manifestId=${manifestId}`, data).pipe(
      catchError(error => {
        // Handle errors here
        return throwError(error);
      })
    );
  }

  //  sendManufacturingManifest(manifestId: string, data: SendTransferData[]): Observable<any> {
  //    return this.apiConfig.post(`${this.api}/manifest/manufacturing/send?manifestId=${manifestId}`, data).pipe(
  //      catchError(error => {
  //        // Handle errors here
  //        return throwError(error);
  //      })
  //    );
  //  }


  inventoryTransferLookup(manifestId: string, storeId:number): Observable<any> {
    return this.apiConfig.get(`${this.api}/transfer/lookup${storeId}?manifestId=${manifestId}`).pipe(
      catchError(error => throwError(error))
    );
  }

  syncManifest(storeId:number): Observable<IBiotrackSyncManifest> {
    return this.apiConfig.get(this.api + '/sync/manifest'+storeId).pipe(
      catchError(error => {
        console.error('Error during sync manifest:', error);
        return throwError(() => new Error('Error during sync manifest'));
      })
    );
  }



   ///////////////////////
  
  syncInventory(menuId: number, categoryId: number, storeId: number): Observable<any> {
    return this.apiConfig.get(`${this.api}/inventory/checkup/${storeId}?menuId=${menuId}&categoryId=${categoryId}`);
  }

  getEmployeeData(): Observable<any[]> {
    return this.apiConfig.get(this.api + '/get/employee').pipe(
      catchError((error) => {
        // Handle errors here
        return throwError(error);
      })
    );
  }

  getVehicleData(): Observable<any[]> {
    return this.apiConfig.get(this.api + '/get/vehicle').pipe(
      catchError((error) => {
        // Handle errors here
        return throwError(error);
      })
    );
  }

  getReceivedManifestHistoryWithMenuId(menuId: number): Observable<any> {
    return this.apiConfig.get(this.api + `/inventory/history/${menuId}`);
  }

  getReceivedManifestHistory(): Observable<any> {
    return this.apiConfig.get(this.api + `/inventory/history/`);
  }

  getReceivedManifestHistoryWithManifestId(manifestId: string): Observable<any> {
    return this.apiConfig.get(this.api + `/inventory/history/byManifestId/${manifestId}/`);
  }

  getBiotrackModificationHistory(): Observable<any> {
    return this.apiConfig.get(this.api + `/inventory/modification-history/getall/`);
  }

}
