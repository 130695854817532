import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';
import { IStore } from 'src/app/appModels/IStore';
import { PaymentGatewayResolver } from 'src/app/appResolvers/payment-gateway.resolver';
import { StoreResolver } from 'src/app/appResolvers/store.resolver';
import { ToastMessageService } from 'src/app/core/utilities/toast-message.service';

@Component({
  selector: 'app-stripe-connected-account-modal',
  templateUrl: './stripe-connected-account-modal.component.html',
  styleUrls: ['./stripe-connected-account-modal.component.scss'],
})
export class StripeConnectedAccountModalComponent extends HandleSubscriptionsComponent implements OnInit {
  email: string;
  adding:boolean = false;
  accounts: any[];
  loading: boolean = false;
  selectedStore: IStore;
  getAllAccountsSub:Subscription;

  @Input() selectedStripeAccount: number;
  
  constructor(
    private modalCtrl: ModalController,
    private _storeResolver: StoreResolver,
    private toasterService: ToastMessageService,
    private _paymentGatewayResolver: PaymentGatewayResolver

  ) { 
    super();
  }

  ngOnInit() {
    this.subscribeSelectedStore();
    this.getAllAccounts();

  }
  

  /**
  * subscribe selected store
  */
   subscribeSelectedStore() {
    this.handleSubscription(this._storeResolver.selectedStore, 
    (res) => {
      if (res) {
        this.selectedStore= res;
      }
    });
  }


  
  /**
   * Display more stores
   * @param storesArray 
   * @returns 
   */
  displayMoreStores(storesArray: any[]) {
    const storesToDisplay = storesArray.slice(2, storesArray.length);
    let storesToDisplayString: string = '';
    storesToDisplay.forEach((elem, index) => {
      if (index === 0) {
        storesToDisplayString = elem.storeName;
      } else {
        storesToDisplayString = storesToDisplayString + ', ' + elem.storeName;
      }
    });
    return storesToDisplayString;
  }

  /**
   * Get All Accounts
   */
  getAllAccounts() {
    this.getAllAccountsSub = this._paymentGatewayResolver.getAllConnectedAccounts().pipe(
      map(res => {
        let tempArr=[];
        for (let stripeAccountId of res) {
          let tdata = {stripeAccountId : stripeAccountId};
          tempArr.push(tdata);
        }
        return tempArr;
      })
      )
    .subscribe(res => {
      this.accounts = res;
      this.mapAccountStatusAndStores(this.accounts);
    })
  }


  /**
   * Map Account Status & Connected Stores
   * @param accountData 
   */
  mapAccountStatusAndStores(accountData){
    for (let account of accountData) {
      // this.handleSubscription(this._storeResolver.getStoreListByStripeAccount(account.stripeAccountId), 
      // (storeList) =>{
      //   if(storeList && storeList.length>0){
      //     account['stores'] = storeList;
      //   }
      // });
      this.updateAccountStatus(account);
    }
  }


  /**
   * Update Account Status
   * @param account 
   */
  updateAccountStatus(account){
    this.handleSubscription(this._paymentGatewayResolver.accountStatus(account.stripeAccountId), 
    (res) =>{
      account['chargesEnabled'] = res.chargesEnabled;
      account['detailsSubmited'] = res.detailsSubmited;
      account['email'] = res.email;
      account['payoutEnable'] = res.payoutEnable;
    });
  }


  

  addNewAccount() {
    this.loading = true;
    this.handleSubscription(this._paymentGatewayResolver.setupStripConnectedPayment(this.email), 
    (res) => {
      this.getAllAccounts();
      this.adding = false;
      this.loading = false;
      this.redirectToStripe(res?.onboardUrl);
    });
  }



  redirectToStripe(url) {
    var popup = window.open(url, "_blank");
    setTimeout(function () {
      if (!popup || popup.outerHeight === 0) {
        //First Checking Condition Works For IE & Firefox
        //Second Checking Condition Works For Chrome
        alert("Click OK to setup strip account...");
        window.location.href = url;
      } else {
        //Popup Blocker Is Disabled
        window.open(url, '_blank');
        window.close();
      }
    }, 25);
  }


  mapToStore(stripeAccountId){
    this.handleSubscription(this._storeResolver.mapAccountWithStore(this.selectedStore.id, stripeAccountId), 
    (res) =>{
      this.modalCtrl.dismiss(stripeAccountId);
    })
  }


  uncompleteAccountToaster(){
    this.toasterService.errorToastWithClose('Your account is not completed yet!!');
  }
  
  visitDashboard(stripeAccountId){
    // this.handleSubscription(this._paymentGatewayResolver.getDashboardUrl(stripeAccountId),
    //   (res) =>{
    //     window.open(res.url, "_blank")
    //   },
    //   (err)=>{
    //     this.toasterService.errorToastWithClose('Error: '+ err);
    //   }
    //   );
      window.open("https://dashboard.stripe.com/" +stripeAccountId, "_blank")
    }
    
    completeOnboarding(stripeAccountId){
      this.handleSubscription(this._paymentGatewayResolver.getAccountLink(stripeAccountId),
        (res) =>{
          window.open(res.url, "_blank")
        },
        (err)=>{
          this.toasterService.errorToastWithClose('Error: '+ err);
      }
    );
  }


  onClose() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  /**
   * Destroy Component
   */
   ngOnDestroy(): void {
    this.getAllAccountsSub.unsubscribe();
  }

}
