import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, Subscription, timer } from 'rxjs';
import { debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';
import { IOrderModel, IOrderStatusModel } from 'src/app/appModels/IOrderModel';
import { IStore } from 'src/app/appModels/IStore';
import { StoreResolver } from 'src/app/appResolvers/store.resolver';
import { CdsService } from 'src/app/appServices/cds.service';
import { OrderService } from 'src/app/appServices/order.service';
import { DateTimeUtility } from 'src/app/appUtilities/dateTime.utility';
import { OrderCardColor, OrderStatus, OrderType } from 'src/app/core/enums/enums';
import { IRoute } from './interfaces/IRoute';

@Injectable({
  providedIn: 'root'
})
export class CdsResolver {
  orders = new BehaviorSubject<any[]>(null);
  loading = new BehaviorSubject<boolean>(false);
  routes = new BehaviorSubject<IRoute[]>(null);
  selectedRoute = new BehaviorSubject<IRoute>(null);
  selectedStatus = new BehaviorSubject<IOrderStatusModel>(null);
  selectedStore: IStore;
  availableStausList: IOrderStatusModel[] = new Array();

  destroy$ = new Subject();
  orderStream$ = new Subject();


  constructor(
    private cdsService: CdsService,
    private _orderService: OrderService,
    private _storeResolver: StoreResolver,
    private dateUtility: DateTimeUtility) {
  }


  /**
   * Init Apis
   */
  initApis(){
    this.subscribeOrderStream();
    this.getAllRoutes();
    this.getAllAvailableStatusList();
    this.subscribeSelectedRoutes();
    this.subscribeSelectedStatus();
    this.subscribeSelectedStore();
    // this.startOrderTimer();
  }
  /**
   * get all routes
   */
  getAllRoutes() {
    this.cdsService.getAllRoutes().pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.routes.next(res);
      if(!this.selectedRoute.value){
        this.setSelectedRoute(this.routes.value[0]);
        this.selectedStatus.next(this.routes.value[0].orderStatusList[0]);
      }
    });
  }



  /**
   * Set Selected route
   * @param route 
   */
  setSelectedRoute(route:IRoute){
    this.selectedRoute.next(route);
  }

  /**
   * Call order stream
   */
  callOrderStream(){
    this.orderStream$.next(null);
  }

  /**
   * Subscribe order stream
   */
  subscribeOrderStream(){
    this.orderStream$.pipe(
        debounceTime(500),
        switchMap( () => this.getOrders())
      ).subscribe(res=>{
      this.setOrders(res);
    })
  }


  /**
   * Subscribe selected store
   */
  subscribeSelectedStore(){
    this._storeResolver.selectedStore.pipe(
      takeUntil(this.destroy$),
      tap( (res)=>  {
        this.selectedStore = res;
        this.orders.next(null)
      }),
    )
    .subscribe(res => {
      this.callOrderStream();
    });
  }


  /**
   * subscribe selected routes 
   */
   subscribeSelectedRoutes() {
    this.selectedRoute.pipe(
      takeUntil(this.destroy$),
      tap( ()=>  {
        this.orders.next(null);
        this.selectedStatus.next(this.selectedRoute.value?.orderStatusList[0]);
      }),
      )
      .subscribe(res => {
        this.callOrderStream();
    });
  }



  /**
   * subscribe selected status 
   */
   subscribeSelectedStatus() {
    this.selectedStatus.pipe(
      takeUntil(this.destroy$),
    ).subscribe((res) => {
      this.callOrderStream();
    });

  }




  /**
   * set orders
   */
  setOrders(orders){
    // this.orders.next(orders);
    this.updateOrderCardColor(this.orders.value);
    if(!this.orders.value){
      this.orders.next(orders);
    }else{
      orders.forEach(order => {
        let tempOrder = this.orders.value.find(e=> e.id== order.id);
        if(!tempOrder){
          const oldStateOrders = Object.assign([], this.orders.value);
          oldStateOrders.push(order);
          this.orders.next(oldStateOrders);
        }else{
          tempOrder = order;
        }
      });
    }
  }




  /**
   * Refresh Orders
   */
   refreshOrders(){
    this.loading.next(true);
    this.getOrders().pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.setOrders(res);
      this.loading.next(false);
    });
  }



  /**
   * Order Interval
   */
  orderTimerSub: any;
  startOrderTimer(){
    if(this.orderTimerSub){
      return;
    }
    const timerObs = timer(10000, 10000);
    this.orderTimerSub = timerObs.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.refreshOrders();
    });
  }




  /**
   * Unsubscrbe order interval
   * @returns 
   */
  unsubscribeOrderInterval(){
    this.orderTimerSub.unsubscribe();
    this.orderTimerSub = null;
  }


  

  /**
   * get orders
   * @returns 
   */
  getOrders(): Observable<IOrderModel[]> {
    if(this.selectedRoute.value?.name && this.selectedStatus.value?.status && this.selectedStore?.id){
      return this.cdsService.getOrdersByRouteAndStatus(this.selectedRoute.value.name, this.selectedStatus.value.status, this.selectedStore?.id).pipe(debounceTime(500))
    }
    return of(null);
  }
  



  /**
   * Update Order Card Color
   */
  updateOrderCardColor(orders:any) {
    if (orders) {
      orders.forEach((x) => {
        x.timeSpent = this.dateUtility.getTimeDifferenceInSeconds(x.orderDate);
        x.orderTimerString = this.dateUtility.formatSeconds(x.timeSpent);
        let timeParams = x.orderTimerString.split(':');
        let minuteTimer = timeParams[1];
        let hoursTimer = timeParams[0];
        this.setCardColor(hoursTimer, minuteTimer, x);
      });
    }
  }




  /**
   * Set Card color
   * @param hoursTimer
   * @param min
   * @param element
   */
  setCardColor(hoursTimer, min, element) {
    if (hoursTimer >= 1) {
      element.cardColor = OrderCardColor.highRisk;
      return;
    }
    if (min < 5) {
      element.cardColor = OrderCardColor.lowRisk;
    } else if (min < 10) {
      element.cardColor = OrderCardColor.mediumRisk;
    } else {
      element.cardColor = OrderCardColor.highRisk;
    }
  }




  /**
   * mark order to next state
   * @param status
   */
  getNextStatus(status: string, orderType: string): string {
    switch (status) {
      case OrderStatus.Pending:
        return 'Accepted';

      case OrderStatus.Accepted:
        return 'Prepared';

      case OrderStatus.Prepared:
        return OrderType.Delivery === orderType ? 'On The Way' : 'Delivered';

      case OrderStatus.OnTheWay:
        return 'Delivered';

      case OrderStatus.Pending:
        return 'Accepted';

      default:
        break;
    }
  }




  /**
   *Get all available Status List from API 
   */
  getAllAvailableStatusList() {
    this._orderService.getAllOrderStatusList().pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.availableStausList = res;
    });
  }


  

  /**
   * Update Order Status
   * @param order 
   * @returns 
   */
  updateOrderStatus(order:any):Observable<any>{
    return this._orderService.updateOrderStatus(order);
  }
  
  
  /**
   * Destroy subscriptions
   */
  destroySubscriptions() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe(); 
    this.destroy$ = new Subject();

    this.orderStream$.unsubscribe(); 
    this.orderStream$ = new Subject();
  }

}
