import { Directive, ElementRef, AfterViewInit, HostListener } from '@angular/core';

@Directive({
  selector: '[numOnly]',
})
export class NumOnlyDirective {
  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent): boolean {
    const inputChar = String.fromCharCode(event.charCode);
    const currentInput = (event.target as HTMLInputElement).value;
    
    // Check if the current input already contains a decimal point
    const hasDecimalPoint = currentInput.includes('.');
  
    // Allow only digits and a single decimal point
    if (inputChar === '.' && hasDecimalPoint) {
      return false; // Disallow another decimal point
    }
  
    const pattern = /^\d*\.?\d*$/; // Regular expression to allow numbers and float values with a single decimal point
    return pattern.test(inputChar);
  }
  
}