import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IAddOn } from 'src/app/appModels/IAddOn';
import { HttpConfigService } from 'src/app/core/httpConfig/httpConfigService';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddOnService {

  api = environment.baseUrl + "addOns";
  invMapApi:string = environment.baseUrl + "inventory-mapping/addon";


  getAllAddonEvent = new Subject();
  
  constructor(private apiConfig: HttpConfigService) { }

  createAddOn(addOn: IAddOn): Observable<any> {
    return this.apiConfig.post(this.api+"/create", addOn, {withCredentials: true});
  }
  removeAddOn(addOnId: number): Observable<any> {
    return this.apiConfig.delete(this.api + "/" + addOnId, {withCredentials: true});
  }
  deleteAddonsByMenu(menuId: number): Observable<any> {
    return this.apiConfig.delete(this.api + "/menu/" + menuId, {withCredentials: true});
  }


  getAddOns(menuId: number): Observable<any> {
    return this.apiConfig.get( `${this.api}/getAll/${menuId}`);
  }
  updateAddOn(addOn: IAddOn): Observable<any> {
    return this.apiConfig.put(this.api + "/update", addOn, {withCredentials: true});
  }
  toggleAddOn(addOn: IAddOn): Observable<any> {
    return this.apiConfig.patch(`${this.api}/status/${addOn.id}/${addOn.active}`,null);
  }

  mapWithInventory(addonId: number, inventoryIds:number[]): Observable<any> {
    return this.apiConfig.post(`${this.api}/map-with-inventory/${addonId}`, inventoryIds, {withCredentials: true});
  }

  updateMeasurement(mappingId:number, measurement:number, unitId:number){
    return this.apiConfig.patch(`${this.invMapApi}/${mappingId}/${measurement}/${unitId}`, {withCredentials: true});
  }
}
