import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { RestaurantInfoResolver } from 'src/app/appResolvers/restaurant-info.resolver';

@Component({
  selector: 'app-verification-in-progress',
  templateUrl: './verification-in-progress.component.html',
  styleUrls: ['./verification-in-progress.component.scss'],
})
export class VerificationInProgressComponent  implements OnInit {
  @Output() verificationComplete = new EventEmitter<void>();
  private subscription: Subscription;
  private getVerificationStatusSub: Subscription;

  constructor(private restaurantInfoResolver: RestaurantInfoResolver) {}

  ngOnInit() {
    const timerObs = interval(10000);
    this.subscription = timerObs.subscribe((res) => {
      console.log('status: ', res)
      this.checkAccountStatus();
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  checkAccountStatus() {
    this.getVerificationStatusSub = this.restaurantInfoResolver.getVerificationStatus().subscribe(
      (res:any)=>{
        console.log('res: ', res);
        if(res.approved){
          this.verificationComplete.emit();
          this.subscription.unsubscribe();
          this.getVerificationStatusSub.unsubscribe();
        }
      }
    );
  }

}
