import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpConfigService } from '../core/httpConfig/httpConfigService';
@Injectable({
  providedIn: 'root'
})
export class PaymentGatewayService {

  api =  this.apiConfig.getBaseUrl  + 'payment-gateway';

  constructor(private apiConfig: HttpConfigService) { }

  getAllPaymentGateways(storeId: number): Observable<any> {
    return this.apiConfig.get(`${this.api}/client/${storeId}/cashier`);
  }

  getPaymentGatewayTypeList(): Observable<any> {
    return this.apiConfig.get(`${this.api}`);
  }

  updateClient(storeId, paymentGatewayId, clientTypeId, active): Observable<any> {
    return this.apiConfig.patch(`${this.api}/${storeId}/${paymentGatewayId}/${clientTypeId}/${active}`,null);
  }

  setupStripPayment(stripeKey, publicKey): Observable<any>{
    return this.apiConfig.post(`${this.api}/client/stripe/account/${stripeKey}/${publicKey}`, null);
  }
  
  setupStripConnectedPayment(email): Observable<any>{
    return this.apiConfig.get(`${this.api}/client/stripe/onboard/${email}`);
  }

  findAccountByEmail(email:string): Observable<any>{
    return this.apiConfig.get(`${this.api}/client/nmi/account/${email}`);
  }
  
  addExistingNMIAccount(payload:any): Observable<any>{
    return this.apiConfig.post(`${this.api}/client/nmi/create`, payload);
  }

  accountStatus(stripeAccountId:string): Observable<any>{
    return this.apiConfig.get(`${this.api}/client/stripe/account-status/${stripeAccountId}`);
  }

  
  getClientsByStoreAndGateway(storeId, gatewayId): Observable<any> {
    return this.apiConfig.get(`${this.api}/${storeId}/${gatewayId}`);
  }


  /**
   * get all accounts
   * @returns 
   */
  getAllAccounts(): Observable<any>{
    return this.apiConfig.get(`${this.api}/client/stripe/accounts/standard`);
  }

  /**
   * get all connected accounts
   * @returns 
   */
  getAllConnectedAccounts(): Observable<any>{
    return this.apiConfig.get(`${this.api}/client/stripe/accounts`);
  }

  /**
   * get all accounts
   * @returns 
   */
  getAllRethinkPayAccounts(): Observable<any>{
    return this.apiConfig.get(`${this.api}/client/rethink/accounts`);
  }


  /**
   * get all nmi accounts
   * @returns 
   */
  getAllNmiAccounts(): Observable<any>{
    return this.apiConfig.get(`${this.api}/client/nmi/accounts`);
  }

 
 /**
   * get nmi card type
   * @param IINumber 
   * @returns 
   */
  getNmiCardType(IINumber:number): Observable<any>{
    return this.apiConfig.get(`${this.api}/client/nmi/card-type/${IINumber}`);
  }


  /**
   * get all aeropay accounts
   * @returns 
   */
  getAllAeropayAccounts(tenantId:number): Observable<any>{
    return this.apiConfig.get(`${this.api}/client/aeropay/user/${tenantId}`);
  }

  /**
   * Get Dashboard Url
   * @param stripeAccountId 
   */
   getDashboardUrl(stripeAccountId:string): Observable<any> {
    return this.apiConfig.get(`${this.api}/client/stripe/dashboard-url/${stripeAccountId}`);
  }


  getAccountLink(stripeAccountId:string): Observable<any>{
    return this.apiConfig.get(`${this.api}/client/stripe/account-link/${stripeAccountId}`);
  }


  /**
   * add new rethink payment account
   * @param payload
   * @returns 
   */
  addRethinkPaymentAccount(payload): Observable<any>{
    return this.apiConfig.post(`${this.api}/client/rethink/onboard`, payload);
  }


  /**
   * Instant deposit daily schedular
   * @param storeId \
   * @returns 
   */
  instantDepositDailySchedular(storeId:number): Observable<any>{
    return this.apiConfig.get(`${this.api}/client/stripe/instant-deposit/${storeId}`);
  }


  getAeropayTokenForMerchant(): Observable<any>{
    return this.apiConfig.get(`${this.api}/client/aeropay/token`);
  }

  getAeropayTokenForUser(userId:any): Observable<any>{
    return this.apiConfig.get(`${this.api}/client/aeropay/user/token/${userId}`);
  }

 
  registerNmiDevice(storeId: number, code:string): Observable<any>{
    return this.apiConfig.post(`${this.api}/client/nmi/register-device/${storeId}/${code}`, null);
  }



  



}
