import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DateTimeUtility } from '../../appUtilities/dateTime.utility';
import { IOrderModel } from '../../appModels/IOrderModel';
import { OrderUtility } from '../../appUtilities/order.utility';
declare var epson: any;

@Injectable({
  providedIn: 'root',
})
export class PrintEpsonService {
  private ePosDev: any; // Declare the ePosDev object
  private printer: any = null; // Declare the printer object

  connected:boolean = false;
  connecting:boolean = false;
  printerlogs = new Subject<string>();
  WEBSOCKET_PORT = 8008;
  SSLWEBSOCKET_PORT = 8043

  constructor(
    public dateTimeUtility: DateTimeUtility,
    private _orderUtility: OrderUtility
    ) {
      // Initialize the ePosDev object
      this.ePosDev = new epson.ePOSDevice();
  }

  consolelog(log){
    console.log('PrintLogs: ', log)
    this.printerlogs.next(log);
  }

  connectToPrinter(ip:string, port) {
    this.connecting = true;
    this.consolelog('Connecting to printer...')
    // var protocol = window.location.protocol;
    // var port = (protocol.match(/^(https:)/)) ? this.SSLWEBSOCKET_PORT : this.WEBSOCKET_PORT;
    
    // var protocol = (port == this.IFPORT_EPOSDEVICE) ? "http" : "https";

    this.ePosDev.connect(ip, port, (resultConnect: string) => {
      this.callbackConnect(resultConnect);
    });
  }

  private callbackConnect(resultConnect: string) {
    if (resultConnect === 'OK' || resultConnect === 'SSL_CONNECT_OK') {
      // Get the Printer object
      this.consolelog("connected to ePOS Device Service Interface.");
      this.ePosDev.createDevice(
        'local_printer',
        this.ePosDev.DEVICE_TYPE_PRINTER,
        { crypto: false, buffer: false },
        (deviceObj: any, retcode: string) => {
          this.callbackCreateDevice(deviceObj, retcode);
        }
      );
    } else {
      // Display the error message
      this.connecting = false;
			this.consolelog("connected to ePOS Device Service Interface is failed. [" + resultConnect + "]");
      console.error('Connection error:', resultConnect);
    }
  }

  private callbackCreateDevice(deviceObj: any, retcode: string) {
    if (retcode === 'OK') {
      this.printer = deviceObj;
      this.printer.timeout = 60000;

      // Register the printing complete event
      this.printer.onreceive = (res: any) => {
        console.log('Print result:', res.success);
			  this.consolelog('Print' + (res.success ? ' Success' : ' Failure') + (res.code? ' Code: ' + res.code: '')  );
      };
      this.connected = true;
      this.connecting = false;
      this.consolelog("you can use printer.");
    } else {
      this.connecting = false;
      this.consolelog('Error creating device: '+ retcode)
      console.error('Error creating device:', retcode);
    }
  }

  
  print(order?:IOrderModel) {
    if(!this.connected) return;
    this.printer.addLayout(this.printer.LAYOUT_RECEIPT, 800, 0, 0, 0, 35, 0);
    this.convertOrderToPrintData(order);
    this.printer.addCut(this.ePosDev.CUT_FEED);

    // Send the printing data
    this.printer.send();
  }


  /**
   * convert order to printable data
   */
  convertOrderToPrintData(order:any){
    if(!order){
      order = this.dummyOrder;
    }

    // Basic Info
    const basicInfoJSON = window.localStorage.getItem('basic-info');
    const basicInfo = JSON.parse(basicInfoJSON);

    this.printer.addText('\n');
    this.printer.addTextAlign(this.printer.ALIGN_CENTER);
    this.printer.addTextSmooth(true);
    this.printer.addTextDouble(true, true);
    this.printer.addText(basicInfo.restaurantName + '\n');
    this.printer.addTextDouble(false, false);
    this.printer.addTextSmooth(false);
    this.printer.addText('\n');
    this.printer.addText('************************************************\n');
    this.printer.addText('Receipt\n');
    this.printer.addText('************************************************\n');
    this.printer.addText('\n');

    // Cannabis Cashier Name
    if (basicInfo.businessType === 'cannabis' && order?.cashierModel != null) {
      
      const cashierName = order.cashierModel?.lastName ? order.cashierModel?.lastName : order.cashierModel?.firstName;
      const cashierId = order.cashierModel?.biotrackRefId ? order.cashierModel?.biotrackRefId : order.cashierModel?.id; 

      this.printer.addTextAlign(this.printer.ALIGN_LEFT);
      this.printer.addText('Cashier Name:');
      this.printer.addTextPosition(300);
      this.printer.addText(cashierName + '\n');
      this.printer.addTextAlign(this.printer.ALIGN_LEFT);
      this.printer.addText('Cashier Id:');
      this.printer.addTextPosition(300);
      this.printer.addText(cashierId + '\n');
      
    }
  
    this.printer.addTextAlign(this.printer.ALIGN_LEFT);
    this.printer.addText('Order Number:');
    this.printer.addTextPosition(300);
    this.printer.addText(order.id + '\n');
    this.printer.addTextAlign(this.printer.ALIGN_LEFT);
    this.printer.addText('Total Amount:');
    this.printer.addTextPosition(300);
    this.printer.addText(order.totalAmount + '\n');
    this.printer.addTextAlign(this.printer.ALIGN_LEFT);
    this.printer.addText('Order Date:');
    this.printer.addTextPosition(300);
    this.printer.addText(this.dateTimeUtility.getDisplayDate(order.orderDate) + '\n');
    this.printer.addTextAlign(this.printer.ALIGN_LEFT);
    this.printer.addText('Order Type:');
    this.printer.addTextPosition(300);
    this.printer.addText(order.orderTypeModel.type + '\n');
    this.printer.addTextAlign(this.printer.ALIGN_LEFT);
    this.printer.addText('Status:');
    this.printer.addTextPosition(300);
    this.printer.addText(order.orderStatusModel.status + '\n');
    this.printer.addTextAlign(this.printer.ALIGN_LEFT);
    this.printer.addText('Payment:');
    this.printer.addTextPosition(300);
    this.printer.addTextSmooth(true);
    this.printer.addText((order.paid ? 'Paid' : 'Un-Paid') + '\n');
    this.printer.addTextSmooth(false);
    this.printer.addText('\n');
    this.printer.addTextAlign(this.printer.ALIGN_CENTER);
    this.printer.addText('************************************************\n');
    this.printer.addText('Order Details\n');
    this.printer.addText('************************************************\n');
    this.printer.addText('\n');

    order.orderDetailsModelList.forEach(item => {
      this.printer.addTextAlign(this.printer.ALIGN_LEFT);
      this.printer.addTextSmooth(true);
      this.printer.addText(item.quantity + ' X ' + item.productName);
      this.printer.addTextPosition(450);
      this.printer.addText('$' + item.basePrice + '\n');

      item.orderDetailsVariantModels.forEach(v => {
        this.printer.addTextSmooth(false);
        this.printer.addTextAlign(this.printer.ALIGN_LEFT);
        this.printer.addText('-' + v.childVariantName);
        this.printer.addTextPosition(438);
        v.variantPrice>0 
          ? this.printer.addText('+$' + v.variantPrice + '\n') 
          : this.printer.addText(''+'\n');
      });
 
      item.orderDetailsAddOnModelList.forEach(a => {
        this.printer.addTextSmooth(false);
        this.printer.addTextAlign(this.printer.ALIGN_LEFT);
        this.printer.addText('-' + a.addonName);
        this.printer.addTextPosition(438);
        a.addonPrice>0 
          ? this.printer.addText('+$' + a.addonPrice + '\n') 
          : this.printer.addText(''+'\n');
      });
      this.printer.addText('\n');

    });

    this.printer.addTextSmooth(false);
    this.printer.addText('\n');
    this.printer.addTextAlign(this.printer.ALIGN_CENTER);
    this.printer.addText('************************************************\n');
    this.printer.addText('\n');
    this.printer.addTextAlign(this.printer.ALIGN_LEFT);
    this.printer.addText('Sales Tax(' + order.tax + '%)');
    this.printer.addTextPosition(450);
    this.printer.addText('$' + this.getTaxAmount(order) + '\n');

    if (order.exciseTax > 0) {
      this.printer.addText('Excise Tax(' + order.exciseTax + '%)');
      this.printer.addTextPosition(450);
      this.printer.addText('$' + this.getExciseTaxAmount(order) + '\n');
    }
  
    this.printer.addText('Tastio Fee');
    this.printer.addTextPosition(450);
    this.printer.addText('$' + order.processingFee + '\n');
    this.printer.addText('\n');
    this.printer.addTextAlign(this.printer.ALIGN_CENTER);
    this.printer.addText('************************************************\n');
    this.printer.addTextAlign(this.printer.ALIGN_LEFT);
    this.printer.addTextSmooth(true);
    this.printer.addText('Total');
    this.printer.addTextPosition(450);
    this.printer.addText('$' + order.totalAmount + '\n');
    this.printer.addTextSmooth(false);
    this.printer.addTextAlign(this.printer.ALIGN_CENTER);
    this.printer.addText('************************************************\n');
    this.printer.addText('\n');

    if(order.paymentType == "cash" && order?.change > 0){
      this.printer.addTextAlign(this.printer.ALIGN_LEFT);
      this.printer.addText('Paid Amount');
      this.printer.addTextPosition(450);
      this.printer.addText('$' + this.calculateCashAmountPaid(order?.change, order.totalAmount) + '\n');
      this.printer.addText('Changes');
      this.printer.addTextPosition(450);
      this.printer.addText('$' + order?.change.toFixed(2) + '\n');
      this.printer.addText('\n');
      this.printer.addText('************************************************\n');
      this.printer.addText('\n');
    }

    // Gratuity and Signature
    if (basicInfo.businessType !== 'cannabis') {
      this.printer.addTextAlign(this.printer.ALIGN_CENTER);
      this.printer.addText('Suggested Gratuity\n');
      this.printer.addText('************************************************\n');
      this.printer.addText('\n');

      const percentages = [15, 18, 20];
      percentages.forEach(percent => {
        const tipAmount = (order.totalAmount * percent) / 100;
        const totalAmount = (tipAmount + order.totalAmount).toFixed(2);

        this.printer.addTextAlign(this.printer.ALIGN_LEFT);
        this.printer.addText(`[  ] ${percent}% - $${tipAmount.toFixed(2)}`);
        this.printer.addTextPosition(300);
        this.printer.addText(`Total: $${totalAmount}\n`);
      });
      
      this.printer.addText('[  ] $');
      this.printer.addTextPosition(300);
      this.printer.addText('Total:$\n');
      this.printer.addTextPosition(80);
      this.printer.addText('---------');
      this.printer.addTextPosition(400);
      this.printer.addText('--------\n');
      this.printer.addText('\n');
      this.printer.addText('\n');
    }

    this.printer.addTextAlign(this.printer.ALIGN_CENTER);
    this.printer.addText('-------------------------------------------\n');
    this.printer.addText('Signature\n');
    this.printer.addText('\n');
    this.printer.addTextAlign(this.printer.ALIGN_CENTER);
    this.printer.addBarcode(order.id, this.printer.BARCODE_CODE39, this.printer.HRI_NONE, this.printer.FONT_A, 2, 32);
    this.printer.addText('\n');
    this.printer.addText('Thanks for visiting\n');
    this.printer.addText(basicInfo.restaurantName + '\n');
    this.printer.addText('\n');

  }

  /**
   * get sales tax on order
   * @param order
   * @returns 
   */
  getTaxAmount(order){
    const itemList = this._orderUtility.convertOrderDetailToCartList(order.orderDetailsModelList);
    const taxAmount = this._orderUtility.calculateTax(itemList, order.tax, (order.tips | 0));
    return taxAmount;
  }


  /**
   * get excise tax on order
   * @param order
   * @returns 
   */
  getExciseTaxAmount(order){
    const itemList = this._orderUtility.convertOrderDetailToCartList(
        order.orderDetailsModelList
    );
    const exciseTaxAmount = this._orderUtility.calculateExciseTax(itemList, order.exciseTax, (order.tips | 0));
    return exciseTaxAmount;
  }


  /**
   * calculate cash amount paid
   * @param totalAmount 
   * @returns 
   */
  calculateCashAmountPaid(change, totalAmount) {
    let changesValue = parseFloat(change);
    let amountPaid = (totalAmount + changesValue).toFixed(2);
    return parseFloat(amountPaid);
  }


  disconnectFromPrinter() {
    // Discard the Printer object
    this.ePosDev.deleteDevice(this.printer, (errorCode: any) => {
      this.callbackDeleteDevice(errorCode);
    });
  }

  private callbackDeleteDevice(errorCode: any) {
    // Disconnect from the device
    this.ePosDev.disconnect();
  }


 
  dummyOrder = {
    "id": 16142,
    "orderTypeModel": {
        "type": "Dine In"
    },
    "orderStatusModel": {
        "status": "Accepted",
        "initialState": false
    },
    "orderDate": 1703847054176,
    "totalAmount": 28.98,
    "orderDetailsModelList": [
        {
            "id": 33584,
            "productName": "#1: 2 Burritos + 3 Tacos + 1 Side of Rice & Beans",
            "basePrice": 23.11,
            "quantity": 1,
            "productMessage": "",
            "orderDetailsAddOnModelList": [
                {
                    "id": 5140,
                    "addonName": "Mozzarella Jack Cheese",
                    "addonPrice": 0.1,
                    "addonId": 260
                }
            ],
            "orderDetailsVariantModels": [
                {
                    "id": 152930,
                    "childVariantName": "Al Pastor",
                    "variantPrice": 0,
                    "defaultSelected": true,
                    "childVariantId": 559,
                    "groupId": 33651
                },
                {
                    "id": 152931,
                    "childVariantName": "Chicken",
                    "variantPrice": 0,
                    "defaultSelected": true,
                    "childVariantId": 561,
                    "groupId": 33651
                },
                {
                    "id": 152932,
                    "childVariantName": "Al Pastor with Pineapple",
                    "variantPrice": 0.25,
                    "defaultSelected": false,
                    "childVariantId": 656,
                    "groupId": 33651
                },
                {
                    "id": 152933,
                    "childVariantName": "Tripitas",
                    "variantPrice": 0,
                    "defaultSelected": false,
                    "childVariantId": 2108,
                    "groupId": 33652
                },
                {
                    "id": 152934,
                    "childVariantName": "Al Pastor ",
                    "variantPrice": 0,
                    "defaultSelected": false,
                    "childVariantId": 572,
                    "groupId": 33653
                },
                {
                    "id": 152935,
                    "childVariantName": "With Everything on the Side",
                    "variantPrice": 0,
                    "defaultSelected": false,
                    "childVariantId": 1157,
                    "groupId": 33655
                },
                {
                    "id": 152936,
                    "childVariantName": "Burrito of Carne Asada",
                    "variantPrice": 0,
                    "defaultSelected": false,
                    "childVariantId": 1924,
                    "groupId": 33656
                },
                {
                    "id": 152937,
                    "childVariantName": "Burrito of Carne Asada",
                    "variantPrice": 0,
                    "defaultSelected": false,
                    "childVariantId": 1932,
                    "groupId": 33659
                },
                {
                    "id": 152938,
                    "childVariantName": "Rice & Beans",
                    "variantPrice": 0,
                    "defaultSelected": true,
                    "childVariantId": 1907,
                    "groupId": 33662
                }
            ],
            "productId": 419
        },
        {
            "id": 33585,
            "productName": "Taco",
            "basePrice": 2.23,
            "quantity": 1,
            "productMessage": "",
            "orderDetailsAddOnModelList": [
                {
                    "id": 5141,
                    "addonName": "Add Cheese on taco",
                    "addonPrice": 0.1,
                    "addonId": 268
                }
            ],
            "orderDetailsVariantModels": [
                {
                    "id": 152939,
                    "childVariantName": "With Everything",
                    "variantPrice": 0,
                    "defaultSelected": false,
                    "childVariantId": 1176,
                    "groupId": 33383
                },
                {
                    "id": 152940,
                    "childVariantName": "Shrimp",
                    "variantPrice": 1,
                    "defaultSelected": false,
                    "childVariantId": 1099,
                    "groupId": 33384
                }
            ],
            "productId": 323
        }
    ],
    "deliveryFee": 0,
    "trackOrderModels": [
        {
            "id": 53271,
            "orderModel": {
                "id": 16142,
                "orderDate": 1703847054176,
                "totalAmount": 28.98,
                "deliveryFee": 0,
                "tax": 8.19,
                "paymentType": "cash",
                "paid": true,
                "tips": 0,
                "discount": 0,
                "storeId": 1,
                "storeName": "Restaurant_Preprod",
                "storeAddress": "CG9C3J56+FR",
                "processingFee": 0,
                "platformFee": 0,
                "exciseTax": 0
            },
            "orderStatusModel": {
                "id": 2,
                "status": "Accepted",
                "initialState": true
            }
        }
    ],
    "tax": 8.19,
    "paymentType": "cash",
    "paid": true,
    "tips": 0,
    "discount": 0,
    "storeId": 1,
    "storeName": "Restaurant_Preprod",
    "storeAddress": "CG9C3J56+FR",
    "orderPaymentModelList": [],
    "processingFee": 0,
    "orderCheckoutDataList": [
        {
            "id": 936,
            "fieldName": "Name",
            "fieldValue": "4",
            "orderId": 16142
        }
    ],
    "platformFee": 0,
    "exciseTax": 0,
    "change": 2.1
  }
  
}

