import { getCurrencySymbol } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';
import { IBasicInfo } from 'src/app/appModels/IBasicInfo';
import { RestaurantInfoResolver } from 'src/app/appResolvers/restaurant-info.resolver';
import { SubscriptionService } from 'src/app/appServices/subscription/subscription.service';
import { ToastMessageService } from '../../utilities/toast-message.service';
import { StripeCardPaymentComponent } from './stripe-card-payment/stripe-card-payment.component';
import { EditTextComponent } from '../edit-text/edit-text.component';
import { subscriptionFreeEmails } from 'src/app/appConstants/subscriptionFreeEmails';

@Component({
  selector: 'app-pricing-plans',
  templateUrl: './pricing-plans.component.html',
  styleUrls: ['./pricing-plans.component.scss'],
})
export class PricingPlansComponent extends HandleSubscriptionsComponent implements OnInit {
  @Input() data: any;
  basicInfo: IBasicInfo;
  subscription: any;
  plans: any;
  coupon: any;
  originalPrice: any;
  
  subscriptionStatus:any;
  modalOpen:boolean = false;  
  couponApplied:boolean = false; 
  freeSubscription:boolean;


  constructor(
    private modalCtrl: ModalController,
    private _basicInfoResolver: RestaurantInfoResolver,
    private subscriptionService: SubscriptionService,
    private toaster: ToastMessageService,
  ) { 
    super();
  }

   

  
  ngOnInit() {
    this.subscribeBasicInfo();
    this.getSubscription();
    this.getAllPlans();
  }


  


  /**
   * Get Currency Symbol
   * @param currCode 
   * @returns 
   */
  getCurrSymbol(currCode) {
    return getCurrencySymbol(currCode, 'wide');
  }



  /**
   * subscribe basic info
   */
  subscribeBasicInfo() {
    this.handleSubscription(this._basicInfoResolver.restaurantBasicInfo,
      (res) => {
        this.basicInfo = res;
        this.freeSubscription = subscriptionFreeEmails.includes(this.basicInfo.emailId);
      }
    );
  }



  /**
   * Start trial
   */

  startTrial(){
    const payload = {
      name: this.basicInfo.ownerName,
      emailId: this.basicInfo.emailId,
      price:  "price_1OyojfKBSFkAswCEat7tyF1R",
    };

    this.handleSubscription(this.subscriptionService.startTrial(payload),
      (res) => {
        // console.log('trial ',res)
      }
    );
  }


  /**
   * Open stripe card payment modal
   * @param plan 
   */
  async openStripeCardPaymentModal(plan:any) {
    plan.coupon = this.coupon;
    if (this.couponApplied) {
      plan.discountedPrice = this.calculateDiscountedPrice(plan.defaultPrice);
    }
    const modal = await this.modalCtrl.create({
      component: StripeCardPaymentComponent,
      mode: 'md',
      cssClass: 'rounded-modal sml',
      componentProps: {
        plan
      },
      backdropDismiss: false,
    });
    await modal.present();
    const { role, data } = await modal.onWillDismiss();
    if (data=='success') {
      this.getSubscription(true);
    }
  }


  /**
   * Choose subscription
   * @param plan 
   */
  // chooseSubscription(plan:any){
  //   const payload = {
  //     name: this.basicInfo.ownerName,
  //     emailId: this.basicInfo.emailId,
  //     price:  plan.priceId,
  //     stripePaymentModel: {
  //       paymentToken: "tok_1P1RbpKBSFkAswCElVpb9tBx"
  //     }
  //   };
  //   console.log('payload => ', payload);


  // }



  /**
   * Get subscription
   */
  getSubscription(forceRefresh: boolean = false){
    this.handleSubscription(this.subscriptionService.getSubscription(this.basicInfo.emailId, forceRefresh),
      (res) => {
        this.subscription = res;
      
        this.subscriptionStatus = this.subscriptionService.isSubscriptionActive(this.basicInfo?.emailId)

        // let currPlan = this.plans.find(e=> e.priceId === this.subscription?.items.data[0].price.id)
        // console.log('currItem: ',currPlan)
        
      }
    );
  }

  

  /**
   * Get all products (plans)
   */
  getAllPlans(){
    this.handleSubscription(this.subscriptionService.getAllPlans(),
      (res: any[]) => {  // Cast response to an array of any
        this.plans = res.map((plan: any) => ({
          productName: plan.productName,
          defaultPrice: plan.defaultPrice,
          priceId: plan.priceId, // Include priceId if available
          metadata: Object.entries(plan.metadata).map(([key, value]) => ({ key, value }))
      }));
    });

     
  }


  /**
   * apply coupon
   */
  async applyCoupon() {
    if (!this.modalOpen){
      this.modalOpen = true;
      const modal = await this.modalCtrl.create({
        component: EditTextComponent,
        mode: 'md',
        cssClass: 'rounded-modal sml',
        componentProps:{
          data: {
            title:'Coupon',
            text: '',
            icon: 'ticket-outline'
          }
        },
        backdropDismiss: false,
      });
      await modal.present();
      const { role , data} = await modal.onWillDismiss();
      if (data?.updatedText) {     
        this.subscriptionService.getCoupon(data?.updatedText).subscribe(res => {
          this.toaster.successToastWithClose('Coupon applied successfully.');
          this.couponApplied = true;
          this.coupon = res;
          this.closeModal;
        }, err => {
          this.toaster.errorToastWithClose('Invalid Coupon code');
        });
      }
    }
    this.modalOpen = false;   
  }


  /**
   * calculate price after applying coupon
   * @param defaultPrice 
   * @returns 
   */
  calculateDiscountedPrice(defaultPrice: any) {
     if(this.coupon.discountType == 'fixed') {
        return defaultPrice - this.coupon.amount;
     } else if (this.coupon.discountType == 'percentage') {
        return defaultPrice - (defaultPrice * this.coupon.amount * 0.01);
     } else {
      return null;
     }
  }

  
  /**
   * on Submit
   */
  onSubmit() {
    // if (this.updatedText !== this.data.text) {
    //   this.modalCtrl.dismiss({ updatedText: this.updatedText });
    // } else {
    //   this.modalCtrl.dismiss(); 
    // }
  }


  
  closeModal() {
    this.modalCtrl.dismiss(null);
  }

  
}
