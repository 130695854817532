import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';
import { RestaurantInfoResolver } from 'src/app/appResolvers/restaurant-info.resolver';
import { SubscriptionService } from 'src/app/appServices/subscription/subscription.service';
import { ToastMessageService } from 'src/app/core/utilities/toast-message.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-stripe-card-payment',
  templateUrl: './stripe-card-payment.component.html',
  styleUrls: ['./stripe-card-payment.component.scss'],
})
export class StripeCardPaymentComponent extends HandleSubscriptionsComponent implements OnInit {
  @Input() plan: any;
  cardHolderName: string;
  basicInfo: any;

  loading: boolean = false;

  elementsOptions: StripeElementsOptions = {
    //locale: 'es'
  };

  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  cardOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#7A7A7A',
        lineHeight: '40px',
        fontWeight: '600',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
  };

  constructor(
    private modalCtrl: ModalController,
    private _basicInfoResolver: RestaurantInfoResolver,
    private stripeService: StripeService,
    private subscriptionService: SubscriptionService,
    private toaster: ToastMessageService,
  ) {
    super();
  }



  ngOnInit() {
    this.stripeService.setKey(
      environment.STRIPE_PUBLIC_KEY
    );
    this.subscribeBasicInfo();

  }



  /**
   * subscribe basic info
   */
  subscribeBasicInfo() {
    this.handleSubscription(this._basicInfoResolver.restaurantBasicInfo,
      (res) => {
        this.basicInfo = res;
      }
    );
  }


  /**
   * on Submit
   */
  onSubmit() {
    this.loading = true
    // return;
    this.handleSubscription(this.stripeService.createToken(this.card.element, { name: this.cardHolderName }),
      (result) => {
        if (result.token) {
          this.createSubscription(result.token.id);
        } else if (result.error) {
          this.toaster.errorToastWithClose(result.error.message);
        }
      },
      (err) => {
        this.loading = false;
        this.toaster.errorToastWithClose('Failed to Subscribe Plan');
      }
    );


    // this.modalCtrl.dismiss(); 
  }


  /**
   * Create subscription
   */
  createSubscription(token: string) {
    const payload = {
      name: this.cardHolderName,
      emailId: this.basicInfo.emailId,
      price: this.plan.priceId,
      couponId: this.plan?.coupon?.id,
      stripePaymentModel: {
        paymentToken: token
      }
    };
    this.handleSubscription(this.subscriptionService.createSubscription(payload),
      (res) => {
        this.loading = false;
        this.toaster.successToastWithClose('Plan Subcribe Successfully!');
        this.closeModal('success');
      },
      (err) => {
        this.loading = false;
        this.toaster.errorToastWithClose('Error Subcribing plan: ' + err);
      }
    );
  }



 
  closeModal(action?:string) {
    this.modalCtrl.dismiss(action || '');
  }

}
