import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';
import { IStore } from 'src/app/appModels/IStore';
import { StoreResolver } from 'src/app/appResolvers/store.resolver';
import { StripePaymentService } from 'src/app/appServices/stripe-payment.service';
import { LoaderUtility } from 'src/app/appUtilities/loader.utility';
import { ToastMessageService } from 'src/app/core/utilities/toast-message.service';

@Component({
  selector: 'app-reader-location',
  templateUrl: './reader-location.page.html',
  styleUrls: ['./reader-location.page.scss'],
})
export class ReaderLocationPage extends HandleSubscriptionsComponent implements OnInit {
  locationList: any[];
  locationForm: UntypedFormGroup;
  createNew = false;

  locationId: string;
  selectedStore: IStore;

  constructor(
    private fb: UntypedFormBuilder,
    private modalCtrl: ModalController,
    private stripePaymentService: StripePaymentService,
    private toasterMessage: ToastMessageService,
    private loadingService: LoaderUtility,
    private storeResolver: StoreResolver
  ) { 
    super();
  }

  ngOnInit() {
    this.subscribeStore();
    this.locationId = localStorage.getItem("location-id");
  
    this.initLocationForm();
  }

  subscribeStore() {
    this.handleSubscription(this.storeResolver.selectedStore,
      (res) => {
        this.selectedStore = res;
        this.getAllLocation();
      }
    );
  }

  initLocationForm() {
    this.locationForm = this.fb.group({
      displayName: ['', Validators.required],
      address: ['', Validators.required],
      postalCode: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      country: ['', Validators.required]
    });
  }

  getAllLocation() {
    this.loadingService.showLoader();
    this.handleSubscription(this.stripePaymentService.getAllLocations(this.selectedStore.id),
      (res) => {
        this.loadingService.hideLoader();
        this.locationList = res;
      },
      (err) => { 
        this.loadingService.hideLoader();
      }
    );
  }

  onCreateLocation() {
    if (this.locationForm.invalid) {
      return false;
    }
    this.loadingService.showLoader();
    this.handleSubscription(this.stripePaymentService.createLocation(this.locationForm.value, this.selectedStore.id),
      (res) => {
        this.loadingService.hideLoader();
        this.createNew = false;
        this.getAllLocation();
      },
      (err) => {
        this.loadingService.hideLoader();
        this.toasterMessage.errorToastWithClose(err.details[0]);
      }
    );
  }

  save() {
    if (!this.locationId) return;
    localStorage.setItem('location-id', this.locationId);
    this.closeModal();
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
