import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StripeManualCardComponent } from './stripe-manual-card/stripe-manual-card.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxStripeModule } from 'ngx-stripe';
import { SquareManualCardComponent } from './square-manual-card/square-manual-card.component';



@NgModule({
  declarations: [
    StripeManualCardComponent,
    SquareManualCardComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    NgxStripeModule,
  ],
  exports: [
    StripeManualCardComponent,
    SquareManualCardComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CardPaymentModule { }
