import { Directive, ElementRef, AfterViewInit, HostListener } from '@angular/core';
import { fromEvent } from 'rxjs';

@Directive({
  selector: '[stopPropagation]',
})
export class StopPropagationDirective {
  @HostListener("click", ["$event"])
  public onClick(event: any): void
  {
      event.stopPropagation();
  }
}