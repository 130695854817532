import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IBasicInfo } from '../appModels/IBasicInfo';
import { ICartDetails } from '../appModels/ICartDetails';
import { ITable } from '../appModels/ITable';
import { CartService } from '../appServices/cart.service';
import { RestaurantInfoResolver } from './restaurant-info.resolver';
import { SettingsResolver } from './settings.resolver';
import { CannabisSettingsService } from 'src/app/appServices/biotrack/cannabis-settings.service';
import { StoreResolver } from './store.resolver';
import { IStore } from '../appModels/IStore';

@Injectable({
  providedIn: 'root',
})
export class CartResolver {
  tipAmount = new BehaviorSubject<number>(0);
  coupon = new BehaviorSubject<any>({ couponCode: null, couponValue: 0 });
  reward = new BehaviorSubject<any>(null);
  cart = new BehaviorSubject<ICartDetails[]>([]);
  tax: number;
  exciseTax: number = 0.12;
  tables = new BehaviorSubject<ITable[]>([]);
  selectedTable = new BehaviorSubject<string>('');
  tipAmountVal: number = 0;
  couponValue: number = 0;
  couponCode: String;
  rawCouponValue: number = 0;
  discountType: String;
  disCount = new BehaviorSubject<number>(0);
  isMedicalGuest;
  basicInfo: IBasicInfo;
  cannabisTaxIncluded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  productId: number;
  // Define the missing properties
  cartSubtotalPrice: number = 0;
  totalAmount: number = 0;
  store: IStore;


  constructor(
    private _basicInfoResolver: RestaurantInfoResolver,
    private _settingResolver: SettingsResolver,
    private cannabisSettingsService: CannabisSettingsService,
    private _storeResolver: StoreResolver,
    private _cartService: CartService,
  ) {
    this.subscribeBasicInfo();
    this.getSelectedTableFromSessionStorage();
    this.getCartFromSessionStorage();
    this.subscribeTaxSettings();
    this.getExciseTaxRate();
    this.getAllTableNo();
    this.getTipAmount();
    this.getCoupon();
    this.subscribeSelectedStore();
  }

  subscribeBasicInfo() {
    this._basicInfoResolver.restaurantBasicInfo.subscribe(res => {
      this.basicInfo = res;
    });
  }


  /**
   * Subscribe Selected Store
   */
  subscribeSelectedStore() {
    this._storeResolver.selectedStore.subscribe(res =>{
      if(res){
        this.store = res;
        this.getCannabisTaxIncluded();
      }
    });
  }

  getCannabisTaxIncluded(): void {
    this.cannabisSettingsService.getSettings(this.store.id).subscribe((settings) => {
      if (settings) {
        const taxIncludedSetting = settings.find((setting) => setting.name === 'taxIncluded');
        if (taxIncludedSetting) {
          this.cannabisTaxIncluded.next(+taxIncludedSetting.value === 1);
        }
      }
    });
  }

  subscribeTaxSettings() {
    this._settingResolver.tax.subscribe((taxSettingResponse) => {
      this.tax = taxSettingResponse;
    });

    this._settingResolver.isMedicalGuest.subscribe(res => {
      this.isMedicalGuest = res;
    });
  }

  getCartFromSessionStorage() {
    const cart_ = sessionStorage.getItem('cart');
    if (cart_) {
      this.cart.next(JSON.parse(cart_));
    } else {
      this.cart.next([]);
    }
  }

  addProductInCart(cartDetail: ICartDetails) {
      const currentCart = this.cart.value;
      if (currentCart.length === 0) {
          this.resetCoupon();
      }

      // Make a deep copy of the cart detail to ensure all properties are retained
      const newCartDetail = { ...cartDetail, productCannabisModel: cartDetail.productCannabisModel };

      const cartCopy = [...currentCart, newCartDetail];
      this.cart.next(cartCopy);
      this.updateSessionStorage();
  }




  removeProductFromCart(index: number) {
      const currentCart = this.cart.value; // Access the current cart
      currentCart.splice(index, 1); // Remove the product at the specified index
      this.cart.next(currentCart); // Update the BehaviorSubject with the new cart array

      // Reset the coupons if the cart is now empty
      if (currentCart.length === 0) {
        this.resetCoupon();
      }
      this.updateSessionStorage(); // Update the session storage
  }

  removeRewardItemFromCart() {
    const currentCart = this.cart.value; 
    const updatedCart = currentCart.filter(item => !item.rewardItemDiscount?.id);
    this.cart.next(updatedCart); 
    this.reward.next(null);

    // Reset the coupons if the cart is now empty
    if (updatedCart.length === 0) {
      this.resetCoupon();
    }
    this.updateSessionStorage(); // Update the session storage
  }




  updateProductInCart(index: number, cartDetail: ICartDetails) {
      const currentCart = this.cart.value;
      // Preserve the existing productCannabisModel if it exists
      const updatedCartDetail = {
          ...cartDetail,
          productCannabisModel: cartDetail.productCannabisModel || currentCart[index].productCannabisModel
      };
      currentCart[index] = updatedCartDetail;
      this.cart.next(currentCart);

      if (currentCart.length === 1) {
          this.resetCoupon();
      }
      this.updateSessionStorage();
  }





  getCartDetailsByIndex(index) {
    return JSON.parse(JSON.stringify(this.cart.value[index]));
  }

  clearCart() {
    this.cart.next([]);
    this.clearSessionStorage();
  }

  private updateSessionStorage() {
    sessionStorage.setItem('cart', JSON.stringify(this.cart.value));
    this.getCartFromSessionStorage();
  }

  private clearSessionStorage() {
    sessionStorage.removeItem('cart');
    this.getCartFromSessionStorage();
  }

  get totalPriceOfCart(): number {
    let total = 0;
    if (this.cart.value) {
      this.cart.value.forEach((item) => {
        let price = item.price;

        if (item.rewardItemDiscount && item.rewardItemDiscount.discountType) {
          switch (item.rewardItemDiscount.discountName) {
            case 'item on discount':
              if (item.rewardItemDiscount.discountType === 'percentage') {
                this.reward.next({...this.reward.value, rewardAmountVal:(price * item.rewardItemDiscount.discount) / 100});
                price -= (price * item.rewardItemDiscount.discount) / 100;
              } else if (item.rewardItemDiscount.discountType === 'flat') {
                this.reward.next({...this.reward.value, rewardAmountVal:item.rewardItemDiscount.discount});
                price -= item.rewardItemDiscount.discount;
              } else {
                // Handle invalid discount type within 'item on discount'
                console.error('Invalid discount type within item on discount:', item.rewardItemDiscount.discountType);
              }
              break;
            case 'buy 1 get 1': // New case
              this.reward.next({...this.reward.value, rewardAmountVal:item.rewardItemDiscount.discount});
              break;
            default:
              // Handle invalid discount name
              console.error('Invalid discount name:', item.rewardItemDiscount.discountName);
              break;
          }
        }


        total += (item.discountedPrice !== null && item.discountedPrice !== undefined) ? item.discountedPrice : price;
      });
    }

     
    if (this.reward.value !== null) {
      switch (this.reward.value.discountType) {
        case 'fixed':
          this.reward.next({...this.reward.value, rewardAmountVal:this.reward.value.rewardAmount});
          total -= this.reward.value.rewardAmount;
          break;
        case 'percentage':
          this.reward.next({...this.reward.value, rewardAmountVal:total * (this.reward.value.rewardAmount / 100)});
          total -= total * (this.reward.value.rewardAmount / 100);
          break;
        default:
          break;
      }
    }

    return Number.parseFloat(total.toFixed(2));
  }


  getTipAmount(): void {
    this.tipAmount.subscribe(res => {
      this.tipAmountVal = res;
    });
  }

  getCoupon(): void {
    this.coupon.subscribe(res => {
      this.couponValue = res.couponValue;
      this.couponCode = res.couponCode;
    });
  }

  get calculateTax(): number {
    if (this.basicInfo.businessType === 'cannabis' && !this.isMedicalGuest && !this.cannabisTaxIncluded.value) {
      return Number.parseFloat(
        (((this.totalPriceOfCart + this.tipAmountVal) * this.tax) / 100).toFixed(2)
      );
    }
    if (this.basicInfo.businessType !== 'cannabis') {
      return Number.parseFloat(
        (((this.totalPriceOfCart + this.tipAmountVal) * this.tax) / 100).toFixed(2)
      );
    }
    return 0;
  }

  get calculateExciseTax(): number {
    if (this.basicInfo.businessType === 'cannabis' && !this.isMedicalGuest && !this.cannabisTaxIncluded.value) {
      let totalBeforeTax = this.totalPriceOfCart + this.tipAmountVal; // Sum of cart and tip
      let taxAmount = totalBeforeTax * this.exciseTax; // Calculate the raw tax amount
      let exciseTaxValue = Number.parseFloat(taxAmount.toFixed(2)); // Format to 2 decimal places
      return exciseTaxValue;
    }
    return 0;
  }

  getExciseTaxRate(): number {
    const currentYear = new Date().getFullYear();
    if (currentYear >= 2025) {
      this.exciseTax += 0.01 * (currentYear - 2025);
      this.exciseTax = Math.min(this.exciseTax, 0.18); // Cap the tax rate at 18%
    }

    return this.exciseTax;
  }

  get subtotal(): number {
    let total = 0;
    if (this.cart.value) {
      this.cart.value.forEach(item => {
        total += item.price * item.quantity; // considering the quantity
      });
    }
    return Number.parseFloat(total.toFixed(2));
  }

  get subtotalAfterCoupon(): number {
    let total = 0;
    if (this.cart.value) {
      this.cart.value.forEach(item => {
        total += (item.discountedPrice !== null && item.discountedPrice !== undefined) ? item.discountedPrice : item.price;
      });
    }
    return Number.parseFloat(total.toFixed(2));
  }

  get calculateGrandTotal(): number {
    const subtotal = this.subtotalAfterCoupon;
    const tax = this.calculateTax;
    const exciseTax = this.calculateExciseTax;
    const tip = this.tipAmountVal;

    return Number.parseFloat((subtotal + tax + exciseTax + tip).toFixed(2));
  }



  public getCartItemCount(): number {
    return this.cart.value.length;
  }

  getAllTableNo() {
    this._cartService.getAllTableNo().subscribe((response) => {
      this.tables.next(response || []);
    });
  }

  getSelectedTableFromSessionStorage() {
    const table_ = sessionStorage.getItem('selectedTable');
    table_ ? this.selectedTable.next(table_) : this.selectedTable.next('');
  }

  getCouponCodesByClientType(storeId: number, currDate): Observable<any> {
    return this._cartService.getCouponCodesByClientType(storeId, currDate);
  }

  getCouponByCouponCode(storeId: number, couponCode: string): Observable<any> {
    const currDate = new Date().setHours(0, 0, 0, 0);
    return this._cartService.getCouponByCouponCode(storeId, couponCode, currDate);
  }

  resetCoupon() {
    this.coupon.next({ couponCode: '', couponValue: 0 });
  }


  removeCouponFromItem(item: ICartDetails) {
      const index = this.cart.value.indexOf(item);
      if (index !== -1) {
          // Create a copy of the item and preserve productCannabisModel
          item = {
              ...item,
              discountedPrice: null,
              couponApplied: false,
              rawCouponValue: null,
              discountType: null,
              productCannabisModel: item.productCannabisModel // Preserve productCannabisModel
          };
          this.cart.value[index] = item;
          this.updateSessionStorage();
          this.recalculateCouponValue();
          this.calculateCartTotals();
      }
  }


    recalculateCouponValue() {
      const cartItems = this.cart.value;
      let couponValue = 0;

      cartItems.forEach(item => {
        if (item.couponApplied && item.discountedPrice !== null && item.discountedPrice !== undefined) {
          couponValue += item.price - item.discountedPrice;
        }
      });

      if (couponValue > 0) {
        this.coupon.next({ couponCode: this.couponCode, couponValue });
      } else {
        this.resetCoupon();
      }
    }





  calculateCartTotals() {
    const cartItems = this.cart.value;
    let subtotal = 0;
    let couponValue = 0;

    cartItems.forEach(item => {
      subtotal += item.discountedPrice !== null && item.discountedPrice !== undefined ? item.discountedPrice : item.price;
      if (item.couponApplied && item.discountedPrice !== null && item.discountedPrice !== undefined) {
        couponValue += item.price - item.discountedPrice;
      }
    });

    this.cartSubtotalPrice = subtotal;
    this.coupon.next({ couponCode: this.couponCode, couponValue });
    this.totalAmount = this.calculateGrandTotal; // Update the total amount
  }

  calculateCartTotalsNoCents() {
    const cartItems = this.cart.value;
    let subtotal = 0;
    let couponValue = 0;

    cartItems.forEach(item => {
      subtotal += item.discountedPrice !== null && item.discountedPrice !== undefined ? item.discountedPrice : item.price;
      if (item.couponApplied && item.discountedPrice !== null && item.discountedPrice !== undefined) {
        couponValue += item.price - item.discountedPrice;
      }
    });

    this.cartSubtotalPrice = Math.floor(subtotal);
    this.coupon.next({ couponCode: this.couponCode, couponValue });
    this.totalAmount = Math.floor(this.calculateGrandTotalNoCents); // Update the total amount
  }

  get calculateGrandTotalNoCents(): number {
    const subtotal = this.subtotalAfterCouponNoCents;
    const tax = this.calculateTaxNoCents;
    const exciseTax = this.calculateExciseTaxNoCents;
    const tip = this.tipAmountVal;

    return Math.floor(subtotal + tax + exciseTax + tip); // Floor the total amount
  }

  get subtotalAfterCouponNoCents(): number {
    let total = 0;
    if (this.cart.value) {
      this.cart.value.forEach(item => {
        total += (item.discountedPrice !== null && item.discountedPrice !== undefined) ? item.discountedPrice : item.price;
      });
    }
    return Math.floor(Number.parseFloat(total.toFixed(2)));
  }

  get calculateTaxNoCents(): number {
    if (this.basicInfo.businessType === 'cannabis' && !this.isMedicalGuest && !this.cannabisTaxIncluded.value) {
      return Math.floor(
        ((this.totalPriceOfCart + this.tipAmountVal) * this.tax) / 100
      );
    }
    if (this.basicInfo.businessType !== 'cannabis') {
      return Math.floor(
        ((this.totalPriceOfCart + this.tipAmountVal) * this.tax) / 100
      );
    }
    return 0;
  }

  get calculateExciseTaxNoCents(): number {
    if (this.basicInfo.businessType === 'cannabis' && !this.isMedicalGuest && !this.cannabisTaxIncluded.value) {
      let totalBeforeTax = this.totalPriceOfCart + this.tipAmountVal; // Sum of cart and tip
      let taxAmount = totalBeforeTax * this.exciseTax; // Calculate the raw tax amount
      return Math.floor(taxAmount); // Floor the excise tax value
    }
    return 0;
  }


}
