import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';
import { IStore } from 'src/app/appModels/IStore';
import { RestaurantInfoResolver } from 'src/app/appResolvers/restaurant-info.resolver';
import { SettingsResolver } from 'src/app/appResolvers/settings.resolver';
import { StoreResolver } from 'src/app/appResolvers/store.resolver';
import { OrderService } from 'src/app/appServices/order.service';
import { ProcessingFeeService } from 'src/app/appServices/processing-fee.service';
import { StripePaymentService } from 'src/app/appServices/stripe-payment.service';
import { environment } from 'src/environments/environment';
import { ChangeDetectorRef } from '@angular/core';
import { CustomDiscountComponent } from 'src/app/includes/popups/custom-discount/custom-discount.component';
import { StationService } from 'src/app/appServices/station.service';
import { CashMgmtService } from 'src/app/appServices/cash-mgmt.service';

@Component({
  selector: 'app-stripe-terminal-S700',
  templateUrl: './stripe-terminal-S700.component.html',
  styleUrls: ['./stripe-terminal-S700.component.scss'],
})
export class StripeTerminalS700Component extends HandleSubscriptionsComponent implements OnInit {
  basicInfo: any;
  // terminal: any;
  readerStatus = 'Not connected';
  paymentStatus = 'Pending';
  paymentDone = false;
  change = 0;
  connectionToken: string;
  cardReaderNumber: string;
  cardReaderInitiated = false;  // New variable to track if card reader was initiated
  @Input() dataPayload: any;
  cardAnimationJson: string = "https://assets4.lottiefiles.com/packages/lf20_pu02tdjw.json";

  errorLog: string;
  duration: number = 10000;
  selectedStore: IStore;
  stripeProcessingFees: any = {};
  station: any;
  device: any;
  secretKey:string;

  constructor(
    private orderService: OrderService,
    private stripePaymentService: StripePaymentService,
    private infoStorage: RestaurantInfoResolver,
    private modalCtrl: ModalController,
    private processingFeeService: ProcessingFeeService,
    private storeResolver: StoreResolver,
    private _settingResolver: SettingsResolver,
    private cashMgmtService: CashMgmtService,
    private cdr: ChangeDetectorRef,
    private stationService: StationService
  ) {
    super();
  }

  ngOnInit() {
    this.getDuration();
    this.subscribeStore();
    this.subscribeSelectedStation();
    this.subscribeStripeProcessingFees();
    this.getBasicInfo();
    this.getCardReaderInfoFromCookie();
  }


  /**
   * Get Duration
   */
  getDuration(){
    this.cashMgmtService.getConfiguration('cardReaderDuration').subscribe(res=>{
      this.duration = (+res.value)*1000;
    });
  }


  subscribeStore() {
    this.handleSubscription(this.storeResolver.selectedStore,
      (res) => {
        this.selectedStore = res;
      }
    );
  }

  subscribeStripeProcessingFees() {
    this.handleSubscription(this._settingResolver.stripeProcessingFee,
      (res) => {
        this.stripeProcessingFees = res;
      }
    );
  }

  getBasicInfo() {
    this.handleSubscription(this.infoStorage.restaurantBasicInfo,
      (res) => {
        if (res) {
          this.basicInfo = res;
        }
      }
    );
  }

  getCardReaderInfoFromCookie() {
    // this.cardReaderNumber = localStorage.getItem('card-reader-number');
    this.cardReaderNumber = this.device.deviceCode;
  }

  async openCustomDiscountModal() {
    try {
      const modal = await this.modalCtrl.create({
        component: CustomDiscountComponent,
        componentProps: {
          currentTotal: this.dataPayload.totalAmount
        }
      });

      await modal.present();

      const { data } = await modal.onDidDismiss();
      if (data) {
        const discount = data;
        this.applyDiscountToTotal(discount);
      }
    } catch (error) {
      console.error('Error opening custom discount modal:', error);
    }
  }

  applyDiscountToTotal(discount) {
    if (discount.discountType === 'percentage') {
      const discountAmount = this.dataPayload.totalAmount * (discount.value / 100);
      this.dataPayload.totalAmount -= discountAmount;
    } else if (discount.discountType === 'custom') {
      this.dataPayload.totalAmount = this.dataPayload.totalAmount - discount.value; // Set the custom amount as the new total
    }

    // Ensure change detection is aware of the updated total amount
    this.cdr.detectChanges();
  }

  removeCents() {
    this.dataPayload.totalAmount = Math.floor(this.dataPayload.totalAmount);
    this.cdr.detectChanges();  // Ensure Angular detects the change and updates the UI
  }


  calculateTotalAmount(dataPayload): number {
    const subtotal = dataPayload.totalAmount - dataPayload.processingFee;
    return this.processingFeeService.getCalculatedTotalAmountForStripeCardReader(this.dataPayload.totalAmount, subtotal, this.stripeProcessingFees) * 100;
  }

  calculatePlatformFeeAmount(amount: number): number {
    return this.processingFeeService.getCalculatedPlatformFeeAmount(this.dataPayload.totalAmount, this.stripeProcessingFees) * 100;
  }


  /**
   * Subscribe Selected Station
   */
  subscribeSelectedStation() {
    this.stationService.selectedStation.subscribe(res => {
      this.station = res;
      this.getDevice(this.station);
    });
  }

  /**
   * get device
   */
  getDevice(station: any) {
    this.stationService.getDevice(station, 'Stripe Register Device').subscribe(res => {
      this.device = res;
    });
  }



  // New method to initiate connection with card reader
  connectWithCardReader() {
    this.cardReaderInitiated = true;  // Track that the card reader was initiated
    this.errorLog = null; // Reset error log on initiating connection
    this.readerStatus = 'Connecting to card reader...';
    this.getClientSecret();
    this.cdr.detectChanges();  // Ensure the UI updates
  }


  async getClientSecret() {
    this.readerStatus = 'Getting client secret...';

    const url = `${environment.baseUrl}payment-gateway/client/stripe/connection_token/${this.selectedStore.id}`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'tenant-name': localStorage.getItem('tenant')
      }
    });
    const content = await response.json();
    this.secretKey = content.secret;
    this.createPaymentIntent();

  }


  /**
   * Create payment intent
   */
  async createPaymentIntent() {
    this.readerStatus = 'Creating payment intent...';

    const url = `${environment.baseUrl}payment-gateway/client/stripe/client_secret/${this.selectedStore.id}`;

    const payload = JSON.stringify({
      totalAmount: this.dataPayload.totalAmount * 100,
      platformFee: this.dataPayload.platformFee
    });

    const response = await fetch(url, {
      method: 'POST',
      body: payload,
      headers: {
        'Content-Type': 'application/json',
        'tenant-name': localStorage.getItem('tenant')
      }
    });
    const content = await response.json();
    const paymentIntentId = content.clientSecret.substring(0, content.clientSecret.indexOf("_secret"));

    this.registerPayment(paymentIntentId);
  }



  /**
   * Register payment
   */
  async registerPayment(paymentIntentId: string) {
    this.readerStatus = 'Registering payment...';

    const url = `${environment.baseUrl}payment-gateway/client/stripe/register-payment/${this.selectedStore.id}/${this.station}/${paymentIntentId}`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'tenant-name': localStorage.getItem('tenant')
      }
    });
    const content = await response.json();
    this.processPayment(paymentIntentId);
  }


  /**
   * Process payment
   */
  async processPayment(paymentIntentId: string) {
    this.readerStatus = 'Payment processing...';
    const url = `${environment.baseUrl}payment-gateway/client/stripe/create-payment/${this.selectedStore.id}/${this.station}`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'tenant-name': localStorage.getItem('tenant')
      }
    });
    const content = await response.json();
    this.checkStatus(0, paymentIntentId);
  }


  async checkStatus(retryCount: number = 0, paymentIntentId?: string) {
    this.readerStatus = 'Checking payment status...';
    const url = `https://api.stripe.com/v1/terminal/readers/${this.device.deviceCode}`;

    try {
      const response: any = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.secretKey,
        }
      });

      const content = await response.json();

      // Check for "succeeded" status inside the action object
      if (content && content.action && content.action.status === "succeeded") {
        this.readerStatus = 'Payment status check successful.';
        this.sendIntentToServer(paymentIntentId);
        return; // Success, exit the function
      } else {
        return this.handleRetry(retryCount, paymentIntentId, 'Action status not succeeded'); // Retry
      }

    } catch (error) {
      console.error('Error during payment status check:', error);
      return this.handleRetry(retryCount, paymentIntentId, 'General Error'); // Retry for general errors
    }
  }


  // Separate function to handle retry logic
  private handleRetry(retryCount: number, paymentIntentId: string, errorMessage: string) {
    if (retryCount < 8) {
      console.log(`${errorMessage}. Retrying (${retryCount + 1})...`);
      this.readerStatus = `${errorMessage}. Retrying (${retryCount + 1})...`; // Update user
      setTimeout(() => this.checkStatus(retryCount + 1, paymentIntentId), this.duration); // Use setTimeout directly
    } else {
      this.readerStatus = 'Payment status check failed after multiple retries.';
      this.paymentStatus = 'Failed';
      console.error('Payment status check failed after 8 retries.');
    }
  }


  sendIntentToServer(paymentIntent) {
    switch (this.dataPayload.type) {
      case "pay by orderId":
        this.checkoutByOrderId(paymentIntent);
        break;
      case "pay by order":
        this.checkout(paymentIntent);
        break;
      case "pay by paymentIntent":
        this.checkoutForSplitOrder(paymentIntent, this.dataPayload);
        break;
    }
  }


  /**
   * checkout
   */
  checkout(paymentIntentId) {
    this.readerStatus = 'Capturing payment...';
    const temp = this.dataPayload;
    temp.paymentIntentId = paymentIntentId;
    this.handleSubscription(this.orderService.createOrderByCardReader(paymentIntentId, 'Stripe Card Reader', temp),
      (res) => {
        if (res) {
          this.afterPayment(res);
        }
      }
    );
  }

  /**
   * Checkout for saved order
   * @param paymentIntentId 
   */
  checkoutByOrderId(paymentIntentId) {
    this.readerStatus = 'Capturing payment...';

    this.dataPayload.paymentIntentId = paymentIntentId;
    this.dataPayload.paid = true;
    this.dataPayload.paymentType = 'card';
    const temp = {
      paymentIntentId: this.dataPayload.paymentIntentId,
      totalAmount: this.dataPayload.totalAmount,
      processingFee: this.dataPayload.processingFee
    };

    this.handleSubscription(this.orderService.markCardOrderAsPaid(this.dataPayload.id, temp),
      (res) => {
        this.paymentStatus = 'Payment is done successfully.';
        this.dataPayload.paid = true;
        this.dataPayload.paymentType = 'card';
        this.closeCardReaderModel(this.dataPayload);
      },
      (error) => {
        console.error('Failed to mark order as paid:', error);
        this.errorLog = 'Failed to mark order as paid. Please try again.';
        this.paymentStatus = 'Payment failed. Please check the connection.';
        this.paymentDone = false;
        this.cdr.detectChanges();
      }
    );
  }


  checkoutForSplitOrder(paymentIntentId, paymentModel, isPlatformAttempt: boolean = false) {
    this.readerStatus = 'Capturing payment...';
    const temp = {
      amount: paymentModel.totalAmount,
      paymentType: "card",
      processingFee: paymentModel.processingFee
    };
    this.handleSubscription(this.orderService.createCardPaymentForOrderByPaymentIntent(paymentModel.orderId, paymentIntentId, temp),
      (res) => {
        this.paymentStatus = 'Payment is done successfully.';
        this.paymentDone = true;
        this.closeCardReaderModel(paymentModel);
      },
      (err) => {
        this.errorLog = "Error in creating order.";
      }
    );
  }




  afterPayment(id: number) {
    this.paymentStatus = 'Payment is done successfully.';
    this.paymentDone = true;
    const temp = { id, paymentType: 'Card Reader' };
    this.closeCardReaderModel(temp);
  }



  closeCardReaderModel(response): void {
    this.errorLog = null; // Clear error log on closing the modal
    this.modalCtrl.dismiss(response);
  }




}
