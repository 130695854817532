import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { TenantResolver } from 'src/app/appServices/tenant/tenant.resolver';

@Injectable({
  providedIn: 'root'
})
export class TenentIdentifierInterceptor implements HttpInterceptor {
  constructor(
    private _tenantResolver: TenantResolver
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const headers = this.addTenantToHeaders(request.headers);
      request = request.clone({
        headers: headers
      });
    return next.handle(request)
      .pipe(
        filter(event => event instanceof HttpResponse),
        tap((event: HttpResponse<any>) => {
        }));

  }


  /**
  * create new header with tenant name
  * @param headers 
  * @returns 
  */
  private addTenantToHeaders(headers: HttpHeaders): HttpHeaders {
    const tenantName = this._tenantResolver.getTenantName();
    if (tenantName) {
      return headers.append('tenant-name', tenantName);
    }
  }
}