import { Injectable } from '@angular/core';
import { OrderService } from '../order.service';
import { ReceiptUtility } from '../../appUtilities/receipt.utility';
import { PrintService, UsbDriver, WebPrintDriver } from 'ng-thermal-print';
import { PrintDriver } from 'ng-thermal-print/lib/drivers/PrintDriver';
import { PrintEpsonService } from './print-epson.service';
import { BehaviorSubject, take, of, throwError } from 'rxjs';
import { catchError, tap, delay, retryWhen } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ReceiptPrinterService {
  usbPrintDriver: UsbDriver;
  webPrintDriver: WebPrintDriver;
  orderDetails: any;

  private globalPrinterLogsSource = new BehaviorSubject<string>('');
  globalPrinterLogs$ = this.globalPrinterLogsSource.asObservable();


  constructor(
    private printService: PrintService,
    private printEpsonService: PrintEpsonService,
    private _receiptUtility: ReceiptUtility,
    private orderService: OrderService,
  ) {
  }

  // In your Angular service

  /**
   * Update global printer logs
   * @param logs
   */
  updateGlobalPrinterLogs(logs: string) {
    this.globalPrinterLogsSource.next(logs);
  }


  isElectronApp() {
    return window.electronAPI && window.electronAPI.isElectron();
  }



  /**
   * Print Reciept
   * @param orderId
   * @param change
   */
  printStuff(orderId: number, change?: any) {
    this.getOrderDetailsAndPrint(orderId, change);
  }



  /**
   * Get order details & print
   * @param orderId
   * @param change
   */
  getOrderDetailsAndPrint(orderId: number, changes?: any) {
    this.orderService.getOrderById(orderId).subscribe(
      (res) => {
        this.orderDetails = res;
        // this.orderDetails.changes = changes;
        // this._receiptUtility.printPreview(this._receiptUtility.createReceiptString(this.orderDetails));
        // this._receiptUtility.printPreview(this._receiptUtility.createReceiptHTML(this.orderDetails));
        // return;
        
        
        this.getAllPrinters();
      },
      (error) => {
        console.error('Error fetching order details', error);
      }
    );
  }



  /**
   * Get all printers
   */
   getAllPrinters() {
     const storedPrinters = localStorage.getItem('printers');
     const printerList = storedPrinters ? JSON.parse(storedPrinters) : [];
     this.printOnConnectedPrinters(printerList);
   }

  /**
   * Print on connected printers
   * @param printerList
   */
  printOnConnectedPrinters(printerList: any[]) {
    for (const printer of printerList) {
      if (printer.connected && printer.printerType == 'Receipt Printer') {

        
        // Electron Case
        if (this.isElectronApp() && printer.connectionType === 'usb') {
          this.printElectron(printer);
        }else{
          // Common case
          this.printCommon(printer);
        }

      }
    }
  }



  /**
   * Print on ConnectionType
   * @param printer
   */
  printCommon(printer: any) {this.epsonIpPrint(printer);
    this.connectAndWebPrint(printer);
  }


  /**
   * Epson Ip Print
   * @param printer
   */
  epsonIpPrint(printer: any) {
    if (printer.brand === 'Epson', printer.connectionType === 'ip' && printer.connected) {
      this.printEpsonService.print(this.orderDetails);
    }
  }

  /**
   * Star Ip Print
   * @param printer
   */
  connectAndWebPrint(printer: any) {
    if (printer.brand === 'Star' && printer.connected) {
      this.connectToWebPrint(printer);
      this.printToWebPrint(this._receiptUtility.createReceiptString(this.orderDetails), this.webPrintDriver);
    }
  }


  /**
   * Connect to web print
   */

   connectToWebPrint(printer: any) {
     this.updateGlobalPrinterLogs(`${printer.deviceName}: Attempting to connect...`);
     this.webPrintDriver = new WebPrintDriver(printer.ip, true);
     this.printService.setDriver(this.webPrintDriver, 'WebPRNT');
     const content = this._receiptUtility.createReceiptString(this.orderDetails);
     this.tryConnectWebPrint(printer, 0, () => this.printToWebPrint(content, this.webPrintDriver));
   }


   tryConnectWebPrint(printer: any, attempt: number, onSuccess: () => void) {
     this.updateGlobalPrinterLogs(`Attempt #${attempt + 1} to connect to ${printer.deviceName}...`);
     setTimeout(() => {
       this.printService.isConnected.pipe(take(1)).subscribe({
         next: (res) => {
           if (res) {
             this.updateGlobalPrinterLogs(`${printer.deviceName}: Successfully connected after ${attempt + 1} attempts.`);
             onSuccess(); // Call the onSuccess callback function here
           } else {
             if (attempt < 9) {
               this.tryConnectWebPrint(printer, attempt + 1, onSuccess);
             } else {
               this.updateGlobalPrinterLogs(`${printer.deviceName}: Failed to connect after ${attempt + 1} attempts.`);
             }
           }
         },
         error: (err) => {
           this.updateGlobalPrinterLogs(`${printer.deviceName}: Connection attempt #${attempt + 1} failed with error: ${err}`);
           if (attempt < 9) {
             this.tryConnectWebPrint(printer, attempt + 1, onSuccess);
           } else {
             this.updateGlobalPrinterLogs(`${printer.deviceName}: Failed to connect after ${attempt + 1} attempts. Error: ${err}`);
           }
         }
       });
     }, 1000 * (attempt + 1)); // Incremental backoff strategy
   }


  /**
   * Print to web print
   * @param content
   * @param driver
   */
  printToWebPrint(content: string, driver: PrintDriver) {
    this.printService.init()
      .setBold(true)
      .writeLine(content)
      .setBold(false)
      .feed(4)
      .cut('full')
      .flush();
  }



  /**
   * Print for Electron Type
   * @param printer
   */
  printElectron(printer: any) {
    this.usbPrintOnElectron(printer, this._receiptUtility.createReceiptHTML(this.orderDetails));

  }



  /**
   * Usb print on electron
   * @param content
   * @returns
   */
   usbPrintOnElectron(printer: any, content: string) {
     // Assuming printers are stored as a JSON string in localStorage
    //  const storedPrinters = localStorage.getItem('printers');
    //  const printers = storedPrinters ? JSON.parse(storedPrinters) : [];
     const printerInfo = JSON.stringify({
      //  printers, // This ensures the data is sent as a stringified JSON object
       printers: [printer], // This ensures the data is sent as a stringified JSON object
     });

     // Send printer information first
     window.electronAPI.send('sendPrinterInfo', printerInfo);

     // Prepare and send the print command
     let printData = {
       content,
       printerName: printer.usbPrinter ? printer.usbPrinter.name : printer.deviceName,
     };
     window.electronAPI.send('print-receipt', printData);
   }

}
