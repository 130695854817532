import { Injectable } from '@angular/core';
import { Observable, map, of, tap} from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpConfigService } from 'src/app/core/httpConfig/httpConfigService';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
 
  api = environment.baseUrl + "stripe/subscription";
  cachedSubscriptions: { [emailId: string]: any } = {};
  cachedPlans: any;

  constructor(private apiConfig: HttpConfigService) { }


  /**
   * Get subscription
   * @param emailId
   * @returns 
   */
    getSubscription(emailId: string, forceRefresh: boolean = false): Observable<any> {
      // Prioritize cached subscription if available and forceRefresh is false
      if (!forceRefresh && this.cachedSubscriptions[emailId]) {
        return of(this.cachedSubscriptions[emailId]);
      }
    
      // Clear cached subscription if forceRefresh is true
      if (forceRefresh) {
        delete this.cachedSubscriptions[emailId];
      }
    
      // Make API call to retrieve subscription data
      return this.apiConfig.get(`${this.api}/${emailId}`).pipe(
        tap(response => this.cachedSubscriptions[emailId] = response)
      );
    }
    


  /**
   * Check if subscription is active
   * @param emailId 
   * @returns boolean
   */
  isSubscriptionActive(emailId: string): { isActive: boolean, daysLeft: number } {
    const subscription = this.cachedSubscriptions[emailId];
    if (subscription && subscription.current_period_end > Date.now() / 1000) {
      // Subscription is active
      const daysLeft = Math.ceil((subscription.current_period_end - Date.now() / 1000) / (60 * 60 * 24));
      // return { isActive: false, daysLeft: 0 };
      return { isActive: true, daysLeft: daysLeft };
    }
    // Subscription is not active
    return { isActive: false, daysLeft: 0 };
  }



  /**
   * Get all products (plans)
   * @returns 
   */
  getAllPlans(): Observable<any> {
    if (this.cachedPlans) {
      return of(this.cachedPlans);
    } else {
      return this.apiConfig.get(`${this.api}/products`).pipe(
        tap(response => this.cachedPlans = response)
      );
    }
  }


  /**
   * Create subscription
   * @param payload 
   * @returns 
   */
  createSubscription(payload: any): Observable<any> {
    return this.apiConfig.post(`${this.api}/create`, payload, {withCredentials: true});
  }


  /**
   * Start trial
   * @param payload 
   * @returns 
   */
  startTrial(payload: any): Observable<any> {
    return this.apiConfig.post(`${this.api}/create/trial`, payload, {withCredentials: true});
  }


  /**
   * Start trial with highest price plan
   * @param name 
   * @param emailId 
   * @returns 
   */
  // startTrialWithHighestPricePlan(name: string, emailId: string): Observable<any> {
  //   return this.getAllPlans().pipe(
  //     map((plans: any[]) => {
  //       const highestPricePlan = plans.reduce((prev, current) => (prev.defaultPrice > current.defaultPrice) ? prev : current);
  //       if (highestPricePlan) {
  //         const payload = {
  //           name: name,
  //           emailId: emailId,
  //           price: highestPricePlan.priceId
  //         };
  //         return this.startTrial(payload);
  //       } else {
  //         throw new Error('No plan found to start trial.');
  //       }
  //     })
  //   );
  // }


  /**
   * Clear all cached data
   */
  clearCache(): void {
    this.cachedSubscriptions = {};
    this.cachedPlans = null;
              
    // localStorage.removeItem('trialStarted');
  }


  /**
   * get coupon
   * @param coupon
   * @returns 
   */
  getCoupon(coupon: any): Observable<any> {
    return this.apiConfig.get(`${this.api}/coupon/${coupon}`, {withCredentials: true});
  }

}
