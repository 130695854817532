import { Component, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DISABLE_NAV } from './appConstants/DisableNavigationRoutes';
import { AppInitializerService } from './core/appInit/app-initializer.service';
import { BreakpointService } from './core/utilities/breakpoint.service';
import { IBasicInfo } from './appModels/IBasicInfo';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent{
  breakpoint$ = this._breakpointService.breakpoint;
  disableNav: boolean = false;
  basicInfo: IBasicInfo;
  showVerification:boolean = true;

  constructor(
    private appInitService: AppInitializerService,
    private _breakpointService: BreakpointService,
    private router: Router,
  ) {
    this.appInitService.initializeApp();
    this.checkUrlToDisableNav();
  }


  

  
  
  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    this._breakpointService.onResize(event.target.innerWidth);
  }
  

  isAuthRoute(): boolean {
    return this.router.url.startsWith('/auth') || this.router.url === '/';
  }

  hideVerification() {
    this.showVerification = false;
  }

  /**
   * Check Url to Disable Navigation tabs 
  */
  checkUrlToDisableNav() {
    this.router.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
          const isDisableNav = DISABLE_NAV.find(e => event.url.startsWith(e), event);
          if (isDisableNav) {
            this.disableNav = true;
          } else {
            this.disableNav = false;
          }

          let vh = window.innerHeight * 0.01;
          document.documentElement.style.setProperty('--vh', `${vh}px`);
          window.addEventListener('resize', () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
          });

        }
      }
    );
  }


}
