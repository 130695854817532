import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpConfigService {
  public get getBaseUrl() {
    return environment.baseUrl;
  }
  public get envProd() {
    return environment.production;
  }
  public get tenantApi() {
    return environment.tenantApi;
  }
  constructor(
    protected httpClient: HttpClient
  ) {}

  public get(url, options?: any | {}): Observable<any> {
    return this.httpClient
      .get(url, options);
  }

  public post(url, data: any, options?: any | {}): Observable<any> {
    return this.httpClient
      .post(url, data, options);
  }

  public delete(url, options?: any | {}): Observable<any> {
    return this.httpClient
      .delete(url, options);
  }

  public put(url, data?: any | {}, options?: any | {}): Observable<any> {
    return this.httpClient
      .put(url, data, options);
  }

  public patch(url, data?: any | {}, options?: any | {}): Observable<any> {
    return this.httpClient
      .patch(url, data, options);
  }
}
