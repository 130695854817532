import { Directive, Component, OnDestroy } from '@angular/core';
import { ViewWillLeave } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';

@Directive()
export abstract class HandleSubscriptionsComponent implements OnDestroy, ViewWillLeave {

  private subscriptions:Subscription[] = [];

  constructor() {}

  handleSubscription(source: Observable<any>, handler: any, errorHandler?:any) {
    const sub = source.subscribe(
        (res) => {
            handler(res);
        },
        (err) => {
            errorHandler(err);
        }
    );
    this.subscriptions.push(sub);
  }


  /**
   * Unsubscribe all subscriptions
   */
  unsubscribeAll(){
    for (const sub of this.subscriptions){
      sub.unsubscribe();
    }
  }


  ngOnDestroy(): void {
    this.unsubscribeAll();
  }


  ionViewWillLeave(): void {
    this.unsubscribeAll();
  }

}
