import { Component, OnInit } from '@angular/core';
import { Nav } from '../nav';
import { RestaurantInfoResolver } from 'src/app/appResolvers/restaurant-info.resolver';
import { IBasicInfo } from 'src/app/appModels/IBasicInfo';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';

@Component({
  selector: 'app-tab-menu',
  templateUrl: './tab-menu.component.html',
  styleUrls: ['./tab-menu.component.scss'],
})
export class TabMenuComponent extends HandleSubscriptionsComponent implements OnInit {
  current_tab = "pos" // Set this as you default page name
  public appPages:any[] = [];
  basicInfo: IBasicInfo;

  constructor(
    private _basicInfoResolver: RestaurantInfoResolver
  ) {
   super();
  }

  ngOnInit() {
    this.subscribeBasicInfo();
  }

  /**
   * subscribe basic info
   */
  subscribeBasicInfo() {
    this.handleSubscription(this._basicInfoResolver.restaurantBasicInfo,
      (res) => {
        this.basicInfo = res;
        this.appPages = Nav.filter(item => !(item.businessType ?? '') || item.businessType === (this.basicInfo?.businessType ?? ''));
      }
    );
  }


  setCurrentTab(ev: any) {
    this.current_tab = ev;
  }


}
