import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { RestaurantInfoResolver } from 'src/app/appResolvers/restaurant-info.resolver';
import { IBasicInfo } from 'src/app/appModels/IBasicInfo';

@Injectable()
export class BackupApiInterceptor implements HttpInterceptor {

  private basicInfo: IBasicInfo;

  constructor(private _basicInfoResolver: RestaurantInfoResolver) {
    this._basicInfoResolver.restaurantBasicInfo.subscribe(res => {
      this.basicInfo = res;
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestUrl = req.url;
    const baseUrl = environment.baseUrl;
    const tenantUrl = environment.tenantApi;

    // Define a helper function to determine the appropriate backup URL
    const getBackupUrl = (url: string): string => {
      if (url.startsWith(baseUrl)) {
        return environment.backupBaseUrl + url.substr(baseUrl.length);
      } else if (url.startsWith(tenantUrl)) {
        return environment.backupTenantApi + url.substr(tenantUrl.length);
      }
      return url; // Default case, should not be hit if properly configured
    };

    if (requestUrl.includes('stripe/subscription/create')) {
      return next.handle(req);
    } else if (requestUrl.startsWith(baseUrl) || requestUrl.startsWith(tenantUrl)) {
      return next.handle(req).pipe(
        catchError((error: any) => {
          // Check if the error is an HttpErrorResponse, indicating a failed HTTP call
          if (error instanceof HttpErrorResponse) {
            // Switch to the appropriate backup URL based on the request URL
            const backupUrl = getBackupUrl(requestUrl);
            const backupRequest = req.clone({ url: backupUrl });

            // Handle the backup request
            return next.handle(backupRequest).pipe(
              catchError((backupError: HttpErrorResponse) => {
                // Handle backup API error here
                console.error('Backup API failed:', backupError);

                // Check business type and format the error response for cannabis business type
                if (this.basicInfo?.businessType === 'cannabis' && backupError.error?.details) {
                  console.log(backupError);
                  return throwError(() => backupError);
                }

                // Create a new error object to return
                const errorMessage = backupError.message || 'Backup API failed';
                return throwError(() => new Error(errorMessage));
              })
            );
          }
          // If not an HttpErrorResponse, propagate the original error
          return throwError(() => error);
        })
      );
    } else {
      // If request URL does not start with base URL or tenant URL, proceed without backup
      return next.handle(req);
    }
  }
}
