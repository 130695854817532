import { Injectable } from '@angular/core';
import { HttpConfigService } from 'src/app/core/httpConfig/httpConfigService';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CannabisSettingsService {

  api:string  = environment.baseUrl + 'cannabis-settings';

  constructor(private apiConfig: HttpConfigService) { }

  updateCannabisSettings(settings: any): Observable<any> {
    return this.apiConfig.put(this.api, settings, {withCredentials: true});
  }
  getSettings(storeId:number): Observable<any> {
    return this.apiConfig.get(`${this.api}/getAll/${storeId}`);
  }

  syncInventory(storeId:number): Observable<any> {
    return this.apiConfig.get(this.api+'/inventory/checkup'+storeId);
  }
}
