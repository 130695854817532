import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-custom-discount',
  templateUrl: './custom-discount.component.html',
  styleUrls: ['./custom-discount.component.scss'],
})
export class CustomDiscountComponent implements OnInit, OnDestroy {

  @Input() currentTotal: number = 0;
  discountForm: FormGroup;
  discountedTotal: string = '0.00';  // Now a string to store formatted value
  discountExplanation: string = '';
  private formChangesSubscription: Subscription;

  constructor(
    private modalCtrl: ModalController,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.discountForm = this.fb.group({
      discountType: ['percentage', Validators.required],
      value: [null, [Validators.required, Validators.min(0.01), this.maxDiscountValidator.bind(this)]],
    });

    this.calculateDiscountedTotal();

    this.formChangesSubscription = this.discountForm.valueChanges.subscribe(() => {
      this.calculateDiscountedTotal();
      this.updateDiscountExplanation();
    });
  }

  ngOnDestroy() {
    if (this.formChangesSubscription) {
      this.formChangesSubscription.unsubscribe();
    }
  }

  maxDiscountValidator(control) {
    const discountType = this.discountForm?.get('discountType')?.value;
    const value = control.value;

    if (discountType === 'percentage' && value > 100) {
      return { max: true };
    } else if (discountType === 'fixed' && value > this.currentTotal) {
      return { max: true };
    }

    return null;
  }

  calculateDiscountedTotal() {
    const discountType = this.discountForm.get('discountType')?.value;
    const value = this.discountForm.get('value')?.value;

    let calculatedTotal = 0;

    if (discountType === 'percentage') {
      const discountAmount = this.currentTotal * (value / 100);
      calculatedTotal = this.currentTotal - discountAmount;
    } else if (discountType === 'fixed') {
      calculatedTotal = this.currentTotal - value;
    }

    if (isNaN(calculatedTotal) || calculatedTotal < 0) {
      calculatedTotal = 0;
    }

    // Format to 2 decimal places
    this.discountedTotal = calculatedTotal.toFixed(2);
  }

  updateDiscountExplanation() {
    const discountType = this.discountForm.get('discountType')?.value;
    const value = this.discountForm.get('value')?.value;

    if (discountType === 'percentage' && value !== null) {
      const discountAmount = this.currentTotal * (value / 100);
      this.discountExplanation = `Applying a ${value}% discount: ${this.currentTotal.toFixed(2)} - ${discountAmount.toFixed(2)} = ${this.discountedTotal}`;
    } else if (discountType === 'fixed' && value !== null) {
      this.discountExplanation = `Subtracting ${value.toFixed(2)} from ${this.currentTotal.toFixed(2)}: ${this.currentTotal.toFixed(2)} - ${value.toFixed(2)} = ${this.discountedTotal}`;
    } else {
      this.discountExplanation = '';
    }
  }

  applyDiscount() {
    if (this.discountForm.valid) {
      const discountType = this.discountForm.get('discountType')?.value;
      const value = this.discountForm.get('value')?.value;

      if (discountType === 'fixed') {
        this.modalCtrl.dismiss({
          discountType: 'custom',
          value: value.toFixed(2)  // Ensure value is formatted
        });
      } else {
        this.modalCtrl.dismiss({
          discountType: discountType,
          value: value.toFixed(2)  // Ensure value is formatted
        });
      }
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
