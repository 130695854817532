import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  darkMode = new BehaviorSubject<boolean>(false);
  constructor() {
    this.subscribeDarkMode();
    this.getThemeFromLocalStorage();
  }
  
  subscribeDarkMode(){
    this.darkMode.subscribe(res=>{
      if(!res) {
        document.body.classList.add('light');
        document.body.classList.remove('dark');
      }
      else{
        document.body.classList.add('dark');
        document.body.classList.remove('light');
      }
    });
  }

  getThemeFromLocalStorage(){
    const darkMode= JSON.parse(localStorage.getItem('darkMode'));
    this.darkMode.next(darkMode? true: false)
  }

  detectSystemTheme() {
    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      document.body.classList.add('dark');
      this.darkMode.next(true);
    }
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (event) => {
        const newColorScheme = event.matches ? 'dark' : 'light';
        if (newColorScheme === 'dark') {
          document.body.classList.add('dark');
          this.darkMode.next(true);
        } else {
          document.body.classList.remove('dark');
          this.darkMode.next(false);
        }
      });
  }

  onThemeToggle() {
    if(!this.darkMode.value){
      this.darkMode.next(true);
      localStorage.setItem('darkMode', JSON.stringify(true));
    }else{
      this.darkMode.next(false);
      localStorage.setItem('darkMode', JSON.stringify(false));
    }
  }


}
