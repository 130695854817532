import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpConfigService } from 'src/app/core/httpConfig/httpConfigService';
import { ICategory } from 'src/app/appModels/ICategory';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
 
  getAllcategoriesEvent = new Subject();
  
  api = environment.baseUrl + "category";

  constructor(private apiConfig: HttpConfigService) { }
  
  getCategoryByParentId(id: number): Observable<any> {
    return this.apiConfig.get(this.api + "?parentCategoryId=" + id);
  }


  getAllParentCategories(menuId: number): Observable<any> {
    return this.apiConfig.get(this.api + "/getAll/" + menuId);
  } 


  addCategory(category: ICategory): Observable<any> {
    return this.apiConfig.post(this.api +"/create", category, {withCredentials: true});
  }


  removeCategory(categoryId: number): Observable<any> {
    return this.apiConfig.delete(this.api + "/" + categoryId, {withCredentials: true});
  }

  deleteCategoiesByMenu(menuId: number): Observable<any> {
    return this.apiConfig.delete(this.api + "/menu/" + menuId, {withCredentials: true});
  }


  getCategories(): Observable<any> {
    return this.apiConfig.get(this.api);
  }


  getCategoryById(id):Observable<any>{
    return this.apiConfig.get(this.api + "/" + id);
  }


  getChildCategoriesById(id):Observable<any>{
    return this.apiConfig.get(this.api + "/getChilds/" + id);
  }


  updateCategory(category:ICategory):Observable<any>{
    return this.apiConfig.put(this.api + "/update/" + category.id, category, {withCredentials: true});
  }
  

  updateCategorySequence(category:ICategory):Observable<any>{
    return this.apiConfig.put(this.api + "/update/sequence/" + category.id, category, {withCredentials: true});
  }

  
  toggleCategory(category:ICategory): Observable<any> {
    return this.apiConfig.patch(`${this.api}/status/${category.id}/${category.active}`,null);
  }



}
