import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpConfigService } from '../core/httpConfig/httpConfigService';
@Injectable({
  providedIn: 'root'
})
export class SquareService {

  api =  this.apiConfig.getBaseUrl;

  constructor(private apiConfig: HttpConfigService) { }


  /**
   * Get Dashboard Url
   * @param stripeAccountId 
   */
  getDashboardUrl(stripeAccountId:string): Observable<any> {
    return this.apiConfig.get(`${this.api}/client/stripe/dashboard-url/${stripeAccountId}`);
  }


  getAccountLink(stripeAccountId:string): Observable<any>{
    return this.apiConfig.get(`${this.api}/client/stripe/account-link/${stripeAccountId}`);
  }

 

  /**
   * add square account
   * @returns 
   */
  addSquareAccount(): Observable<any>{
    return this.apiConfig.get(`${this.api}square/connection_token`);
  }

  
 
 /**
   * add square account
   * @param code 
   * @returns 
   */
  createSquareAccountOnDB(code:string): Observable<any>{
    return this.apiConfig.post(`${this.api}square/create/${code}`, null);
  }


  /**
   * get all accounts
   * @returns 
   */
  getAllAccounts(): Observable<any>{
    return this.apiConfig.get(`${this.api}square/accounts`);
  }



  /**
   * Extend expiry
   * @param refreshToken 
   * @returns 
   */
  extendToken(refreshToken: string): Observable<any> {
    return this.apiConfig.post(`${this.api}square/refresh/${refreshToken}`, null);
  }



  /**
   * Extend expiry
   * @param refreshToken 
   * @returns 
   */
  generateDeviceCode(storeId: number): Observable<any> {
    return this.apiConfig.post(`${this.api}square/create/device-code/${storeId}`, null);
  }
  
  
  
  /**
   * Sync pair status
   * @param storeId 
   * @returns 
  */
 syncPairStatus(storeId: number): Observable<any> {
   return this.apiConfig.get(`${this.api}square/terminal/webhook/${storeId}`);
  }


}
