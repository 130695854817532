import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpConfigService } from '../core/httpConfig/httpConfigService';

@Injectable({
  providedIn: 'root'
})
export class RestaurantInfoService {
  api = this.apiConfig.getBaseUrl + "account-settings";

  constructor(private apiConfig: HttpConfigService) {}

  getBasicInfo(): Observable<any> {
    return this.apiConfig.get(this.api);
  }
  
  getVerificationStatus(tenant:string): Observable<any> {
    return this.apiConfig.get(`${this.api}/approved-status/${tenant}`);
  }

  updateCardTermsAndConditions(accepted: boolean): Observable<any> {
    const url = `${this.api}/card-terms-and-conditions/${accepted}`;
    return this.apiConfig.patch(url);
  }

  enableAccount(): Observable<any> {
    const url = `${this.api}/paid/${true}`;
    return this.apiConfig.patch(url);
  }

  updateTimeZone(timezone: string): Observable<any> {
    return this.apiConfig.patch(`${this.api}/timezone?timezone=${timezone}`);
  }

}
