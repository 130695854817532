import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { IRole } from './appModels/IRole';
import { AuthGuard, LoginGuard } from './core/auth/auth.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then( m => m.AuthPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'get-started',
    loadChildren: () => import('src/app/pages/get-started/get-started.module').then( m => m.GetStartedPageModule),
    data: { roles: [IRole.Admin] },
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./layouts/layout.module').then(m => m.LayoutPageModule),
    canActivate: [AuthGuard]
  },
  
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  },





 

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
