import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CashPaymentComponent } from './cash-payment.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DesignUtilitiesModule } from 'src/app/appModules/design-utilities.module';
import { CustomDiscountComponent } from 'src/app/includes/popups/custom-discount/custom-discount.component';

@NgModule({
  declarations: [
    CashPaymentComponent,
    CustomDiscountComponent // Declare CustomDiscountComponent here
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    DesignUtilitiesModule
  ],
  exports: [
    CashPaymentComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CashPaymentModule { }
