import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ModalController, PopoverController } from '@ionic/angular';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';
import { RestaurantInfoResolver } from 'src/app/appResolvers/restaurant-info.resolver';
import { OrderService } from 'src/app/appServices/order.service';
import { ToastMessageService } from 'src/app/core/utilities/toast-message.service';
import { CustomDiscountComponent } from 'src/app/includes/popups/custom-discount/custom-discount.component';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-cash-payment',
  templateUrl: './cash-payment.component.html',
  styleUrls: ['./cash-payment.component.scss'],
})
export class CashPaymentComponent extends HandleSubscriptionsComponent implements OnInit {
  @Input() dataPayload: any;
  basicInfo: any;
  amountForm: UntypedFormGroup;
  remainingChanges = 0;
  loading: boolean = false;
  orderByLoyaltyPoints: boolean = false;


  constructor(
    private modalCtrl: ModalController,
    private popoverCtrl: PopoverController,
    private infoStorage: RestaurantInfoResolver,
    private _toastMessageService: ToastMessageService,
    private orderService: OrderService,
    private _fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    console.log(this.dataPayload);
    this.getBasicInfo();
    this.checkIfOrderByLoyaltyPoints();
    this.amountForm = this._fb.group({
      amount: ['', [
        Validators.required,
        amountVaidator(this.dataPayload.totalAmount)
      ]]
    });
  }


  /**
   * Check if order by loyalty points
   */
  checkIfOrderByLoyaltyPoints(){
    if(this.dataPayload.paymentMethod?.title==='Loyalty Points'){
      this.orderByLoyaltyPoints = true;
      this.payByCash();
    }
  }


  closeModal(response) {
    this.modalCtrl.dismiss(response);
  }



  /**
  * pay by cash
  */
  payByCash() {
    this.loading = true;
    if (this.dataPayload.id) {
      this.markOrderAsPaid();
      this.loading = true;
    } else {
      this.createGuestCheckout(this.dataPayload);
      this.loading = true;
    }
  }


  /**
   * create guest checkout
   * @param order
   */
  createGuestCheckout(order) {
    console.log("Guest Checkout", order);
    this.handleSubscription(
      this.orderService.guestCheckout(order),
      (res) => {
        order.id = res.id;
        order.change = this.remainingChanges;
        this.closeModal(order); // Close modal on success
        console.log("Payment successful:", res);
      },
      (errorResponse) => {
        let errorMessage = "An error occurred. Please contact support@tastio.com to resolve this issue."; // Default error message

        try {
          const matches = /(\[|\{).*(\]|\})/s.exec(errorResponse);
          const errorString = matches ? matches[0] : null;
          if (errorString) {
            const parsedError = JSON.parse(errorString);
            if (Array.isArray(parsedError) && parsedError.length > 0 && parsedError[0].Error) {
              // Check if the error matches the specific pattern
              if (/Barcode \d+ is not currently located in location \d+/i.test(parsedError[0].Error)) {
                // If it does, use the custom message instead of the default API error
                errorMessage = "The item's barcode is not registered in the expected location. " +
                               "Please click on 'Menu' on the Side Navigation, and select 'Receive/View Biotrack Manifest' " +
                               "to receive the manifest again. If you need further assistance, contact admin@tastio.com.";
                this._toastMessageService.errorToastWith15Seconds(errorMessage); // Use the 15-second toast
                this.closeModal(null); // Optionally close modal on error
                console.error("Error:", errorMessage);
                this.loading = false;
              } else {
                // If it doesn't match the pattern, use the API error
                errorMessage = parsedError[0].Error;
                this._toastMessageService.errorToastWithClose(errorMessage); // Use the regular toast
              }
            }
          }
        } catch (parseError) {
          console.error("Failed to parse error response:", parseError);
          // Use the regular toast for any unexpected errors that couldn't be parsed
          this._toastMessageService.errorToastWithClose(errorMessage);
        }

        // Remove the last toast call from the global error handling scope to avoid duplicate toasts
        console.error("Payment failed:", errorMessage);
        this.closeModal(null); // Optionally close modal on error
        this.loading = false;
      }
    );
  }

  /**
   * mark order as paid
   */
   markOrderAsPaid() {
     // Initialize the temporary payload with basic data
     let temp: any = {
       emailId: this.dataPayload.emailId,
       mobileNumber: this.dataPayload.mobileNumber,
       processingFee: this.dataPayload.processingFee
     };

     // If the payment method is Loyalty Points, modify the payload
     if (this.dataPayload.paymentMethod.title === 'Loyalty Points') {
       temp = {
         amountSpend: 0.1,
         loyaltyPointsSpent: this.dataPayload.loyaltyPointsSpent
       };
     }

     // Check if customer information exists and add it to the payload
     if (this.dataPayload.customer) {
       temp.customer = this.dataPayload.customer;
       console.log('Customer information added to payload:', temp.customer);
     }

     console.log("Mark Order as Paid", this.dataPayload);

     // Call the service to mark the order as paid
     this.handleSubscription(this.orderService.markCashOrderAsPaid(this.dataPayload.id, temp),
       (res) => {
         this.dataPayload.paid = true;
         this.dataPayload.paymentType = 'cash';
         this.dataPayload.change = this.remainingChanges;
         this.closeModal(this.dataPayload);
         console.log("Order marked as paid:", res);
       },
       (error) => {
         console.error("Error marking order as paid:", error);
       }
     );
   }



  async openCustomDiscountModal() {
     try {
       this.amountForm.controls['amount'].reset();
       this.calculateChanges();

       const modal = await this.modalCtrl.create({
         component: CustomDiscountComponent,
         componentProps: {
           currentTotal: this.dataPayload.totalAmount
         }
       });

       await modal.present();

       const { data } = await modal.onDidDismiss();
       if (data) {
         console.log(data);
         const discount = data;
         this.applyDiscountToTotal(discount);
       }
     } catch (error) {
       console.error('Error opening custom discount modal:', error);
     }
   }

   applyDiscountToTotal(discount) {
     let discountAmount = 0;

     // Calculate the discount amount based on the type of discount
     if (discount.discountType === 'percentage') {
       discountAmount = this.dataPayload.totalAmount * (discount.value / 100);
       this.dataPayload.totalAmount -= discountAmount;
     } else if (discount.discountType === 'custom') {
       discountAmount = discount.value;
       this.dataPayload.totalAmount = this.dataPayload.totalAmount - discountAmount;
     }

     // Log the payload and orderDetailsModelList before applying the discount
     console.log("Payload: ", this.dataPayload);
     console.log('Before discount:', this.dataPayload.orderDetailsModelList);

     // Calculate the original total and apply discount proportionally
     const originalTotal = this.dataPayload.orderDetailsModelList.reduce((acc, item) => acc + (item.basePrice * item.quantity), 0);

     let totalCalculated = 0;

     // Apply the discount proportionally to each item, rounding to 2 decimal places
     this.dataPayload.orderDetailsModelList.forEach((item, index, arr) => {
       const itemTotalPrice = item.basePrice * item.quantity;
       const itemProportion = itemTotalPrice / originalTotal;
       let itemDiscount = discountAmount * itemProportion;

       // Use discountedPrice and ensure it's a number with 2 decimal places
       item.discountedPrice = parseFloat(((item.basePrice * item.quantity - itemDiscount) / item.quantity).toFixed(2));
       totalCalculated += item.discountedPrice * item.quantity;

       // Adjust the last item if necessary to match the total amount exactly
       if (index === arr.length - 1 && totalCalculated !== this.dataPayload.totalAmount) {
         const difference = this.dataPayload.totalAmount - totalCalculated;
         item.discountedPrice = parseFloat(((item.discountedPrice * item.quantity + difference) / item.quantity).toFixed(2));
       }
     });

     // Log the data after applying the discount and rounding
     console.log('After discount:', this.dataPayload.orderDetailsModelList);

     // Store the discount amount in dataPayload
     this.dataPayload.discount = discountAmount;

     // Reset the amount form control after updating the total amount
     this.amountForm.controls['amount'].reset();

     // Update the validator with the updated totalAmount
     this.amountForm.controls['amount'].setValidators([
       Validators.required,
       amountVaidator(this.dataPayload.totalAmount)  // Use the modified totalAmount
     ]);

     // Re-evaluate the form's validity and trigger change detection
     this.amountForm.controls['amount'].updateValueAndValidity();
     this.cdr.detectChanges();  // Ensure Angular detects the change and updates the UI
   }



   removeCents() {
     this.dataPayload.totalAmount = Math.floor(this.dataPayload.totalAmount);
     this.amountForm.controls['amount'].reset();
     this.calculateChanges();
     this.cdr.detectChanges();  // Ensure Angular detects the change and updates the UI
   }





  /**
   * calculate changes
   */
  calculateChanges() {
    const amountGivenIsLess = this.dataPayload.totalAmount > this.amountForm.value.amount;
    if (this.amountForm.value.amount != null && !amountGivenIsLess) {
      this.remainingChanges = this.amountForm.value.amount - this.dataPayload.totalAmount;
    } else {
      this.remainingChanges = 0;
    }
  }



  /**
   * get basic information
   */
  getBasicInfo() {
    this.handleSubscription(this.infoStorage.restaurantBasicInfo,
      (res) => {
        if (res) {
          this.basicInfo = res;
        }
      }
    );
  }


}



export function amountVaidator(currentTotalAmount): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const forbidden = control.value < currentTotalAmount;
    return forbidden ? { forbiddenName: { value: control.value } } : null;
  };
}
