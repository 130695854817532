import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StopPropagationDirective } from '../appDirectives/stop-propagation.directive';
import { SafePipe } from '../appPipes/safe.pipe';
import { NumOnlyDirective } from '../appDirectives/num-only.directive';

@NgModule({
  declarations: [
    StopPropagationDirective,
    NumOnlyDirective,
    SafePipe
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    StopPropagationDirective,
    NumOnlyDirective,
    SafePipe
    
  ],
  providers: [
  ]
})
export class DesignUtilitiesModule {
}
