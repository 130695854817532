import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';
import { SmsService } from 'src/app/appServices/sms.service';
import { ToastMessageService } from 'src/app/core/utilities/toast-message.service';

@Component({
  selector: 'app-send-notification',
  templateUrl: './send-notification.component.html',
  styleUrls: ['./send-notification.component.scss']
})
export class SendNotificationComponent extends HandleSubscriptionsComponent implements OnInit {
  @Input() data: any;
  message: string;
  messages = [];
  disabledSendButton: boolean = false;
  constructor(
    private smsService: SmsService,
    private _toastMessage: ToastMessageService,
    private modalCtrl: ModalController
    ) {
      super();
    this.messages = JSON.parse(localStorage.getItem("suggestMessages")) ? JSON.parse(localStorage.getItem("suggestMessages")) : [];
  }

  ngOnInit(): void {
  }

  selectSuggestedMessage(item) {
    this.message = item;
  }

  cancel() {
    this.modalCtrl.dismiss('close');
  }

  /**
   * send Notification
   */
  sendNotification() {
    if (this.message) {
      this.disabledSendButton = true;
      const temp = {
        mobileNumber: this.data.mobileNumber,
        message: this.message
      }
      this.handleSubscription(this.smsService.sendNotification(temp),
        (res) => {
          this.disabledSendButton = false;
          this._toastMessage.successToast("Message is successfully sent");
          this.cancel();
        }, err => {
          this.disabledSendButton = false;
          this._toastMessage.successToast("Error in sending notification.");
        }
      );
    } else {
      this._toastMessage.successToast("Please fill required fields");
    }
  }
}
