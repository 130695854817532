import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {
  DiscoveryMethod, StripeTerminalPlugin
} from 'capacitor-stripe-terminal';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';
import { IStore } from 'src/app/appModels/IStore';
import { RestaurantInfoResolver } from 'src/app/appResolvers/restaurant-info.resolver';
import { StoreResolver } from 'src/app/appResolvers/store.resolver';
import { OrderService } from 'src/app/appServices/order.service';
import { StripePaymentService } from 'src/app/appServices/stripe-payment.service';
import { ToastMessageService } from 'src/app/core/utilities/toast-message.service';
import { environment } from 'src/environments/environment';

// Documentation: https://oss.eventone.page/capacitor-stripe-terminal/

@Component({
  selector: 'app-stripe-bluetooth-card-reader',
  templateUrl: './stripe-bluetooth-card-reader.component.html',
  styleUrls: ['./stripe-bluetooth-card-reader.component.scss'],
})
export class StripeBluetoothCardReaderComponent extends HandleSubscriptionsComponent implements OnInit, OnDestroy {
  terminal: any;
  readerStatus = 'Not connected';
  paymentStatus = 'Pending';
  paymentDone = false;
  change = 0;
  connectionToken: string;
  @Input() dataPayload: any;
  basicInfo: any;
  locationId: string;

  upgrading = {
    status : false,
    progress: 0
  };


  // Refactoring
  logs: string;
  events = {
    "getLocationId": {
      event: this.getLocationIdFromCookie
    },
    "askPermission": {
      event: this.askForPermission
    },
    "getTerminalInstance": {
      event: this.getTerminalInstance
    },
    "discoverReaders": {
      event: this.discoverReaders
    },
    "connectReader": {
      event: this.connectReader
    },
    "payment": {
      event: this.pay
    },
    "retrivePaymentIntent": {
      event: this.retrievePaymentIntent
    },
    "collectPayment": {
      event: this.collectPayment
    },
    "processPayment": {
      event: this.paymentProcess
    },
    "sendIntentToServer": {
      event: this.sendIntentToServer
    }
  };

  eventList: any[];
  currentEventIndex = -1;


  selectedStore: IStore;

  constructor(
    private toastMessage: ToastMessageService,
    private stripePaymentService: StripePaymentService,
    private orderService: OrderService,
    private modalCtrl: ModalController,
    private infoStorage: RestaurantInfoResolver,
    private changeRef: ChangeDetectorRef,
    private storeResolver: StoreResolver,
  ) {
    super();
  }

  ngOnInit() {
    this.subscribeStore();
    this.getBasicInfo();

    this.eventList = Object.keys(this.events);
    this.goToNextEvent();
  }


  ngOnDestroy(): void {
    this.disconnectReader();
  }

  subscribeStore() {
    this.handleSubscription(this.storeResolver.selectedStore,
      (res) => {
        this.selectedStore = res;
      }
    );
  }

  installAvailableUpdate() {
    if (this.terminal) {
      this.terminal.installAvailableUpdate().then(res => {
        this.updateUpgrading(true);
        this.toastMessage.toast('Upgradating Reader, Installing latest version', 'Closes');
      });
    }
  }

  /**
   * Cancel Installation
   */
  cancelInstallUpdate() {
    if (this.terminal) {
      this.terminal.cancelInstallUpdate().then(res => {
        this.updateUpgrading(false);
      });
    }
  }

  updateReaderStatus(status: string) {
    this.readerStatus = status;
    this.changeRef.detectChanges();
  }

  updateUpgrading(status: boolean, progress?: number | 0) {
    this.upgrading.status = status;
    this.upgrading.progress = progress;
    this.changeRef.detectChanges();
  }

  updatePaymentStatus(status: string) {
    this.paymentStatus = status;
    this.changeRef.detectChanges();
  }
  /**
   * initialize stripe
   */
  async getTerminalInstance() {
    this.terminal = await StripeTerminalPlugin.create({
      fetchConnectionToken: () => {
        const api = environment.baseUrl + 'payment-gateway/client/stripe/connection_token/' + this.selectedStore.id;
        const options = {
          method: 'GET',
          headers: { 'content-type': 'application/json', 'tenant-name': localStorage.getItem('tenant') },
        };
        return fetch(api, options)
          .then(response => response.json())
          .then(data => data.secret);
      },
      onUnexpectedReaderDisconnect: this.unexpectedDisconnect
    });
    if (this.terminal) {
      this.goToNextEvent();
      this.onReportReaderSoftwareUpdateProgress();

    } else {
      this.logs = "Unable to create Teminal Instance";
      this.toastMessage.errorToastWithClose('Unable to create Stripe Teminal Instance');
    }
  }

  // An event handler called when a reader disconnects from your app.
  unexpectedDisconnect() {
    this.toastMessage.toast('Unexpected Disconnect, please restart app', 'close');
  }


  /**
   * Discover Readers
   * @returns 
   */
  async discoverReaders() {
    this.updateReaderStatus('Finding card reader');
    const config = {
      simulated: environment.cardReader.simulated,
      discoveryMethod: DiscoveryMethod.BluetoothProximity
    };
    await this.handleSubscription(this.terminal.discoverReaders(config),
      (readers) => {
        if (readers.length) {
          this.readerStatus = 'Reader founded.';
          this.goToNextEvent(readers[0]);
        } else {
          this.logs = "No Reader Found";
          this.toastMessage.errorToastWithClose('No Reader found');

        }
      },
      (err) => {
        this.logs = "Failded to discover card reader: " + err;
        this.toastMessage.errorToastWithClose('Failded to discover card reader: ' + err);
      }
    );
  }

  /**
   * On Report Reader Software update progress
   */
  onReportReaderSoftwareUpdateProgress() {
    this.handleSubscription(this.terminal.didReportReaderSoftwareUpdateProgress(),
      (res) => {
        this.updateUpgrading(true, (Number.parseFloat(res) * 100));
      }, err => {
        this.updateUpgrading(false);
      }
    );
  }
  /**
 * connect with given card Reader
 * @param cardReader 
 */
  async connectReader(cardReader) {
    // Just select the first reader here.
    const connectionConfig = {
      locationId: this.locationId
    }
    this.updateReaderStatus('Connecting with card reader...');
    this.terminal.connectBluetoothReader(cardReader, connectionConfig).then(res => {
      this.updateReaderStatus('Successfully connected');
      this.updatePaymentStatus('Payment Initiated..');
      this.goToNextEvent();
    }, err => {
      this.logs = "Failed to connect with card reader, err: " + err;
      this.toastMessage.errorToastWithClose('Failed to connect: ' + err);
    });
  }

  pay() {
    let stripeData = {
      amount: Number.parseFloat(this.dataPayload.totalAmount.toFixed(2)) * 100,
      email: this.dataPayload.emailId,
      platformFee: this.dataPayload.platformFee
    };
    this.handleSubscription(this.stripePaymentService.getClientSecret(stripeData, this.selectedStore.id),
      (res) => {
        this.goToNextEvent(res.clientSecret);
      } , (err) => {
        this.logs = "Failed to get client secert, err: " + err;
      }
    );
  }


  retrievePaymentIntent(clientSecret) {
    this.paymentStatus = 'Please swipe card..';
    this.terminal.retrievePaymentIntent(clientSecret).then(res => {
      this.goToNextEvent(res.stripeId);
    }, err => {
      this.toastMessage.errorToastWithClose('Error to retrieve payment intent');
    });
  }
  /**
   * collecting a payment method for a PaymentIntent.
   * @param paymentIntent 
   */
  collectPayment(paymentIntent) {
    this.updatePaymentStatus('Payment Proccessing...');
    this.terminal.collectPaymentMethod(paymentIntent).then(res => {
      this.goToNextEvent(res.stripeId);
    }, err => {
      this.toastMessage.errorToastWithClose('Error: ' + err);
    });
  }

  /**
  * Processes a payment after a payment method has been collected.
  * @param paymentIntent 
  */
  paymentProcess(paymentIntent) {
    this.updatePaymentStatus('Payment processing almost finished..');
    this.terminal.processPayment(paymentIntent).then(result => {
      this.goToNextEvent(result.stripeId);
    }, err => {
      // this.logs = "Error in processing payment, err: " + err;
      this.toastMessage.errorToastWithClose('Error: ' + err);
      this.closeCardReaderModel(null);
    });
  }

  /**
 * Send Intent Object to server
 * @param paymentIntent 
 */
  sendIntentToServer(paymentIntent) {

    if (this.dataPayload.type === "pay by orderId") {
      this.checkoutByOrderId(paymentIntent);
    } else if (this.dataPayload.type === "pay by order") {
      this.checkout(paymentIntent);
    }
    else if (this.dataPayload.type === "pay by paymentIntent") {
      this.createCardPaymentForOrderByPaymentIntent(paymentIntent, this.dataPayload);
    }
  }

  /**
   * Checkout by Order Id
   * @param paymentIntentId 
   */
  checkoutByOrderId(paymentIntentId) {
    this.dataPayload.paymentIntentId = paymentIntentId;
    this.dataPayload.paid = true;
    this.dataPayload.paymentType = 'card';
    const temp = {
      paymentIntentId: this.dataPayload.paymentIntentId,
      totalAmount: this.dataPayload.totalAmount,
      processingFee: this.dataPayload.processingFee
    }
    this.handleSubscription(this.orderService.markCardOrderAsPaid(this.dataPayload.id, temp),
      (res) => {
        this.updatePaymentStatus('Payment is done successfully.');
        this.dataPayload.paid = true;
        this.dataPayload.paymentType = 'card';
        this.closeCardReaderModel(this.dataPayload);
      }
    );
  }

  /**
 * Checkout with payment intent
 * @param paymentIntentId 
 */
  checkout(paymentIntentId) {
    const temp = this.dataPayload;
    temp.paymentIntentId = paymentIntentId;
    this.handleSubscription(this.orderService.createOrderByCardReader(paymentIntentId, 'Stripe Card Reader', temp),
      (res) => {
        this.afterPayment(res);
      },
      (err) => {
        this.logs = "Error in creating order. Internal server issue.";
        this.toastMessage.errorToastWithClose('Error in creating order.');
      }
    );
  }

  /**
   * after payment
   * @param id 
   */
  afterPayment(id: number) {
    this.paymentStatus = 'Payment is done successfully.';
    this.paymentDone = true;
    const temp = {
      id,
      paymentType: 'Card Reader'
    }
    this.closeCardReaderModel(temp);
  }

  /**
   * get basic information
   */
  getBasicInfo() {
    this.handleSubscription(this.infoStorage.restaurantBasicInfo,
      (res) => {
        if (res) {
          this.basicInfo = res;
        }
      }
    );
  }

  /**
* create payment for order
* @param paymentIntentId
* @param paymentModel
*/
  createCardPaymentForOrderByPaymentIntent(paymentIntentId, paymentModel) {
    const temp = {
      amount: paymentModel.totalAmount,
      paymentType: "card",
      processingFee: paymentModel.processingFee
    }
    this.handleSubscription(this.orderService.createCardPaymentForOrderByPaymentIntent(paymentModel.orderId, paymentIntentId, temp),
      (res) => {
        this.updatePaymentStatus('Payment is done successfully.');
        this.paymentDone = true;
        if (res) {
          paymentModel.id = res;
        }
        this.closeCardReaderModel(this.dataPayload);
        // this.goToNextEvent();
      },
      (err) => {
        // this.logs = "Internal Server error in creating order";
        this.toastMessage.errorToastWithClose('Error in creating order.');
      }
    );
  }

  disconnectReader() {
    this.terminal.cancelCollectPaymentMethod().then(res => { });
    this.terminal.clearCachedCredentials().then(res => { });
    this.terminal.cancelDiscoverReaders().then(res => { });
    this.terminal.disconnectReader().then(res => this.toastMessage.toast('Disconnected..', 'Close'));
  }

  /**
   * Close Card Reader
   * @param response 
   */
  closeCardReaderModel(response): void {
    this.disconnectReader();
    this.modalCtrl.dismiss(response);
  }

  /**
   * ask for permission
   */
  async askForPermission() {
    //  if (Capacitor.getPlatform() === 'android') {
    // check if permission is required
    let response = await StripeTerminalPlugin.checkPermissions();
    if (response.location === 'prompt') {
      // if it is required, request it
      response = await StripeTerminalPlugin.requestPermissions();
      if (response.location !== 'granted') {
        // this.logs = "Please provide location permission";
        this.toastMessage.errorToastWithClose("Location Permission is Required");
        this.closeCardReaderModel(null);
        return;
      }
    }
    this.goToNextEvent();
    // }
  }

  cancelPaymentMethod() {
    this.terminal.cancelPaymentMethod().then(res => {
      this.toastMessage.toast("Previous Payment is canceled: " + res, 'close');
    });
  }

  /**
   * get location id
   */
  getLocationIdFromCookie() {
    this.locationId = localStorage.getItem('location-id');
    if (!this.locationId) {
      this.toastMessage.errorToastWithClose('Please add location id from setting page');
      this.logs = "Please Select location from Setting.";
    } else {
      this.goToNextEvent();
    }
  }


  /**
 * go to next event
 */
  goToNextEvent(...arg: any | []) {
    if (this.currentEventIndex < this.eventList.length - 1) {
      this.currentEventIndex = this.currentEventIndex + 1;
      this.events[this.eventList[this.currentEventIndex]].event.apply(this, arg);
    }
  }
}
