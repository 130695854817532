import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpConfigService } from '../core/httpConfig/httpConfigService';

@Injectable({
  providedIn: 'root',
})
export class SmsService {
  api = this.apiConfig.getBaseUrl + '/sms';

  constructor(private apiConfig: HttpConfigService) {}

  sendNotificationFoodIsReady(order): Observable<any> {
    return this.apiConfig.post(this.api + '/notificationFoodIsReady/', order, {
      withCredentials: true,
    });
  }

  sendNotification(data): Observable<any> {
    return this.apiConfig.post(this.api + '/send-message', data, {
      withCredentials: true,
    });
  }
}
