// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseUrl: 'http://localhost:8080/app/v1/',
  baseUrl: 'https://main-api.preprod-tastio.com/app/v1/',
  // backupBaseUrl: 'http://localhost:8080/app/v1/',
  backupBaseUrl: 'https://main-api.preprod-tastio.com/app/v1/',
  // tenantApi: 'http://localhost:8080/app/v1/',
  tenantApi: 'https://tenant-api.preprod-tastio.com/app/v1/',
  backupTenantApi: 'https://tenant-api.preprod-tastio.com/app/v1/',
  baseUri: 'https://preprod-tastio.com',
  adminPanelUrl: 'https://portal.preprod-tastio.com',
  kdsUrl: 'https://kds.preprod-tastio.com',
  S3_URL: 'https://tastio-testing.ams3.digitaloceanspaces.com/',
  END_POINTS: 'ams3.digitaloceanspaces.com/',
  SPACE_NAME: 'tastio-testing',
  cardReader: {
    simulated: true
    // simulated: false
  },
  squareCredentials:{
    appId: 'sandbox-sq0idb-ifUUbWpWsrJ8JXxU4UiLIA',
    locationId: 'LMKAY6Q4ZRETG',
    callbackUrl: 'https://tastioapi.com/app/v1/square/terminal/status'
  },
  appVersion: '1.0.1',
  loginUrl: 'https://portal.preprod-tastio.com/login',
  STRIPE_PUBLIC_KEY: 'pk_test_51IfgJAKBSFkAswCEyeeJrPs3IrVUXwtI5JEIooa46y2egewCc7rZAjaz6ogCZQJPcbujGax3UNmtWWKh3yaU8k6D00rUBLne4r',
  STRIPE_TEST_KEY: 'pk_test_51KyAK6Gf6J4ckXYeVNCr8lcFrTUEWKTTPbfBTjpdfm59TykmalcuWIhmoBdzxx4a2wDkBWczrM7cXdiUcHHCTwJN00G3yY0jkh',
  //STRIPE_PUBLIC_KEY: 'pk_live_51IfgJAKBSFkAswCEmaw2GIaaMCKA6CBYDYDfoVJsyOG5PauH0Lq91HZSfqfquAmS5ynh3pMvEBaNQ9wABSGr23n100M1IhzB9T'
  STRIPE_SECRET_KEY : 'sk_test_51IfgJAKBSFkAswCEOdTYteu9h4SWU5Tha24zC3ydRlDMziBofHoqPimsO0hoEQ7HGiRks0Mvrpn2C0Z6eBwGgVnp00QNWrTSEB'
};

/*
 * For easier debugging in development mode, you can i
mport the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
