import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ICartDetails } from '../appModels/ICartDetails';
import { IOrderDetailsAddOnModel, IOrderDetailsModel, IOrderDetailsVariantModel } from '../appModels/IOrderModel';


@Injectable({
  providedIn: 'root'
})
export class OrderUtility {

  /**
  * convert cart details to order details list
  * @param cartDetails
  */
  public convertCartDetailsToOrderDetails(cartDetails: ICartDetails[]): IOrderDetailsModel[] {
    let orderDetails: IOrderDetailsModel[] = [];

    cartDetails.forEach((element: ICartDetails) => {
      orderDetails.push(this.convertCartItemToOrderItem(element));
    });

    return orderDetails;
  }

  /**
   *
   * @param cartDetails
   */
  public convertCartItemToOrderItem(cartDetails: ICartDetails): IOrderDetailsModel {
    let product = cartDetails.productModel;
    return {
      productName: product.name,
      productId: product.id,
      basePrice: product.basePrice,
      quantity: cartDetails.quantity,
      productCannabisModel: product.productCannabisModel,
      productMessage: cartDetails.message,
      tierPrice: cartDetails.tierPrice,
      orderDetailsAddOnModelList: this.convertCartAddOnsToOrderDetailsAddOn(cartDetails.cartDetailsAddOnModelList),
      orderDetailsVariantModels: this.convertCartVariantsToOrderDetailsVariants(cartDetails.cartDetailsVariantModelList)
    }
  }



  /**
   * convert cart add on to order details add on list
   * @param cartDetailsAddOnModelList
   */
  private convertCartAddOnsToOrderDetailsAddOn(cartDetailsAddOnModelList: any[]): IOrderDetailsAddOnModel[] {
    let addOnList: IOrderDetailsAddOnModel[] = [];
    cartDetailsAddOnModelList.forEach(element => {
      const temp: IOrderDetailsAddOnModel = {
        addonName: element.addOnModel.addOnName,
        addonId: element.addOnModel.id,
        addonPrice: element.addOnModel.addOnPrice
      }
      addOnList.push(temp);
    });
    return addOnList;
  }

  /**
   * Convert cart varinat to order details variant list
   * @param cartDetailsVariantList
   */
  private convertCartVariantsToOrderDetailsVariants(cartDetailsVariantList: any[]): IOrderDetailsVariantModel[] {
    let variantModelList: IOrderDetailsVariantModel[] = [];

    cartDetailsVariantList.forEach(element => {
      const temp: IOrderDetailsVariantModel = {
        childVariantName: element.childVariantModel.variantName,
        childVariantId: element.childVariantModel.id,
        variantPrice: element.price,
        defaultSelected: element.defaultSelected,
        groupId: element.variantGroupModel.id
      }
      variantModelList.push(temp);
    });

    return variantModelList;
  }




  /**
   * convert order details list to cart list
   * @param orderDetailList
   */
   public convertOrderDetailToCartList(orderDetailList: any[]): any[] {
       const cartList = [];
       orderDetailList.forEach(element => {
           const temp = {
               productModel: {
                  id: element.productId,
                  name: element.productName,
                  basePrice: element.basePrice, // Use unit price
                  productMessage: element.productMessage
               },
               productName: element.productName,
               basePrice: element.basePrice, // Use unit price
               quantity: element.quantity,
               tierPrice: element.tierPrice,
               cartDetailsAddOnModelList: this.convertOrderAddOnToCartAddOn(element.orderDetailsAddOnModelList),
               cartDetailsVariantModelList: this.convertOrderVariantToCartVariant(element.orderDetailsVariantModels),
           };
           cartList.push(temp);
       });
       return cartList;
   }



  private convertOrderAddOnToCartAddOn(orderDetailsAddonModelList: any[]): any[] {
      // Add null-safe check for orderDetailsAddonModelList
      if (!orderDetailsAddonModelList) {
          return []; // Return an empty array if undefined
      }

      return orderDetailsAddonModelList.map(e => {
          return {
              addOnModel: {
                  id: e.addonId,
                  addOnName: e.addonName,
                  addOnPrice: e.addonPrice
              }
          };
      });
  }


  private convertOrderVariantToCartVariant(orderDetailsVariantModels: any[]): any[] {
      // Add null-safe check for orderDetailsVariantModels
      if (!orderDetailsVariantModels) {
          return []; // Return an empty array if undefined
      }

      return orderDetailsVariantModels.map(e => {
          return {
              parentVariantModel: {
                  variantName: e.parentVariantName,
                  id: e.parentVariantId
              },
              childVariantModel: {
                  variantName: e.childVariantName,
                  id: e.childVariantId
              },
              price: e.variantPrice,
              defaultSelected: e.defaultSelected,
              variantGroupModel: {
                  id: e.groupId
              }
          };
      });
  }



  /**
 * calculate cart total price
 * @param cartList
 */
 calculateCartTotalPrice(cartList: any[]): number {
     let total = 0;
     cartList.forEach(element => {
         // Get the final price of each product, including variants and addons, multiplied by quantity
         let productPrice = this.calculateProductPrice(element);

         // Debugging
        //  console.log('--- calculateCartTotalPrice ---');
        //  console.log('Product Name:', element.productName);
        //  console.log('Product Price:', productPrice);
        //  console.log('Total before adding product:', total);

         total += productPrice;

        //  console.log('Total after adding product:', total);
        //  console.log('-------------------------------');
     });
     return total;
 }







 /**
  * Calculate the cart total price specifically for cannabis business type.
  * @param cartList
  */
 calculateCannabisCartTotalPrice(cartList: any[]): number {
   let total = 0;
   cartList.forEach(element => {
     const itemTotal = element.quantity * element.basePrice; // Multiply quantity with base price for each item
     total += itemTotal;
   });
   return total;
 }


  /**
   * get sales tax price
   * @param cartList
   * @param tax
   */
   calculateTax(subtotal, tax, tipAmount): number {
       // Debugging statements
      //  console.log('--- calculateTax Debugging ---');
      //  console.log('Subtotal:', subtotal);
      //  console.log('Tip Amount:', tipAmount);
      //  console.log('Tax Rate:', tax);

       const taxAmount = (subtotal + tipAmount) * 0.01 * tax;
      //  console.log('Calculated Tax Amount:', taxAmount);
      //  console.log('-------------------------------');

       return taxAmount;
   }



  /**
   * get excise tax price
   * @param cartList
   * @param exciseTax
   */
   calculateExciseTax(subtotal, exciseTax, tipAmount): number {
       const exciseTaxAmount = (subtotal + tipAmount) * 0.01 * exciseTax;
       return exciseTaxAmount;
   }


  /**
   * get single tax
   * @param amount
   * @param tax
   */
  calculateSingleTax(amount, tax): number {
    return Number.parseFloat((amount * Number.parseFloat(tax) / 100).toFixed(2));
  }

  /**
   * This will be the calculation of total amount with tax and service feed
   * @param cartList
   * @param tax
   */
   calculateTotalAmount(cartList, tax, tipAmount): number {
       const subtotal = this.calculateCartTotalPrice(cartList);
       return subtotal + this.calculateTax(subtotal, tax, tipAmount);
   }


  /**
* calculate product final price
* @param product
*/
calculateProductPrice(product): number {
    let productPrice = product.basePrice;

    // Add variant prices
    if (product.orderDetailsVariantModels) {
      product.orderDetailsVariantModels.forEach(element => {
        productPrice += element.variantPrice;
      });
    }

    // Add variant prices (if converted item list)
    if (product.cartDetailsVariantModelList) {
      product.cartDetailsVariantModelList.forEach(element => {
        productPrice += element.price;
      });
    }

    // Add addon prices
    if (product.orderDetailsAddOnModelList) {
      product.orderDetailsAddOnModelList.forEach(element => {
        productPrice += element.addonPrice;
      });
    }

    // Add addon prices (if converted item list)
    if (product.cartDetailsAddOnModelList) {
      product.cartDetailsAddOnModelList.forEach(element => {
        productPrice += element.addOnModel.addOnPrice;
      });
    }

    // Multiply the final product price by the quantity
    productPrice *= product.quantity;

    // Debugging
    // console.log('--- calculateProductPrice ---');
    // console.log('Product Name:', product.productName || product.productModel.name);
    // console.log('Base Price:', product.basePrice);
    // console.log('Total Addon Price:', productPrice - product.basePrice * product.quantity);
    // console.log('Quantity:', product.quantity);
    console.log('Product Price (after quantity):', productPrice);
    // console.log('-----------------------------');

    return productPrice;
}





}
