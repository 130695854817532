import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { RestaurantInfoService } from 'src/app/appServices/restaurant-info.service';
import { ToastMessageService } from 'src/app/core/utilities/toast-message.service';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-terms-stripe-card-reader',
  templateUrl: './terms-stripe-card-reader.component.html',
  styleUrls: ['./terms-stripe-card-reader.component.scss']
})
export class TermsStripeCardReaderComponent {

  constructor(
    private modalCtrl: ModalController,
    private restaurantInfoService: RestaurantInfoService,
    private toasterService: ToastMessageService
  ) {}

  private checkAndDeleteLocalStorage(): void {
    const basicInfo = localStorage.getItem('basic-info');
    if (basicInfo) {
      localStorage.removeItem('basic-info');
    }
  }

  private setNewBasicInfo(): void {
    this.restaurantInfoService.getBasicInfo().subscribe(
      (info) => {
        if (info) {
          localStorage.setItem('basic-info', JSON.stringify(info));
          console.log('Updated basic-info set in local storage:', info);
          //this.reloadPage();  // Reload the page
        } else {
          console.warn('basic-info key does not exist in the response.');
        }
      },
      (error) => {
        console.error('Failed to fetch and update basic-info:', error);
      }
    );
  }

  acceptTerms(): void {
    this.restaurantInfoService.updateCardTermsAndConditions(true).subscribe(
      () => {
        this.toasterService.successToast('Card terms and conditions accepted and updated successfully.');
        this.checkAndDeleteLocalStorage();
        this.setNewBasicInfo();  // Fetch and set the updated basic-info
        this.modalCtrl.dismiss({role: 'accept'});
      },
      (error) => {
        this.toasterService.errorToastWithClose('Failed to update card terms and conditions');
        console.error('Failed to update card terms and conditions', error);
      }
    );
  }

  declineTerms(): void {
    this.restaurantInfoService.updateCardTermsAndConditions(false).subscribe(
      () => {
        this.toasterService.errorToastWithClose('You need to accept the terms to enable card payments.');
        this.modalCtrl.dismiss({role: 'cancel'});
      },
      (error) => {
        this.toasterService.errorToastWithClose('Failed to update card terms and conditions');
        console.error('Failed to update card terms and conditions', error);
      }
    );
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  private reloadPage(): void {
    if ((window as any).electronAPI) {
      // If running in Electron, use the Electron reload method
      (window as any).electronAPI.send('reload-window');
    } else {
      // If running in a regular web environment, use the standard reload
      window.location.reload();
    }
  }
}
