import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { HttpConfigService } from '../core/httpConfig/httpConfigService';
import { environment } from 'src/environments/environment';
import { StoreResolver } from '../appResolvers/store.resolver';
import { IStore } from '../appModels/IStore';
import { tap, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  store: IStore;
  api = this.apiConfig.getBaseUrl;

  activeOrder = new BehaviorSubject(null);

  constructor(
    private apiConfig: HttpConfigService,
    private _storeResolver: StoreResolver
    ) {
      this.subscribeSelectedStore();
    }


  /**
   * Subscribe Selected Store
   */
   subscribeSelectedStore() {
    this._storeResolver.selectedStore.subscribe(res =>{
      this.store = res;
    });
  }


  saveOrder(order): Observable<any> {
    return this.apiConfig.post(this.api + 'order/kiosk/save', order, {
      withCredentials: true,
    });
  }

  createOrder(order): Observable<any> {
    return this.apiConfig.post(this.api + 'order/cashier/checkout', order, {
      withCredentials: true,
    });
  }

  getOrdersByMobileNumber(mobileNumber: string):Observable<any>{
    return this.apiConfig.get(`${this.api}order/mobileNumber/${mobileNumber}`);
  }

  /**
   * get store Id
   */
  getStoreId() {
    return this.store?.id || null;
  }

  createOrderForGuest(order): Observable<any> {
    return this.apiConfig.post(this.api + 'order/guest/checkout', order, {
      withCredentials: true,
    });
  }

  payByCash(checkoutOrderPayload): Observable<any> {
    return this.apiConfig.post(
      this.api + 'order/kiosk/checkoutwithcash',
      checkoutOrderPayload,
      { withCredentials: true }
    );
  }

  /**
   * create order for cashier by order and payment intent
   * @param order
   * @param paymetIntent
   */
   createOrderByCardReader(paymentIntent, gateway, order): Observable<any> {
     console.log('createOrderByCardReader called with:', paymentIntent, gateway, order);
     return this.apiConfig.post(
       this.api + 'order/kiosk/card-reader/' + gateway + '/' + paymentIntent,
       order
     ).pipe(
       tap(response => console.log('createOrderByCardReader response:', response)),
       catchError(error => {
         console.error('createOrderByCardReader error:', error);
         return throwError(error);
       })
     );
   }



  /**
   * create order for cashier by order and payment intent for platform
   * @param order
   * @param paymetIntent
   */
   createOrderByCardReaderForPlatform(paymentIntent, gateway, order): Observable<any> {
     console.log('createOrderByCardReaderForPlatform called with:', paymentIntent, gateway, order);
     return this.apiConfig.post(
       this.api + 'order/kiosk/card-reader/' + gateway + '/' + paymentIntent + '/platform',
       order
     ).pipe(
       tap(response => console.log('createOrderByCardReaderForPlatform response:', response)),
       catchError(error => {
         console.error('createOrderByCardReaderForPlatform error:', error);
         return throwError(error);
       })
     );
   }



  /**
   * Create order by terminal
   * @param order
   * @returns
   */
  createOrderByTerminal(order): Observable<any> {
    if(order.paymentType == 'splitAmount'){
      let apiEndPoint = `${this.api}order/payment/partial-pay/square-terminal/${order.orderId}`;
      let payload = {
        deviceId: order.deviceId,
        processingFee: order.processingFee,
        platformFee: order.platformFee,
        paymentType: 'SQUARE_TERMINAL',
        amount: order.totalAmount,
        storeId: order.storeId,
      };
      return this.apiConfig.post(apiEndPoint, payload);
    }
    else if(order.id){
      let apiEndPoint = `${this.api}order/paid/square-terminal/${order.id}`;
      let payload = {
        deviceId: order.deviceId,
        totalAmount: order.totalAmount,
        processingFee: order.processingFee,
        platformFee: order.platformFee
      };
      return this.apiConfig.post(apiEndPoint, payload);
    }else{
      return this.apiConfig.post(this.api + 'order/kiosk/card-reader/Square terminal', order);
    }
  }
  /**
   * Create order by terminal
   * @param order
   * @returns
   */


  createOrderByNMICardReader(order): Observable<any> {
    if(order.paymentType == 'splitAmount'){
      let apiEndPoint = `${this.api}order/payment/partial-pay/nmi-terminal/${order.orderId}`;
      let payload = {
        deviceId: order.deviceId,
        amount: order.totalAmount,
        processingFee: order.processingFee,
        paymentType: 'NMI TERMINAL',
        platformFee: order.platformFee,
      };
      return this.apiConfig.post(apiEndPoint, payload);
    }
    else if(order.id){
      let apiEndPoint = `${this.api}order/paid/nmi-terminal/${order.id}`;
      let payload = {
        deviceId: order.deviceId,
        totalAmount: order.totalAmount,
        processingFee: order.processingFee,
      };
      return this.apiConfig.post(apiEndPoint, payload);
    }else{
      return this.apiConfig.post(this.api + 'order/kiosk/card-reader/NMI TERMINAL', order);
    }
  }



  /**
   * create card payment for order
   * @param orderId
   * @param paymentIntent
   * @param paymentModel
   * @returns
   */
   createCardPaymentForOrderByPaymentIntent(orderId, paymentIntent, paymentModel): Observable<any> {
     console.log('createCardPaymentForOrderByPaymentIntent called with:', orderId, paymentIntent, paymentModel);
     return this.apiConfig.post(
       this.api + 'order/cashier/payment/' + orderId + '/' + paymentIntent,
       paymentModel
     ).pipe(
       tap(response => console.log('createCardPaymentForOrderByPaymentIntent response:', response)),
       catchError(error => {
         console.error('createCardPaymentForOrderByPaymentIntent error:', error);
         return throwError(error);
       })
     );
   }



  /**
   * create card payment for order
   * @param orderId
   * @param paymentIntent
   * @param paymentModel
   * @returns
   */
   createCardPaymentForOrderByPaymentIntentForPlatform(orderId, paymentIntent, paymentModel): Observable<any> {
     console.log('createCardPaymentForOrderByPaymentIntentForPlatform called with:', orderId, paymentIntent, paymentModel);
     return this.apiConfig.post(
       this.api + 'order/cashier/payment/' + orderId + '/' + paymentIntent + '/platform',
       paymentModel
     ).pipe(
       tap(response => console.log('createCardPaymentForOrderByPaymentIntentForPlatform response:', response)),
       catchError(error => {
         console.error('createCardPaymentForOrderByPaymentIntentForPlatform error:', error);
         return throwError(error);
       })
     );
   }


  markCashOrderAsPaid(id, paymentModel): Observable<any> {
    return this.apiConfig.post(
      this.api + 'order/paid/cash/' + id,
      paymentModel,
      { withCredentials: true }
    );
  }

  markCardOrderAsPaid(id, paymentModel): Observable<any> {
    console.log('markCardOrderAsPaid called with:', id, paymentModel);
    return this.apiConfig.post(
      this.api + 'order/paid/card/' + id,
      paymentModel,
      { withCredentials: true }
    ).pipe(
      tap(response => console.log('markCardOrderAsPaid response:', response)),
      catchError(error => {
        console.error('markCardOrderAsPaid error:', error);
        return throwError(error);
      })
    );
  }



  markCardOrderAsPaidForPlatform(id, paymentModel): Observable<any> {
    console.log('markCardOrderAsPaidForPlatform called with:', id, paymentModel);
    return this.apiConfig.post(
      this.api + 'order/paid/card/' + id + '/platform',
      paymentModel,
      { withCredentials: true }
    ).pipe(
      tap(response => console.log('markCardOrderAsPaidForPlatform response:', response)),
      catchError(error => {
        console.error('markCardOrderAsPaidForPlatform error:', error);
        return throwError(error);
      })
    );
  }


  markOrderAsPaidByManualCard(id, paymentModel): Observable<any> {
    return this.apiConfig.post(
      this.api + 'order/paid/manual-card/' + id,
      paymentModel,
      { withCredentials: true }
    );
  }


  markOrderAsPaidBySquareManualCard(id, paymentModel): Observable<any> {
    return this.apiConfig.post(
      this.api + 'order/paid/square-manual-card/' + id,
      paymentModel,
      { withCredentials: true }
    );
  }

  markOrderAsPaidByNmiManualCard(id, paymentModel): Observable<any> {
    return this.apiConfig.post(
      this.api + 'order/paid/nmi-manual-card/' + id,
      paymentModel,
      { withCredentials: true }
    );
  }

  /**
   * Pay partial Amount
   * @param id
   * @param paymentModel
   * @returns
   */
  payPartialAmountByManualCard(id, paymentModel): Observable<any> {
    return this.apiConfig.post(
      this.api + 'order/payment/partial-pay/manual-card/' + id,
      paymentModel,
      { withCredentials: true }
    );
  }

  /**
   * Pay partial square card
   * @param id
   * @param paymentModel
   * @returns
   */
  payPartialAmountBySquareManualCard(id, paymentModel): Observable<any> {
    return this.apiConfig.post(
      this.api + 'order/payment/partial-pay/square-manual-card/' + id,
      paymentModel,
      { withCredentials: true }
    );
  }


  /**
   * Pay partial nmi card
   * @param id
   * @param paymentModel
   * @returns
   */
  payPartialAmountByNmiManualCard(id, paymentModel): Observable<any> {
    return this.apiConfig.post(
      this.api + 'order/payment/partial-pay/nmi-manual-card/' + id,
      paymentModel,
      { withCredentials: true }
    );
  }

  removeOrderById(id): Observable<any> {
    return this.apiConfig.delete(this.api + 'order/' + id, {
      withCredentials: true,
    });
  }

  sendReceipt(id, data): Observable<any> {
    return this.apiConfig.post(this.api + 'order/send-receipt/' + id, data, {
      withCredentials: true,
    });
  }
  getOrdersByDays(from, to): Observable<any> {
    if(!this.getStoreId()){
      return of(null);
    }
    return this.apiConfig.get(
      this.api +
      'order/cashier/order-range?from=' +
      from +
      '&to=' +
      to +
      '&storeId=' +
      this.getStoreId(),
      { withCredentials: true }
    );
  }

  getOrderById(id): Observable<any> {
    return this.apiConfig.get(this.api + 'order/admin/' + id, {
      withCredentials: true,
    });
  }

  guestCheckout(order): Observable<any> {
    return this.apiConfig.post(this.api + 'order/guest/checkout', order);
  }

  createCashPaymentByOrderId(orderId, paymentModel): Observable<any> {
    return this.apiConfig.post(
      this.api + 'order/cashier/payment/' + orderId,
      paymentModel
    );
  }

  /**
   * create card payment by order id
   * @param orderId
   * @param paymentIntent
   * @param paymentModel
   * @returns
   */
  // createCardPaymentByOrderId(orderId: number, paymentIntent: string, paymentModel: any): Observable<any> {
  //   return this.apiConfig.post(this.api + 'order/cashier/payment/' + orderId + '/' + paymentIntent, paymentModel);
  // }

  deleteOrderPaymentById(orderPaymentId): Observable<any> {
    return this.apiConfig.delete(
      this.api + 'order/cashier/payment/' + orderPaymentId
    );
  }

  markOrderAsPaid(orderId: any): Observable<any> {
    return this.apiConfig.patch(this.api + 'order/paid/' + orderId, {
      withCredentials: true,
    });
  }

  addItemToOrder(item: any, orderId: number): Observable<any> {
    return this.apiConfig.put(
      this.api + 'order-details/' + orderId + '/add',
      item,
      { withCredentials: true }
    );
  }

  updateProductFromSavedOrders(item: any, orderId: number): Observable<any> {
    return this.apiConfig.put(
      this.api + 'order-details/' + orderId + '/update',
      item,
      { withCredentials: true }
    );
  }

  makeCreditCardPayment(onliePaymentModel, gatewayName): Observable<any> {
    return this.apiConfig.post(
      this.api + 'order/kiosk/card/' + gatewayName,
      onliePaymentModel
    );
  }

  updateOrderStatus(order): Observable<any> {
    return this.apiConfig.put(this.api + 'order/update/status', order, {
      withCredentials: true,
    });
  }

  getAllOrderStatusList(): Observable<any> {
    return this.apiConfig.get(this.api + 'orderStatus/getAll');
  }

  updateTip(orderId:number, tipAmount: number): Observable<any> {
    return this.apiConfig.patch(`${this.api}order/${orderId}/tip/${tipAmount}`);
  }

  updateItemInOrder(item: any, orderId: number): Observable<any> {
    return this.apiConfig.put(
      this.api + 'order-details/' + orderId + '/update',
      item,
      { withCredentials: true }
    );
  }

  sendPaymentLink(id, data): Observable<any> {
    return this.apiConfig.post(this.api + 'order/payment/payment-link/' + id, data, {
      withCredentials: true,
    });
  }

  updateDiscount(orderId:number, couponId: number, couponValue: number): Observable<any> {
    return this.apiConfig.patch(`${this.api}order/${orderId}/coupon/${couponId}/${couponValue}`);
  }

  removeDiscount(orderId:number): Observable<any> {
    return this.apiConfig.patch(`${this.api}order/${orderId}/remove-coupon`);
  }

  updateChanges(orderId:number, changes: number): Observable<any> {
    return this.apiConfig.patch(`${this.api}order/${orderId}/changes/${changes}`);
  }

}
