import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';
import { IProfile } from 'src/app/appModels/IProfile';
import { RestaurantInfoResolver } from 'src/app/appResolvers/restaurant-info.resolver';
import { PaymentGatewayService } from 'src/app/appServices/payment-gateway.service';
import { ToastMessageService } from 'src/app/core/utilities/toast-message.service';
import { openWidget } from 'aerosync-web-sdk';
import { environment } from 'src/environments/environment';
import { TenantResolver } from 'src/app/appServices/tenant/tenant.resolver';

@Component({
  selector: 'app-add-aeropay-account',
  templateUrl: './add-aeropay-account.component.html',
  styleUrls: ['./add-aeropay-account.component.scss'],
})
export class AddAeropayAccountComponent extends HandleSubscriptionsComponent implements OnInit {
  basicInfo: any;
  @Input() profile: IProfile;
  status:string = 'Adding your Account';
  userId:any;
  userToken:string;

  constructor(
    private infoStorage: RestaurantInfoResolver,
    private modalCtrl: ModalController,
    private paymentGatewayService: PaymentGatewayService,
    private toaster: ToastMessageService,
    private _tenantResolver: TenantResolver
  ) {
    super();
  }

  ngOnInit() {
    this.getBasicInfo();
    this.addAccount();
  }

  /**
   * get basic information
   */
  getBasicInfo() {
    this.handleSubscription(this.infoStorage.restaurantBasicInfo,
      (res) => {
        if (res) {
          this.basicInfo = res;
        }
      }
    );
  }



  addAccount(){
    this.getTokenForMerchant();
  }


  /**
   * Get token for merchant
   */
  getTokenForMerchant(){
    this.status = 'Getting token for merchant';
    this.handleSubscription(this.paymentGatewayService.getAeropayTokenForMerchant(),
      (res) => {
        // console.log('getAeropayTokenForMerchant: ',res);
        this.createAeropayUser(res.token);
      },
      (err) => {
        this.toaster.errorToastWithClose('Error: '+ err);
      }
    );
  }

  
  /**
   * Create aeropay user
   * @param token 
   */
  async createAeropayUser(token:string) {
    this.status = 'Creating Aeropay user';
    const payload = JSON.stringify({
      first_name: this.profile.firstName,
      last_name: this.profile.lastName,
      phone_number: this.profile.mobileNumber,
      email: this.profile.emailId
  });

    const url = `${environment.baseUrl}payment-gateway/client/aeropay/create`;
    const response = await fetch(url, {
      method: 'POST',
      body: payload,
      headers: {
        'Authorization': 'Bearer ' + token,
         'Content-Type': 'application/json'
      }
    });
    const content = await response.json();
    this.userId = content.userId;
    // console.log('userId ',content.userId);
    this.getTokenForUser();
  }


  /**
   * Get token for user
   * @param userId 
   */
  getTokenForUser(){
    this.status = 'Getting token for user';
    this.handleSubscription(this.paymentGatewayService.getAeropayTokenForUser(this.userId),
      (res) => {
        // console.log('getAeropayTokenForUser: ',res);
        this.getAggregator(res.token);
        this.userToken = res.token;
      },
      (err) => {
        this.toaster.errorToastWithClose('Error: '+ err);
      }
    );
  }




 

  /**
   * Get aggregator
   * @param token 
   */
  async getAggregator(token:string) {
    this.status = 'Getting Aggregator';
    const url = `${environment.baseUrl}payment-gateway/client/aeropay/aggregator`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + token,
         'Content-Type': 'application/json'
      }
    });
    const content = await response.json();

    // console.log('getAggregator ',content.token);
    this.openAerosyncWidget(content.token);
  }



  /**
   * Integrate AeroSync UI AddBank
   */
  openAerosyncWidget(token:string) {
    let globalThis = this;
    let deeplink = ""; // Unique URL that points to the specific page within the mobile app

    // let consumerId = "1119757"; // Unique ID that represents the merchant for customization

    let widgetRef = openWidget({
      id: "widget",
      iframeTitle: 'Connect',
      // environment: 'staging', // staging, production
      environment: environment.production? 'production': 'staging', // staging, production
      token: token,
      style: {
        width: '375px',
        height: '688px',
        bgColor: '#000000',
        opacity: 0.7
      },
      deeplink: deeplink,
      // consumerId: this.userId,
      onEvent: function (event, type) {
        console.log("onEvent", event, type);
      },
      onLoad: function () {
        console.log("onLoad");
      },
      onSuccess: function (event:any) {
        console.log("onSuccess", event);

        if(event.user_id){
          globalThis.getAeropayAcAndSaveInDB(globalThis.profile.id, event.user_id, event.user_password, globalThis.userToken);
        }
      },
      onClose: function () {
        console.log("onClose");
      },
      onError: function (event) {
        console.log("onError", event);
      }
    });
    // launch Aerosync widget with the configuration
    widgetRef.launch();
  }


 
 /**
   * Get aeropay Ac and save in DB
   * @param userId 
   * @param username 
   * @param password 
   * @param token 
   */
  async getAeropayAcAndSaveInDB(userId:any, username:any, password:any, token:string) {
    const tenantName = this._tenantResolver.getTenantName();
    this.status = 'Getting Aeropay Ac and save in DB';
    const url = `${environment.baseUrl}payment-gateway/client/aeropay/account/${userId}/${username}/${password}`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
        'tenant-name': tenantName
      }
    });
    const content = await response.json();

    // console.log('getAeropayAcAndSaveInDB success ',content);

    if(content){
      this.closeModel('ACCOUNT_ADDED');
    }
  }


 

  

  closeModel(response): void {
    this.modalCtrl.dismiss(response);
  }
  

}
