import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import {
  StripeCardElementOptions,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import { RestaurantInfoResolver } from 'src/app/appResolvers/restaurant-info.resolver';
import { ToastMessageService } from 'src/app/core/utilities/toast-message.service';
import { ModalController } from '@ionic/angular';
import { OrderService } from 'src/app/appServices/order.service';
import { CartResolver } from 'src/app/appResolvers/cart.resolver';
import { environment } from 'src/environments/environment';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';
import { IStore } from 'src/app/appModels/IStore';
import { StoreResolver } from 'src/app/appResolvers/store.resolver';
import { CustomDiscountComponent } from 'src/app/includes/popups/custom-discount/custom-discount.component';
import { ChangeDetectorRef } from '@angular/core';
@Component({
  selector: 'app-stripe-manual-card',
  templateUrl: './stripe-manual-card.component.html',
  styleUrls: ['./stripe-manual-card.component.scss'],
})
export class StripeManualCardComponent extends HandleSubscriptionsComponent implements OnInit {
  couponCodeId: number;
  basicInfo: any;
  disablePayButton = false;
  fetching: boolean = false;

  stripeTest: UntypedFormGroup;

  elementsOptions: StripeElementsOptions = {
    //locale: 'es'
  };

  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  cardOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#7A7A7A',
        lineHeight: '40px',
        fontWeight: '600',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
  };

  @Input() data: any;
  selectedStore: IStore;
  publicKeyForFirstTranscation: string;
  showCard = true;

  constructor(
    private fb: UntypedFormBuilder,
    private stripeService: StripeService,
    private cartResolver: CartResolver,
    private orderService: OrderService,
    private modalCtrl: ModalController,
    public toastMessage: ToastMessageService,
    private _storeResolver: StoreResolver,
    private _basicInfoResolver: RestaurantInfoResolver,
    private cdr: ChangeDetectorRef
  ) {
    super();
    this.stripeTest = this.fb.group({
      name: ['', [Validators.required, this.noLeadingWhitespaceValidator]],
    });
  }

  ngOnInit(): void {
    // Set the first key before the first token creation
    this.subscribeBasicInfo();
    this.subscribeSelectedStore();
  }

  async openCustomDiscountModal() {
     try {
       const modal = await this.modalCtrl.create({
         component: CustomDiscountComponent,
         componentProps: {
           currentTotal: this.data.totalAmount
         }
       });

       await modal.present();

       const { data } = await modal.onDidDismiss();
       if (data) {
         console.log(data);
         const discount = data;
         this.applyDiscountToTotal(discount);
       }
     } catch (error) {
       console.error('Error opening custom discount modal:', error);
     }
   }


   applyDiscountToTotal(discount) {
     if (discount.discountType === 'percentage') {
       const discountAmount = this.data.totalAmount * (discount.value / 100);
       this.data.totalAmount -= discountAmount;
     } else if (discount.discountType === 'custom') {
       this.data.totalAmount = this.data.totalAmount - discount.value; // Set the custom amount as the new total
     }

     // Ensure change detection is aware of the updated total amount
     this.cdr.detectChanges();
   }

   removeCents() {
     this.data.totalAmount = Math.floor(this.data.totalAmount);
     this.cdr.detectChanges();  // Ensure Angular detects the change and updates the UI
   }





  // Custom validator to check for leading whitespace
  noLeadingWhitespaceValidator(control: AbstractControl): ValidationErrors | null {
    const hasLeadingWhitespace = control.value?.startsWith(' ');
    return hasLeadingWhitespace ? { leadingWhitespace: true } : null;
  }

  /**
   * Subscribe selected store
   */
  subscribeSelectedStore(){
    this.handleSubscription(this._storeResolver.selectedStore,
    (res) => {
      if (res) {
        this.selectedStore = res;
        this.getStripeAcByStoreId();
      }
    });
  }


   /**
   * Get Stripe Ac by Store id
   */
   getStripeAcByStoreId(){
    this.handleSubscription(this._storeResolver.getStripeAcByStoreId(this.selectedStore.id),
    (res) =>{
      console.log( 'res ',res)
      this.publicKeyForFirstTranscation = res?.publicKey;
      console.log( 'publicKeyForFirstTranscation ',this.publicKeyForFirstTranscation);
      this.initializeStripe(this.publicKeyForFirstTranscation);
    })
  }

  initializeStripe(key: string): void {
    this.stripeService.setKey(key);
    this.showCard = false;  // Hide the card component to allow re-initialization
    setTimeout(() => {
      this.showCard = true;  // Show the card component again after a brief delay
    }, 50);  // Adjust the delay if necessary to ensure proper re-rendering
  }


  async buy(): Promise<void> {
    this.fetching = true;
    this.disablePayButton = true;
    const name = this.stripeTest.get('name').value;
    const tokenIds:any[] =[];

    this.handleSubscription(this.stripeService.createToken(this.card.element, { name }),
      async (result) => {
        if (result.token) {
          this.chargeCard(result.token.id);
          // tokenIds.push(result.token.id);

          // this.handleSecondTokenCreation(tokenIds, name);
        } else if (result.error) {
          this.disablePayButton = false;
          this.toastMessage.errorToastWithClose(result.error.message);
        }
      },
      (err) => {
        console.log(err)
        this.fetching = false;
        this.disablePayButton = false;
        this.toastMessage.errorToastWithClose('Failed to Create Order');
      }
    );
  }





  /**
   * Handle second token creation
   * @param tokenIds
   * @param name
   */
  // handleSecondTokenCreation(tokenIds: string[], name: string){
  //   this.handleSubscription(this.stripeService.createToken(this.card.element, { name }),
  //     (result) => {
  //       if (result.token) {
  //         tokenIds.push(result.token.id);
  //         this.chargeMultipleCards(tokenIds);
  //       } else if (result.error) {
  //         this.disablePayButton = false;
  //         this.toastMessage.errorToastWithClose(result.error.message);
  //       }
  //     },
  //     (err) => {
  //       this.fetching = false;
  //       this.disablePayButton = false;
  //       this.toastMessage.errorToastWithClose('Failed to Create Order');
  //     }
  //   );
  // }


  chargeCard(token: string): void {
    if (this.data.type === "pay by paymentIntent") {
      this.payPartialAmount(token);
    } else if (this.data.id) {
      this.paySavedOrderByOrderId(token);
    } else {
      this.payOrder(token);
    }
  }
  // chargeMultipleCards(tokenIds: string[]): void {
  //   if (this.data.type === "pay by paymentIntent") {
  //     this.payPartialAmount(tokenIds);
  //   } else if (this.data.id) {
  //     this.paySavedOrderByOrderId(tokenIds);
  //   } else {
  //     this.payOrder(tokenIds);
  //   }
  // }

  /**
   * pay order with out order id
   * @param token
   */
  payOrder(token: string) {
    this.fetching = true;
    const onlinePaymentModel = {
      totalAmount: this.data.totalAmount,
      orderModel: this.data,
      stripePaymentModel: {
        paymentToken: token,
      },
    };
    this.handleSubscription(this.orderService
      .makeCreditCardPayment(onlinePaymentModel, 'Stripe'),
        (res) => {
          this.fetching = false;
          this.data.id = res;
          this.postPayment();
        },
        (err) => {
          this.fetching = false;
          this.disablePayButton = false;
          this.toastMessage.errorToastWithClose(
            'Error in creating order, If any money detected from your account will be refund.'
          );
        }
      );
  }

  /**
   * pay saved order
   * @param token
   */
  paySavedOrderByOrderId(token: string) {
    this.fetching = true;
    const temp = {
      paymentToken: token,
      totalAmount: this.data.totalAmount,
      processingFee: this.data.processingFee,
      platformFee: this.data.platformFee
    }
    this.handleSubscription(this.orderService
      .markOrderAsPaidByManualCard(this.data.id, temp),
        (res) => {
          this.fetching = false;
          this.postPayment();
        },
        (err) => {
          this.fetching = false;
          this.disablePayButton = false;
          this.toastMessage.errorToastWithClose(
            'Error in creating order, If any money detected from your account will be refund.'
          );
        }
      );
  }

  payPartialAmount(token: string) {
    this.fetching = true;
    const temp = {
      paymentToken: token,
      amount: this.data.totalAmount,
      processingFee: this.data.processingFee,
      paymentType: 'card',
      platformFee: this.data.platformFee
    }
    this.handleSubscription(this.orderService
      .payPartialAmountByManualCard(this.data.id, temp),
        (res) => {
          this.fetching = false;
          this.postPayment();
        },
        (err) => {
          this.fetching = false;
          this.disablePayButton = false;
          this.toastMessage.errorToastWithClose(
            'Error in creating order, If any money detected from your account will be refund.'
          );
        }
      );
  }

  postPayment(): void {
    this.disablePayButton = false;
    this.cartResolver.cart.next([]);
    //this.toastMessage.successToastWithClose('Order is successfully placed');
    this.onCloseModel();
  }

  /**
   * subscribe basic info
   */
  subscribeBasicInfo() {
    this.handleSubscription(this._basicInfoResolver.restaurantBasicInfo,
      (res) => {
        this.basicInfo = res;
      }
    );
  }




  onCloseModel(): void {
    this.modalCtrl.dismiss(this.data);
  }

  closeModal(): void {
    this.modalCtrl.dismiss();
  }
}
