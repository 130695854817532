import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpConfigService } from '../core/httpConfig/httpConfigService';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  baseApi = this.apiConfig.getBaseUrl;

  api = this.baseApi + 'configuration';

  constructor(private apiConfig: HttpConfigService) {}

  getSetting(type): Observable<any> {
    return this.apiConfig.get(this.api + '/getByParameter/' + type);
  }

  getStripeProcessingFee() {
    return this.apiConfig.get(this.baseApi + 'payment-gateway/client/stripe/fee');
  }

  getTaxInfo(): Observable<any> {
    return this.apiConfig.get(this.api + "/getByParameter/Tax");
  }

  getExciseTaxInfo(): Observable<any> {
    return this.apiConfig.get(this.api + "/getByParameter/Excise Tax");
  }

  updateTax(tax): Observable<any> {
    return this.apiConfig.put(this.api, tax, { withCredentials: true });
  }

  updateExciseTax(tax): Observable<any> {
    return this.apiConfig.put(this.api, tax, { withCredentials: true });
  }

  updateTipsInfo(enableTip): Observable<any> {
    return this.apiConfig.put(this.api, enableTip, { withCredentials: true });
  }


}
