import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class BasicAuthHtppInterceptorServiceService implements HttpInterceptor {
  constructor(
    private tokenService: TokenService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.includes('/tenant')) {
      req = req.clone({
        headers: this.tokenService.createTokenHeader()
      });
    }
    return next.handle(req).pipe(tap(event => event, event => this.handleError(event)));
  }

  private handleError(event: HttpEvent<any>): HttpEvent<any> {
    if (event instanceof HttpErrorResponse) {
      const error = <HttpErrorResponse>event;
      console.log(`failed: ${error.message}`);
    }
    return event;
  }
}
