import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { CookieService } from 'ngx-cookie-service';
import { NgxStripeModule } from 'ngx-stripe';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './appModules/core.module';
import { BasicAuthHtppInterceptorServiceService } from './core/auth/basic-auth-htpp-interceptor-service.service';
import { TenentIdentifierInterceptor } from './core/auth/tenantIdentifier/tenent-identifier.interceptor';
import { ServerErrorInterceptor } from './core/errorHandler/server-error.interceptor';
import { CardDetailsPageModule } from './includes/popups/card-details/card-details.module';
import { ThermalPrintModule } from 'ng-thermal-print'; //add this line
// import { ThermalPrintModule } from 'ng-thermal-print';
import { SideMenuComponent } from './layouts/side-menu/side-menu.component';
import { TabMenuComponent } from './layouts/tab-menu/tab-menu.component';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { BackupApiInterceptor } from './core/httpConfig/backup-api.interceptor';

@NgModule({
  declarations: [AppComponent,
    SideMenuComponent,
    TabMenuComponent,],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    CardDetailsPageModule,
    ThermalPrintModule,
    ReactiveFormsModule,
    FormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      // enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    NgxStripeModule.forRoot(),

    // ThermalPrintModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BasicAuthHtppInterceptorServiceService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TenentIdentifierInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BackupApiInterceptor,
      multi: true,
    },
    CookieService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
