import {
  HttpErrorResponse, HttpEvent, HttpHandler,
  HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StoreResolver } from 'src/app/appResolvers/store.resolver';
import { LogoutResolver } from 'src/app/appServices/logout/logout.resolver';
import { ToastMessageService } from '../utilities/toast-message.service';
import { RestaurantInfoResolver } from 'src/app/appResolvers/restaurant-info.resolver';
import { IBasicInfo } from 'src/app/appModels/IBasicInfo';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {

  private basicInfo: IBasicInfo;

  constructor(
    public toastMessage: ToastMessageService,
    private _logoutResolver: LogoutResolver,
    private navCtrl: NavController,
    private _restaurantInfoResolver: RestaurantInfoResolver
  ) {
    this._restaurantInfoResolver.restaurantBasicInfo.subscribe(res => {
      this.basicInfo = res;
    });
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = 'Something went wrong.';

        if (this.basicInfo?.businessType === 'cannabis' && error.error.details) {
            const errorDetails = error.error.details[0];
            return throwError(() => new Error(errorDetails));
          }

        if (error?.error === 'Unauthorized' || error.status === 401) {
          errorMessage = 'Invalid Credentials';
          localStorage.removeItem('token');
          this._logoutResolver.logout();
          return throwError(() => new Error(errorMessage));
        }

        if (error?.status === 500 && error.error.details && error.error.details[0] === 'No Logged In User Found') {
          localStorage.removeItem('token');
          this._logoutResolver.logout();
          errorMessage = error.error.details[0];
          this.toastMessage.errorToastWithClose('Session timeout, Please login again');
          return throwError(() => new Error(errorMessage));
        }


        return throwError(() => new Error(errorMessage));
      })
    );
  }
}
