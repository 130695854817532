import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpConfigService } from '../core/httpConfig/httpConfigService';

@Injectable({
  providedIn: 'root'
})
export class StoresService {

  api = this.apiConfig.getBaseUrl;

  constructor(private apiConfig: HttpConfigService) {
  }

  /**
   * get all stores info
   */
  getAllStores(): Observable<any[]> {
    return this.apiConfig.get(this.api + "store");
  }
  

  /**
   * Get Stripe account by store id
   * @param storeId 
   * @returns 
   */
   getStripeAcByStoreId(storeId): Observable<any> {
    return this.apiConfig.get(`${this.api}store/stripe/${storeId}`);
  }


  /**
   * Get Square account by store id
   * @param storeId 
   * @returns 
   */
  getAeropayAcByStoreId(storeId): Observable<any> {
    return this.apiConfig.get(`${this.api}store/aeropay/${storeId}`);
  }


  /**
   * Get Square account by store id
   * @param storeId 
   * @returns 
   */
   getSquareAcByStoreId(storeId): Observable<any> {
    return this.apiConfig.get(`${this.api}store/square/${storeId}`);
  }


  /**
   * Get Rethink account by store id
   * @param storeId 
   * @returns 
   */
  getRethinkAcByStoreId(storeId): Observable<any> {
    return this.apiConfig.get(`${this.api}store/rethink/${storeId}`);
  }


  /**
   * get store list by stripe account
   * @param stripeAccountId 
   */
   getStoreListByStripeAccount(stripeAccountId: string): Observable<any> {
    return this.apiConfig.get(this.api + "/store/stripe/storelist/" + stripeAccountId);
  }


  /**
   * Map Stripe account with Store 
   * @param storeId 
   * @param stripeAccountId 
   * @returns 
   */
   mapAccountWithStore(storeId, stripeAccountId): Observable<any> {
    return this.apiConfig.patch(`${this.api}store/stripe/${storeId}/${stripeAccountId}`,null);
  }


  /**
   * Map Stripe account with Store 
   * @param storeId 
   * @param squareId 
   * @returns 
   */
  mapSquareAccountWithStore(storeId, squareId): Observable<any> {
    return this.apiConfig.patch(`${this.api}store/square/${storeId}/${squareId}`,null);
  }

  
  /**
   * Map Aeropay account with Store 
   * @param storeId 
   * @param accountId 
   * @returns 
   */
  mapAeropayAccountWithStore(storeId, id): Observable<any> {
    return this.apiConfig.patch(`${this.api}store/aeropay/${storeId}/${id}`,null);
  }


  /**
   * Map Rethink account with Store
   * @param storeId 
   * @param rethinkAccountId 
   * @returns 
   */
  mapRethinkAccountWithStore(storeId, rethinkAccountId): Observable<any> {
    return this.apiConfig.patch(`${this.api}store/rethink/${storeId}/${rethinkAccountId}`,null);
  }
  
  
  /**
   * Toggle Store Active
   * @param storeId 
   * @param active 
   * @returns 
  */
  updateActive(storeId, active): Observable<any> {
    return this.apiConfig.patch(`${this.api}store/${storeId}/${active}`,null);
  }

  



  /**
   * Create New Store
   * @param storeData 
   * @returns 
   */
  createNewStore(storeData): Observable<any> {
    return this.apiConfig.post(this.api + "store", storeData);
  }



  /**
   * Edit Store
   * @param store 
   * @returns 
   */
  editStore(store): Observable<any> {
    return this.apiConfig.put(this.api + "store", store);
  }

  /**
   * Get Store by Id
   * @param storeId 
   * @returns 
   */
  getStoreById(storeId): Observable<any> {
    return this.apiConfig.get(this.api + "store/" + storeId);
  }

  /**
   * Delete Store by id
   * @param storeId 
   * @returns 
   */
  deleteStore(storeId): Observable<any> {
    return this.apiConfig.delete(this.api + "store/" + storeId);
  }

}
