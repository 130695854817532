import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ICategory } from '../appModels/ICategory';
import { IMenu } from '../appModels/IMenu';
import { IProduct } from '../appModels/IProduct';
import { IStore } from '../appModels/IStore';
import { AddOnService } from '../appServices/addon/add-on.service';
import { CategoryService } from '../appServices/category/category.service';
import { GlobalAuthResolver } from '../appServices/global-auth/global-auth.resolver';
import { MenuServices } from '../appServices/menu.service';
import { ProductService } from '../appServices/product/product.service';
import { VariantService } from '../appServices/topping/variant.service';
import { StoreResolver } from './store.resolver';

@Injectable({
    providedIn: 'root'
})
export class MenuResolver {
    store: IStore;
    selectedCategory = new BehaviorSubject<ICategory>(null);
    categories = new BehaviorSubject<any>(null);
    searchItem = new BehaviorSubject<string>(null);
    products = new BehaviorSubject<IProduct[]>(null);
    
    menu = new BehaviorSubject<IMenu[]>(null);
    selectedMenu = new BehaviorSubject<IMenu>(null);
    loggedIn:boolean;
    selectedStoreSub:Subscription;

    private productsLoadedSubject = new BehaviorSubject<boolean>(false);
    productsLoaded$ = this.productsLoadedSubject.asObservable();
    
    constructor(
        private _menuService: MenuServices,
        private _globalAuthResolver: GlobalAuthResolver,
        private _storeResolver: StoreResolver,
        private productService: ProductService,
        private categoryService:CategoryService,
        private addonService :AddOnService,
        private toppingService: VariantService,
    ) {
        // this.subscribeSelectedStore();
        this.subscribeLogin();
    }


    setProductsLoaded(loaded: boolean): void {
        this.productsLoadedSubject.next(loaded);
    }

    
    /**
     * get Menu from API
     * @returns 
     */
    getAllMenus() {
        this._menuService.getAllMenus().subscribe(res => {
            res && this.setMenu(res);
            if(!this.selectedMenu.value?.id) this.selectedMenu.next(res[0]);
        });
    }

    /**
    * Initialize Menu
    * @param menu 
    */
    setMenu(menu: IMenu[]) {
        this.menu.next(menu);
    }

    
  
    /**
     * Subscribe Selected Store 
     */
    subscribeSelectedStore() {
        this.selectedStoreSub = this._storeResolver.selectedStore.pipe(
            debounceTime(1000),
            distinctUntilChanged()
            ).subscribe(res =>{
            this.store = res;
            this.getAllCategories();
        });
    }
    
   
    /**
     * subscribe login
     */
    subscribeLogin() {
        this._globalAuthResolver.loggedIn.subscribe(res => {
            this.loggedIn = res;
            if(this.loggedIn === false) {
                this.clearStorage();
            }
        });
    }


    debounceCall:boolean = false;
    getAllCategories(){
        if(!this.store?.menuId) {
            this.categories.next([]);
            return;
        }
        if(!this.debounceCall){
            this.debounceCall = true;
            this._menuService.getAllCategory(this.store.menuId).subscribe(
                (res) => {
                    if (res) {
                        let allCategory = [
                            { id: -1, active:true ,name: 'Keypad' },
                            ...res
                          ];
                        this.categories.next(allCategory.filter(e => e.active));
                        
        
                        let currentCategoryIndex = 0;
                        const categories = this.categories.value;

                        while (currentCategoryIndex < categories.length) {
                            const currentCategory = categories[currentCategoryIndex];

                            if (currentCategory.activeProductsCount === 0) {
                                currentCategoryIndex++;
                            } else {
                                this.setSelectedCategory(currentCategory);
                                break;
                            }
                        }

                        this.debounceCall = false;
                    }
                },
                (err) => {
                    this.debounceCall = false;
                    console.log(err)
                });
        }
    }
  



  /**
   * Get all products by page with category
   * @param categoryId 
   * @param pageSize 
   * @param pageIndex 
   * @param search 
   * @returns 
   */
  getAllProductsByPageWithCategory(categoryId:number, pageSize: number, pageIndex: number, search: string): Observable<any> {
    return this._menuService.getAllProductsByPageWithCategory(categoryId, pageSize, pageIndex, search,this.store.menuId);
  }

     

    

    /**
     * set selected category
     */
    setSelectedCategory(category:ICategory) {
        if (this.selectedCategory.value !== category) {
            this.selectedCategory.next(category);
        }
    }
    
    /**
     * clear menu storage
     */
    public clearMenu(clear?:boolean) {
        this.clearStorage();
        if(!clear) this.getAllCategories();
    }

    private clearStorage() {
        this.setMenu(null);
        this.selectedCategory.next(null);
        this.categories.next(null);
        this.products.next(null);
    }

    

    
    /**
     * Get Reports (Counts)
     * @param menuId 
     * @returns 
     */
    getReports(menuId: number, count: string): Observable<any> {
        return this._menuService.getReports(menuId, count);
    }

    

    /**
     * Create Menu
     */
    createMenu(menu: IMenu): Observable<any> {
        return this._menuService.createMenu(menu);
    }



    /**
     * Update Menu
     * @param menuId 
     * @param name 
     * @returns 
     */
    updateName(menuId: number, name: string): Observable<any> {
      return this._menuService.updateMenuName(menuId, name);
    }


    /**
     * Post Update Menu 
     */
    postUpdateMenu(menuId: number, name: string) {
      const tempMenu = [...this.menu.value];
      const index = tempMenu.findIndex(e => e.id === menuId);
      tempMenu[index].name = name;
      this.setMenu(tempMenu);
      this.selectedMenu.next(tempMenu[index]);
    }
    


    
    /**
     * Map menu with store
     * @param storeId 
     * @param menuId 
    */
    mapMenuWithStore(storeId: number, menuId: number) {
        return this._menuService.mapMenuWithStore(storeId, menuId);
    }

    /**
     * get product ids
     * @param menuId 
     */
    getProductIds(menuId: number): Observable<any> {
        return this.productService.getProductIdsByMenu(menuId);
    }

  
    /**
     * Get Menu by id
     * @param menuId 
     * @returns 
     */
    getMenuById(menuId: number){
      return this._menuService.getMenuById(menuId)
    }




    /**
     * Delete Menu
     * @param menuId 
     * @returns 
     */
    deleteMenu(menuId: number): Observable<any> {
        return this._menuService.deleteMenu(menuId);
    }


    /**
     * Duplicate product
     */
    duplicateProduct(productId: number, menuId: number): Observable<any> {
      return this._menuService.duplicateProduct(menuId, productId);
    }


    /**
     * delete product by id
     * @param productId 
     */
    deleteProduct(productId: number): Observable<any> {
      return this.productService.deleteProduct(productId);
    }

    /**
     * delete categories
     * @param menuId 
     * @returns 
     */
    deleteCategories(menuId: number): Observable<any> {
        return this.categoryService.deleteCategoiesByMenu(menuId);
    }


    /**
     * delete addons
     * @param menuId 
     * @returns 
     */
    deleteAddons(menuId: number): Observable<any> {
        return this.addonService.deleteAddonsByMenu(menuId);
    }


    /**
     * delete toppings
     * @param menuId 
     * @returns 
     */
    deleteToppings(menuId: number): Observable<any> {
        return this.toppingService.deleteVariantsByMenu(menuId);
    }



}