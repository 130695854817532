import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';
import { RestaurantInfoResolver } from 'src/app/appResolvers/restaurant-info.resolver';
import { OrderService } from 'src/app/appServices/order.service';
import { ToastMessageService } from 'src/app/core/utilities/toast-message.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-square-card-reader',
  templateUrl: './square-card-reader.component.html',
  styleUrls: ['./square-card-reader.component.scss'],
})
export class SquareCardReaderComponent extends HandleSubscriptionsComponent implements OnInit {
  basicInfo: any;
  @Input() dataPayload: any;
  cardAnimationJson: string = "https://assets4.lottiefiles.com/packages/lf20_pu02tdjw.json";

  simulation:boolean = false;
  paymentSuccess:boolean = false;


  // The URL where the Point of Sale app will send the transaction results.
  callbackUrl = environment.squareCredentials.callbackUrl;

  // Your application ID
  applicationId = environment.squareCredentials.appId;

  // The total and currency code should come from your transaction flow.
  // For now, we are hardcoding them.
  transactionTotal = 25.55;
  currencyCode = "USD";

  // The version of the Point of Sale SDK that you are using.
  sdkVersion = "v2.0";

 
  constructor(
    private orderService: OrderService,
    private infoStorage: RestaurantInfoResolver,
    private modalCtrl: ModalController,
    private toastMessage: ToastMessageService,
  ) {
    super();
  }

  ngOnInit() {
    this.getBasicInfo();
    // this.checkout();
  }

  /**
   * get basic information
   */
  getBasicInfo() {
    this.handleSubscription(this.infoStorage.restaurantBasicInfo,
      (res) => {
        if (res) {
          this.basicInfo = res;
        }
      }
    );
  }


  openURL(){
    // Configure the allowable tender types
    var tenderTypes =
     "com.squareup.pos.TENDER_CARD, \
      com.squareup.pos.TENDER_CARD_ON_FILE, \
      com.squareup.pos.TENDER_CASH, \
      com.squareup.pos.TENDER_OTHER";
  
    var posUrl =
      "intent:#Intent;" +
      "action=com.squareup.pos.action.CHARGE;" +
      "package=com.squareup;" +
      "S.com.squareup.pos.WEB_CALLBACK_URI=" + this.callbackUrl + ";" +
      "S.com.squareup.pos.CLIENT_ID=" + this.applicationId + ";" +
      "S.com.squareup.pos.API_VERSION=" + this.sdkVersion + ";" +
      "i.com.squareup.pos.TOTAL_AMOUNT=" + this.transactionTotal + ";" +
      "S.com.squareup.pos.CURRENCY_CODE=" + this.currencyCode + ";" +
      "S.com.squareup.pos.TENDER_TYPES=" + tenderTypes + ";" +
      "end";
  
    window.open(posUrl);
  }
  




 












      
  /**
   * Checkout with payment intent
   */
  checkout() {
    if(!this.simulation){
      this.handleSubscription(this.orderService.createOrderByTerminal(this.dataPayload),
        (res) => {
          this.paymentSuccess = true;
        },
        (err) => {
          this.toastMessage.errorToastWithClose('Error in creating order.');
        }
      );
    }else{
      setTimeout(() => {
        this.paymentSuccess = true;
      }, 2000);
    }
  }
 

  

  closeModel(response): void {
    this.modalCtrl.dismiss(response);
  }
  

}
