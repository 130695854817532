import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { MenuResolver } from 'src/app/appResolvers/menu.resolver';
import { RestaurantInfoResolver } from 'src/app/appResolvers/restaurant-info.resolver';
import { StoreResolver } from 'src/app/appResolvers/store.resolver';
import { TokenService } from 'src/app/core/auth/token.service';
import { CdsResolver } from 'src/app/pages/cds/cds.resolver';
import { ClientTypeResolver } from '../client-type/client-type.resolver';
import { GlobalAuthResolver } from '../global-auth/global-auth.resolver';
import { TenantResolver } from '../tenant/tenant.resolver';

@Injectable({
  providedIn: 'root'
})
export class LogoutResolver {


  constructor(
    private _restaurantInfoResolver: RestaurantInfoResolver,
    private _tenantResolver: TenantResolver,
    private tokenService: TokenService,
    private _menuResolver: MenuResolver,
    private navCtrl: NavController,
    private _globalAuthResolver: GlobalAuthResolver,
    private _storeResolver: StoreResolver,
    private _clientTypeResolver: ClientTypeResolver,
    private _cdsResolver: CdsResolver,
    private router: Router
  ) {

  }


  clearMenuInStorage() {
    const keysToRemove = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && key.startsWith('productList_')) {
        keysToRemove.push(key);
      }
    }
    keysToRemove.forEach(key => localStorage.removeItem(key));
  }


  /**
   * logout
   */
  public logout() {
    if (this.router.url.startsWith("/auth")) {
      return;
    }
    this._globalAuthResolver.loggedIn.next(false);
    this._cdsResolver.destroySubscriptions();
    this._menuResolver.clearMenu(true);
    this._storeResolver.clear();
    this._clientTypeResolver.clear();
    this.clearMenuInStorage();
    this.tokenService.clearToken();
    this._tenantResolver.clearTenantName();
    this._restaurantInfoResolver.clearBasicInfo();
    localStorage.removeItem('store');
    localStorage.removeItem('user');
    this.navCtrl.navigateForward('/auth');
  }


  public shiftEnd() {
    this._globalAuthResolver.loggedIn.next(false);
    this._cdsResolver.destroySubscriptions();
    this._menuResolver.clearMenu(true);
    this._storeResolver.clear();
    this._clientTypeResolver.clear();
    this.tokenService.clearToken();
    // localStorage.removeItem('user');
    this.navCtrl.navigateForward('/auth/login');
  }

}
