import { Injectable } from '@angular/core';
import { NumberUtility } from '../appUtilities/number.utility';
import { UserService } from './user/user.service';
import { CashMgmtService } from './cash-mgmt.service';

@Injectable({
  providedIn: 'root'
})
export class ProcessingFeeService {
  percentCommission:number;
  instantDepositConfig: any;

  constructor(
    private numberUtility: NumberUtility,
    private userService: UserService,
    private cashMgmtService: CashMgmtService
  ) { 
    this.getSalesmanCommission();
    this.getInstantDepositConfig();
  }

  calculateSquareTerminalProcessingFee(paymentMethod: any, totalAmount: number, stripeProcessingFees: any): number {
    
    let feePercentage = 2.60 / 100;
    let fixedFee = 0.10;

    // Processing fee for subtotal
    const processingFeeSubtotal = ((totalAmount + fixedFee) / (1 - feePercentage)) - totalAmount;
    // console.log('processingFeeSubtotal => ',processingFeeSubtotal);
    

    if (paymentMethod.toUpperCase() === 'CARD PAYMENT') {
      fixedFee = stripeProcessingFees?.fixedFee;
      feePercentage = stripeProcessingFees?.percentageFee * 0.01;
    }

    if (paymentMethod.toUpperCase() === 'CARD READER') {
      fixedFee = stripeProcessingFees?.inPersonFixedFee;
      feePercentage = stripeProcessingFees?.inPersonFeePercentageFee * 0.01;
    }


    // Processing fee for salesman
    const platformFee = this.getPlatformFeeFromRules(
      totalAmount,
      stripeProcessingFees?.platformFeeRules,
      stripeProcessingFees?.platformFee
    );
    const salesmanFee =  this.percentCommission * (platformFee/100);
    const salesmanProcessingFee = ((salesmanFee + fixedFee) / (1 - feePercentage)) - salesmanFee;
    // console.log('salesmanProcessingFee => ',salesmanProcessingFee);


    // Processing fee for platform
    const remainingPlatformFee = platformFee - (salesmanFee || 0);
    const platformProcessingFee = ((remainingPlatformFee + fixedFee) /(1 - feePercentage)) - remainingPlatformFee;
    // console.log('platformProcessingFee => ',platformProcessingFee);
    
    const processingFee = processingFeeSubtotal + (salesmanProcessingFee || 0) + platformProcessingFee;
    // console.log('processingFee => ',processingFee);

    const tastiFee = platformFee + processingFee;
    return this.numberUtility.roundToTwo(tastiFee);

  }


  calculateSquareCardProcessingFee(paymentMethod: any, totalAmount: number, stripeProcessingFees: any): number {
    let feePercentage = 2.90 / 100;
    let fixedFee = 0.30;

    // Processing fee for subtotal
    const processingFeeSubtotal = ((totalAmount + fixedFee) / (1 - feePercentage)) - totalAmount;
    // console.log('processingFeeSubtotal => ',processingFeeSubtotal);
    

    // if (paymentMethod.toUpperCase() === 'CARD PAYMENT') {
    //   fixedFee = stripeProcessingFees?.fixedFee;
    //   feePercentage = stripeProcessingFees?.percentageFee * 0.01;
    // }

    // if (paymentMethod.toUpperCase() === 'CARD READER') {
    //   fixedFee = stripeProcessingFees?.inPersonFixedFee;
    //   feePercentage = stripeProcessingFees?.inPersonFeePercentageFee * 0.01;
    // }


    // Processing fee for salesman
    const platformFee = this.getPlatformFeeFromRules(
      totalAmount,
      stripeProcessingFees?.platformFeeRules,
      stripeProcessingFees?.platformFee
    );
    const salesmanFee =  this.percentCommission * (platformFee/100);
    const salesmanProcessingFee = ((salesmanFee + fixedFee) / (1 - feePercentage)) - salesmanFee;
    // console.log('salesmanProcessingFee => ',salesmanProcessingFee);


    // Processing fee for platform
    const remainingPlatformFee = platformFee - (salesmanFee || 0);
    const platformProcessingFee = ((remainingPlatformFee + fixedFee) /(1 - feePercentage)) - remainingPlatformFee;
    // console.log('platformProcessingFee => ',platformProcessingFee);
    
    const processingFee = processingFeeSubtotal + (salesmanProcessingFee || 0) + platformProcessingFee;
    // console.log('processingFee => ',processingFee);
    
    const tastiFee = platformFee + processingFee;
    // console.log('tastiFee => ',tastiFee);
    return this.numberUtility.roundToTwo(tastiFee);

  }


  private getSalesmanCommission() {
    this.userService.getSalesman().subscribe(res=>{
      // console.log(res);
      this.percentCommission = res[0]?.percentCommission || 0;
    })
  }


  /**
   * Get instant deposit config
   */
  getInstantDepositConfig(){
    this.cashMgmtService.getConfiguration('instantDeposit').subscribe(res=>{
        this.instantDepositConfig = res.value;
    })
  }

  /**
   * Get calculated platform fee amount for second transaction of card reader
   * @param totalAmount 
   * @param stripeProcessingFees 
   * @returns 
   */
  getCalculatedTotalAmountForStripeCardReader(
    totalAmount: number,
    subtotal: number,
    stripeProcessingFees: any
  ): number {

    let fixed_fee = stripeProcessingFees?.inPersonFixedFee || 0;
    let percent_fee = stripeProcessingFees?.inPersonFeePercentageFee * 0.01 || 0;

    const platformFee = this.getPlatformFeeFromRules(
      totalAmount,
      stripeProcessingFees?.platformFeeRules,
      stripeProcessingFees?.platformFee
    );

    const amountToRestaurant = this.numberUtility.roundToTwo((subtotal + fixed_fee) / (1 - percent_fee));
    return amountToRestaurant;
  }



  /**
   * Get calculated platform fee amount for second transaction of card reader
   * @param totalAmount 
   * @param stripeProcessingFees 
   * @returns 
   */
  getCalculatedPlatformFeeAmount(
    totalAmount: number,
    stripeProcessingFees: any
  ): number {

    let fixed_fee = stripeProcessingFees?.inPersonFixedFee || 0;
    let percent_fee = stripeProcessingFees?.inPersonFeePercentageFee * 0.01 || 0;

    const platformFee = this.getPlatformFeeFromRules(
      totalAmount,
      stripeProcessingFees?.platformFeeRules,
      stripeProcessingFees?.platformFee
    );

    const amountToTastio = this.numberUtility.roundToTwo((platformFee + fixed_fee) / (1 - percent_fee));
    return amountToTastio;
  }

  
  calculateStripeProcessingFee(
    paymentMethod: any,
    totalAmount: number,
    stripeProcessingFees: any
  ): number {
    let processingFee = 0;
    let fixed_fee = 0;
    let percent_fee = 0;
    let amountToPay = 0;

    if (paymentMethod.toUpperCase() === 'CARD PAYMENT') {
      fixed_fee = stripeProcessingFees?.fixedFee;
      percent_fee = stripeProcessingFees?.percentageFee * 0.01;
    }

    if (paymentMethod.toUpperCase() === 'CARD READER') {
      fixed_fee = stripeProcessingFees?.inPersonFixedFee;
      percent_fee = stripeProcessingFees?.inPersonFeePercentageFee * 0.01;
    }

    const platformFee = this.getPlatformFeeFromRules(
      totalAmount,
      stripeProcessingFees?.platformFeeRules,
      stripeProcessingFees?.platformFee
    );

    // if (this.instantDepositConfig === '1') {
    //   percent_fee = percent_fee + (0.01);
    // }

    // if (paymentMethod.toUpperCase() === 'CARD READER') {
      // amountToPay = (totalAmount + fixed_fee) / (1 - percent_fee);
      // amountToPay = (totalAmount + platformFee + fixed_fee) / (1 - percent_fee);
    // } else {
      // const amountToRestaurant = (totalAmount + fixed_fee) / (1 - percent_fee);
      // const amountToTastio = (platformFee + fixed_fee) / (1 - percent_fee);  
      // amountToPay = amountToRestaurant  + amountToTastio;
      // amountToPay = amountToRestaurant;
    // }
    
    amountToPay = (totalAmount + platformFee + fixed_fee) / (1 - percent_fee);

    processingFee = this.numberUtility.roundToTwo(amountToPay - totalAmount);

    return processingFee;
  }

  getPlatformFeeFromRules(totalAmount: number, platformFeeRules: any[], defaultPlatformFee: number): number {
    let result = 0;
    if(localStorage.getItem("connectedAccount") === "false"){
      return result;
    }

    if(platformFeeRules?.length<1){
      return
    }

    for (let i = 0; i < platformFeeRules?.length; i++) {
      const min = platformFeeRules[i].minOrderAmount;
      const max = platformFeeRules[i].maxOrderAmount;

      if (totalAmount > min && totalAmount <= max) {
        result = platformFeeRules[i].fee;
        break;
      }
    }

    return result > 0 ? result : defaultPlatformFee;
  }
  
}
