import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpConfigService } from '../../core/httpConfig/httpConfigService';

@Injectable({
  providedIn: 'root'
})
export class ClientTypeService {
  api = this.apiConfig.getBaseUrl;
  constructor(private apiConfig: HttpConfigService) {
  }

  getAllClients(): Observable<any> {
    return this.apiConfig.get(this.api + "clients");
  }


  addClient(client):Observable<any> {
    return this.apiConfig.post(this.api+"clients", client, {withCredentials: true});
  }

  updateClient(client):Observable<any> {
    return this.apiConfig.put(this.api+"clients/"+client.id, client, {withCredentials: true});
  }

  getAllOrderType(): Observable<any> {
    return this.apiConfig.get(this.api + "orderTypes/getAll");
  }

  
  /**
   * get all order types 
   */
  getOrderTypeList(storeId: number): Observable<any[]> {
    return this.apiConfig.get(`${this.api}store/${storeId}/orderTypes/cashier`);
  }


  /**
   * Toggle order type
   * @param storeId 
   * @param clientTypeId 
   * @param orderTypeId 
   * @param active 
   * @returns 
   */
  toggleOrderType(storeId, clientTypeId, orderTypeId, active): Observable<any> {
    return this.apiConfig.patch(`${this.api}clients/${storeId}/${clientTypeId}/${orderTypeId}/${active}`,null);
  }
  

}
