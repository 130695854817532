import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpConfigService } from 'src/app/core/httpConfig/httpConfigService';
import { IVariant } from 'src/app/appModels/IVariant';

@Injectable({
  providedIn: 'root'
})
export class VariantService {
 
  api = environment.baseUrl + "variant";

  getAllVariantEvent = new Subject();
  getChildVariantEvent = new Subject();

  constructor(private apiConfig: HttpConfigService) { }

  getAllVariants(menuId: number): Observable<any> {
    return this.apiConfig.get(`${this.api}/getAllVariants/${menuId}`);
  }
  getVariantsCountByMenuId(menuId: number): Observable<any> {
    return this.apiConfig.get(this.api + "/count/" + menuId);
  }

  getAllVariantsByPages(menuId: number, pageSize: number, pageIndex: number, search: String, status: boolean): Observable<any> {
    return this.apiConfig.get(`${this.api}/${menuId}/${pageSize}/${pageIndex}?search=${search}&status=${status}`);
  }

  addVariant(variant: IVariant): Observable<any> {
    return this.apiConfig.post(this.api+"/create", variant, {withCredentials: true});
  }
  removeVariant(variantId: number): Observable<any> {
    return this.apiConfig.delete(this.api + "/" + variantId, {withCredentials: true});
  }

  deleteVariantsByMenu(menuId: number): Observable<any> {
    return this.apiConfig.delete(this.api + "/menu/" + menuId, {withCredentials: true});
  }

  updateVariant(variant: IVariant): Observable<any> {
    return this.apiConfig.put(this.api + "/update/" + variant.id, variant, {withCredentials: true});
  }

  getChildVariantById(id): Observable<any> {
    return this.apiConfig.get(this.api + "/getChilds/" + id);
  }

  toggleVariant(variant: IVariant): Observable<any> {
    return this.apiConfig.patch(`${this.api}/status/${variant.id}/${variant.active}`,null);
  }

  mapWithInventory(variantId:number, inventoryIds:number[]): Observable<any>{
    return this.apiConfig.post(`${this.api}/map-with-inventory/${variantId}`, inventoryIds, {withCredentials: true});
  }

  getAllVariantGroups(menuId: number): Observable<any> {
    return this.apiConfig.get(`${this.api}-group/getAll/${menuId}`);
  }

  removeVariantFromGroup(variantGroupId: number ,variantId: number): Observable<any> {
    return this.apiConfig.delete(`${this.api}-group/map-variants/${variantGroupId}/${variantId}`, {withCredentials: true});
  }
  
  mapVariantsWithGroup(variantIds:number[], groupId:number): Observable<any>{
    return this.apiConfig.post(`${this.api}-group/map-variants/${groupId}`, variantIds, {withCredentials: true});
  }

  addVariantGroup(variantGroup: any): Observable<any> {
    return this.apiConfig.post(`${this.api}-group`, variantGroup, {withCredentials: true});
  }

  toggleVariantGroupActiveStatus(variantGroupId: number, status:boolean): Observable<any>{
    return this.apiConfig.patch(`${this.api}-group/status/${variantGroupId}/${status}`, {withCredentials: true})
  }

  toggleVariantGroupMultiselect(variantGroupId: number, status:boolean): Observable<any>{
    return this.apiConfig.patch(`${this.api}-group/multi-select/${variantGroupId}/${status}`, {withCredentials: true})
  }
  
  deleteVariantsGroup(groupId: number): Observable<any> {
    return this.apiConfig.delete(`${this.api}-group/${groupId}`, {withCredentials: true});
  }
  
  updateVariantGroupName(variantGroupId: number, name:string): Observable<any>{
    return this.apiConfig.patch(`${this.api}-group/${variantGroupId}/${name}`, {withCredentials: true})
  }
}
