import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpConfigService } from 'src/app/core/httpConfig/httpConfigService';
import { IStore } from 'src/app/appModels/IStore';
import { StoreResolver } from 'src/app/appResolvers/store.resolver';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  api = environment.baseUrl + "user";
  private store: IStore;

  constructor(private apiConfig: HttpConfigService, private storeResolver: StoreResolver) {
    this.subscribeSelectedStore();
  }

  getSalesman(): Observable<any> {
    return this.apiConfig.get(this.api + "/salesman");
  }

  sendRecallNotification(message: string, mobileNumbers: string[]): Observable<any> {
    const params = new HttpParams().set('message', message); // Set the message as a query parameter
    return this.apiConfig.post(`${this.api}/sendRecallNotification`, mobileNumbers, { params });
  }


  /**
   * Fetch users by store ID, barcode ID, and tenant name
   * @param barcodeId
   * @param tenantName
   * @returns Observable<any>
   */
   getUsersByStoreBarcodeTenant(barcodeSearchRequest: { storeId: number, barcodeIds: string[] }): Observable<any> {
     return this.apiConfig.post(`${this.api}/usersByStoreBarcodeTenant`, barcodeSearchRequest);
   }


  /**
   * Subscribe to the selected store and set the store ID
   */
  private subscribeSelectedStore() {
    this.storeResolver.selectedStore.subscribe(res => {
      this.store = res;
    });
  }

  /**
   * Get the store ID
   * @returns storeId or null if not set
   */
  private getStoreId(): number {
    return this.store?.id || null;
  }

}
