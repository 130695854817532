import { ApplicationRef, Injectable } from '@angular/core';
// import { SwUpdate } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';
import { ToastMessageService } from './toast-message.service';

@Injectable({
    providedIn: 'root'
})
export class CheckForUpdateService {

  constructor(
    private appRef: ApplicationRef, 
    // private updates: SwUpdate,
    private _toaster: ToastMessageService) {
    
  }

  checkUpdate(){
    // Allow the app to stabilize first, before starting polling for updates with `interval()`.
    const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
    const everySixHours$ = interval(6 * 60 * 60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

    // everySixHoursOnceAppIsStable$.subscribe(() => this.updates.checkForUpdate().then(res =>{
    //     console.log('Checking for Update');
    //   }));
    this.checkIfUpdateAvailable();
    // this.updates.
  }

  checkIfUpdateAvailable() {
    // if (!this.updates.isEnabled) {
    //   console.log('Not Enabled');
    //   return;
    // }
    // console.log('inside checkIfUpdateAvailable')
    // this.updates.available.subscribe((event) => {
    //   console.log(`current`, event.current, `available `, event.available);
    //   this.openTost();
    // });
    
  }

  openTost(){
    this._toaster.toast('A new version of app is available', 'Refresh').then(res=>{
      if(res){
        this.activateUpdates(); 
      }
    });
  }
  
  activateUpdates(){
    // if (confirm('update available for the app please conform')) {
      // this.updates.activateUpdate().then(() => 
      //location.reload();
      // this.updates.activateUpdate().then(() => {
      //   this.updates.activated.subscribe((event) => {
      //     console.log(`current`, event.previous, `available `, event.current);
      //   });
      //   location.reload();
      // });
    // }

  }

}