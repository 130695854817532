import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IBasicInfo } from '../appModels/IBasicInfo';
import { RestaurantInfoService } from '../appServices/restaurant-info.service';
import { TenantResolver } from '../appServices/tenant/tenant.resolver';
import { SettingsResolver } from './settings.resolver';

@Injectable({
    providedIn: 'root'
})
export class RestaurantInfoResolver {
    restaurantBasicInfo = new BehaviorSubject<IBasicInfo>(null);

    constructor(
        private restaurantInfoService: RestaurantInfoService,
        private tenantResolver: TenantResolver,
        private settingsResolver: SettingsResolver
    ) {
        this.getBasicInfo();
    }

    /**
     * get basic info
     */
     public getBasicInfo(forceGet?: boolean) {
         if (!this.tenantResolver.getTenantName()) {
             return;
         }
         const basicInfo = JSON.parse(localStorage.getItem("basic-info"));
         console.log('Basic Info from localStorage:', basicInfo);

         // Force fetch if requested
         if (forceGet) {
             this.getBasicInfoFromAPI().subscribe(res => {
                 this.setBasicInfo(res);
             });
         }
         // Fetch from API if basicInfo is missing businessType or incomplete
         else if (!basicInfo || !basicInfo.businessType) {
             console.warn('Basic info is missing businessType or is incomplete, fetching from API');
             this.getBasicInfoFromAPI().subscribe(res => {
                 this.setBasicInfo(res);
             });
         } else {
             this.restaurantBasicInfo.next(basicInfo);
         }
     }




    /**
     * get basic info from api
     * @returns
     */
    public getBasicInfoFromAPI(): Observable<IBasicInfo> {
        return this.restaurantInfoService.getBasicInfo();
    }

    public setBasicInfo(basicInfo: IBasicInfo) {
        localStorage.setItem("basic-info", JSON.stringify(basicInfo));
        this.restaurantBasicInfo.next(basicInfo);
    }

    /**
     * clear basic info
     */
    public clearBasicInfo() {
        this.restaurantBasicInfo.next(null);
        localStorage.removeItem('basic-info');
    }

}
