import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { interval, takeWhile } from 'rxjs';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';

@Component({
  selector: 'app-add-nmi-account',
  templateUrl: './add-nmi-account.component.html',
  styleUrls: ['./add-nmi-account.component.scss'],
})
export class AddNmiAccountComponent implements OnInit {

  src: string = 'https://tastio.nmipays.com/form/tastio-ic';
  showFrame:boolean = false;
  countdown: number = 5;
  timerId: any;

  constructor(
    private modalCtrl: ModalController,
  ) { 
  }

  ngOnInit() {}

  start() {
    this.showFrame = true;
    this.startTimer();
  }

  startTimer(){
    this.timerId = setTimeout(() => {
      this.countdown--;
      if (this.countdown > 0) {
        this.startTimer();
      } else {
        clearTimeout(this.timerId);
        window.open(this.src, '_blank');
      }
    }, 1000);
  }

  onClose() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

}
