import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SettingsService } from '../appServices/settings.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsResolver {
  tax = new BehaviorSubject<number>(0);
  exciseTax = new BehaviorSubject<number>(0);
  stripeProcessingFee = new BehaviorSubject(null);
  isMedicalGuest = new BehaviorSubject<boolean>(false);
  
  constructor(private _settingsService: SettingsService) { }

  getSettings() {
    this.getStripeProcessingFee();
    this.getTax();
    //TODO
    this.getExciseTax();
  }
  /**
   * Get Tax setting
   */
  public getTax() {
    this._settingsService.getSetting('tax').subscribe((res) => {
      res && this.tax.next(res.value);
    });
  }
  public getExciseTax() {
    this._settingsService.getSetting('Excise Tax').subscribe((res) => {
      res && this.exciseTax.next(res.value);
    });
  }


  /**
   * Get Stripe Payment Processing Fee
   * */
  public getStripeProcessingFee() {
    this._settingsService.getStripeProcessingFee().subscribe((res) => {
      this.stripeProcessingFee.next(res);
    });
  }


}
