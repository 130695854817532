import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';
import { IStore } from 'src/app/appModels/IStore';
import { StoreResolver } from 'src/app/appResolvers/store.resolver';
import { SquareService } from 'src/app/appServices/square.service';
import { ToastMessageService } from 'src/app/core/utilities/toast-message.service';

@Component({
  selector: 'app-square-account-modal',
  templateUrl: './square-account-modal.component.html',
  styleUrls: ['./square-account-modal.component.scss'],
})
export class SquareAccountModalComponent extends HandleSubscriptionsComponent implements OnInit {
  email: string;
  accounts: any[];
  loading: boolean = false;
  selectedStore: IStore;

  @Input() selectedSquareAccount: number;
  
  constructor(
    private modalCtrl: ModalController,
    private _storeResolver: StoreResolver,
    private toasterService: ToastMessageService,
    private squareService: SquareService

  ) { 
    super();
  }

  ngOnInit() {
    this.subscribeSelectedStore();
    this.getAllAccounts();
  }
  

  /**
  * subscribe selected store
  */
   subscribeSelectedStore() {
    this.handleSubscription(this._storeResolver.selectedStore, 
    (res) => {
      if (res) {
        this.selectedStore= res;
      }
    });
  }


  
  /**
   * Display more stores
   * @param storesArray 
   * @returns 
   */
  displayMoreStores(storesArray: any[]) {
    const storesToDisplay = storesArray.slice(2, storesArray.length);
    let storesToDisplayString: string = '';
    storesToDisplay.forEach((elem, index) => {
      if (index === 0) {
        storesToDisplayString = elem.storeName;
      } else {
        storesToDisplayString = storesToDisplayString + ', ' + elem.storeName;
      }
    });
    return storesToDisplayString;
  }

  /**
   * Get All Accounts
   */
  getAllAccounts() {
    this.handleSubscription(this.squareService.getAllAccounts(), 
      (res) =>{
        this.accounts = res;
      });
  }



  addNewAccount() {
    this.loading = true;
    this.handleSubscription(this.squareService.addSquareAccount(), 
    (res) => {
      this.getAllAccounts();
      this.loading = false;
      this.redirectToSquare(res.token);
    });
    
  }



  redirectToSquare(url) {
    var popup = window.open(url, "_self");
    setTimeout(function () {
      if (!popup || popup.outerHeight === 0) {
        //First Checking Condition Works For IE & Firefox
        //Second Checking Condition Works For Chrome
        // alert("Click OK to setup strip account...");
        // window.location.href = url;
      } else {
        //Popup Blocker Is Disabled
        // window.open(url, '_blank');
        // window.close();
      }
    }, 25);
  }


  mapToStore(squareAccountId){
    this.handleSubscription(this._storeResolver.mapSquareAccountWithStore(this.selectedStore.id, squareAccountId), 
    (res) =>{
      this.modalCtrl.dismiss(squareAccountId);
    })
  }


   
  
  visitDashboard(squareAccountId){
    this.handleSubscription(this.squareService.getDashboardUrl(squareAccountId),
      (res) =>{
        window.open(res.url, "_blank")
      },
      (err)=>{
        this.toasterService.errorToastWithClose('Error: '+ err);
      }
      );
    }
    
    completeOnboarding(squareAccountId){
      this.handleSubscription(this.squareService.getAccountLink(squareAccountId),
        (res) =>{
          window.open(res.url, "_blank")
        },
        (err)=>{
          this.toasterService.errorToastWithClose('Error: '+ err);
      }
    );
  }


  onClose() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  /**
   * Destroy Component
   */
   ngOnDestroy(): void {
  }

}
