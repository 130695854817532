import { Injectable } from '@angular/core';
import { IOrderDetailsModel, IOrderDetailsVariantModel, IOrderModel } from '../appModels/IOrderModel';
import { DateTimeUtility } from './dateTime.utility';
import { OrderUtility } from './order.utility';
import { HandleSubscriptionsComponent } from '../appDirectives/handle-subscriptions.directive';
import { SettingsResolver } from '../appResolvers/settings.resolver';
import { NumberUtility } from './number.utility';
import { RestaurantInfoResolver } from 'src/app/appResolvers/restaurant-info.resolver';
import { CannabisSettingsService } from 'src/app/appServices/biotrack/cannabis-settings.service'; // Add this import
import { StoreResolver } from 'src/app/appResolvers/store.resolver'; // Add this import
import { IStore } from 'src/app/appModels/IStore'; // Add this import for IStore model
import { PaymentMethods } from 'src/app/core/enums/enums';
import { ProcessingFeeService } from 'src/app/appServices/processing-fee.service';



@Injectable({
    providedIn: 'root'
})
export class ReceiptUtility extends HandleSubscriptionsComponent {
    stripeProcessingFees: any = {};
    basicInfo: any;
    taxIncluded: boolean = false;
    store: IStore;
    tips: any[] = [
      { id: 1, tip: 10, selected: false },
      { id: 2, tip: 15, mostTipped: true, selected: true },
      { id: 3, tip: 18, selected: false },
      { id: 4, tip: 20, selected: false },
      { id: 5, tip: 'Rounding', type: 'txt', selected: false },
      { id: 6, tip: 'Other', type: 'txt', selected: false },
    ];


    constructor(
        public dateTimeUtility: DateTimeUtility,
        private _orderUtility: OrderUtility,
        private numberUtility: NumberUtility,
        private infoStorage: RestaurantInfoResolver,
        private _settingResolver: SettingsResolver,
        private cannabisSettingsService: CannabisSettingsService,
        private _storeResolver: StoreResolver,
        private processingFeeService: ProcessingFeeService
    ) {
        super();
        this.getBasicInfo();
        this.subscribeStripeProcessingFee();
        this.subscribeSelectedStore();
    }


    getBasicInfo() {
      this.handleSubscription(this.infoStorage.restaurantBasicInfo,
        (res) => {
          if (res) {
            this.basicInfo = res;
          }
        }
      );
    }

    /**
     * Subscribe to the selected store to get the taxIncluded setting.
     */
    subscribeSelectedStore() {
        this.handleSubscription(this._storeResolver.selectedStore, (res) => {
            if (res) {
                this.store = res;
                this.getTaxIncludedSetting();
            }
        });
    }

    /**
     * Get the taxIncluded setting from the store.
     */
    getTaxIncludedSetting() {
        this.handleSubscription(this.cannabisSettingsService.getSettings(this.store.id), (res) => {
            if (res) {
                const taxIncludedSetting = res.find(setting => setting.name === 'taxIncluded');
                if (taxIncludedSetting) {
                    this.taxIncluded = +taxIncludedSetting.value === 1;
                }
            }
        });
    }

    getTaxAmount(order: IOrderModel) {
        const itemList = this._orderUtility.convertOrderDetailToCartList(order.orderDetailsModelList);
        // const subtotal = this._orderUtility.calculateCartTotalPrice(itemList);
        const tipAmount = order.tips || 0;
        const taxRate = order.tax;


        // Calculate subtotal and taxes
        let subtotal = 0;
        if (this.basicInfo?.businessType === 'cannabis') {
            subtotal = this._orderUtility.calculateCannabisCartTotalPrice(order?.orderDetailsModelList);
        } else {
            subtotal = this._orderUtility.calculateCartTotalPrice(order?.orderDetailsModelList);
        }
        console.log('Subtotal:', subtotal);

        // Debugging statements
        console.log('--- getTaxAmount Debugging ---');
        console.log('Subtotal:', subtotal);
        console.log('Tip Amount:', tipAmount);
        console.log('Tax Rate (order.tax):', taxRate);

        const taxAmount = this._orderUtility.calculateTax(subtotal, taxRate, tipAmount);

        console.log('Calculated Tax Amount:', taxAmount);
        console.log('-------------------------------');

        return taxAmount;
    }




    getExciseTaxAmount(order: IOrderModel) {
        const itemList = this._orderUtility.convertOrderDetailToCartList(order.orderDetailsModelList);
        const subtotal = this._orderUtility.calculateCartTotalPrice(itemList);
        const exciseTaxAmount = this._orderUtility.calculateExciseTax(subtotal, order.exciseTax, (order.tips || 0));
        return exciseTaxAmount;
    }



    // calculateProcessingFee(order: IOrderModel) {
    //     const processingFee = order?.orderPaymentModelList?.reduce((acc, obj) => {
    //         return acc + (Number(obj.processingFee) || 0); // Ensure processingFee is a number
    //     }, 0);
    //     return processingFee || 0;
    // }

    /**
   * Calculate the processing fee for a given payment method
   * @param paymentMethodTitle
   * @param totalAmount
   * @returns
   */
   calculateProcessingFee(paymentMethodTitle: string, totalAmount: number): number {
       if (!paymentMethodTitle) {
           console.log('[DEBUG] Missing payment method title.');
           return 0;
       }

       if (paymentMethodTitle === 'Cash On Delivery' || paymentMethodTitle === 'Cash Payment') {
           console.log('[DEBUG] Payment method is cash. No processing fee applied.');
           return 0;
       }

       let paymentMethodName = '';

       if (paymentMethodTitle === 'Card Payment' || paymentMethodTitle === 'Manual Card Entry') {
           paymentMethodName = 'Stripe';
       } else if (paymentMethodTitle === 'Card Reader') {
           paymentMethodName = 'Stripe';
       } else if (paymentMethodTitle === 'Square Terminal') {
           paymentMethodName = 'Square Terminal';
       } else if (paymentMethodTitle === 'Square Card') {
           paymentMethodName = 'Square Card';
       } else {
           paymentMethodName = 'Stripe'; // Default to Stripe
       }

       let calculatedFee = 0;

       if (paymentMethodName === 'Square Terminal') {
           calculatedFee = this.processingFeeService.calculateSquareTerminalProcessingFee(
               paymentMethodTitle,
               totalAmount,
               this.stripeProcessingFees
           );
       } else if (paymentMethodName === 'Square Card') {
           calculatedFee = this.processingFeeService.calculateSquareCardProcessingFee(
               paymentMethodTitle,
               totalAmount,
               this.stripeProcessingFees
           );
       } else {
           calculatedFee = this.processingFeeService.calculateStripeProcessingFee(
               paymentMethodTitle,
               totalAmount,
               this.stripeProcessingFees
           );
       }

       console.log('[DEBUG] Processing Fee Calculated:', {
           paymentMethodTitle,
           totalAmount,
           paymentMethodName,
           calculatedFee
       });

       return calculatedFee;
   }



  /**
 * Generate the payment options section in HTML
 * @param order
 * @returns
 */
private getPaymentOptionsHTML(order: IOrderModel): string {
    const separator = '************************************************************************************************************';
    let paymentOptionsHTML = '';

    if (!order?.paid && this.basicInfo?.businessType !== 'cannabis') {
        const totalAmount = order?.totalAmount || 0;
        const paymentMethods = [
            { title: 'Cash On Delivery', displayTitle: 'Cash Payment' },
            { title: 'Card Payment', displayTitle: 'Manual Card Entry' },
            { title: 'Card Reader', displayTitle: 'Card Reader' },
            // Add more methods as needed
        ];

        let paymentOptionsRows = '';

        paymentMethods.forEach(method => {
            const processingFee = this.calculateProcessingFee(method.title, totalAmount);
            // const platformFee = this.getPlatformFeeFromRules(
            //     totalAmount,
            //     this.stripeProcessingFees.platformFeeRules,
            //     this.stripeProcessingFees?.platformFee
            // );
            const platformFee = 0;


            console.log('[DEBUG] Platform Fee Applied in Receipt:', {
                    totalAmount: order.totalAmount,
                    platformFee,
                });

            const methodTotal = totalAmount + processingFee + platformFee;

            paymentOptionsRows += `
            <tr>
                <td>${method.displayTitle}:</td>
                <td align="right">$${methodTotal.toFixed(2)}</td>
            </tr>
            `;
        });

        paymentOptionsHTML = `
        <div class="title-row">
            ${separator}
            <span>Payment Options</span>
            ${separator}
        </div>
        <table>
            ${paymentOptionsRows}
        </table>
        `;
    }

    return paymentOptionsHTML;
}

/**
 * Generate the payment options section in plain text
 * @param order
 * @returns
 */
private getPaymentOptionsString(order: IOrderModel): string {
    let paymentOptionsString = '';

    if (!order?.paid && this.basicInfo?.businessType !== 'cannabis') {
        const totalAmount = order?.totalAmount || 0;
        const paymentMethods = [
            { title: 'Cash On Delivery', displayTitle: 'Cash Payment' },
            { title: 'Card Payment', displayTitle: 'Manual Card Entry' },
            { title: 'Card Reader', displayTitle: 'Card Reader' },
            // Add more methods as needed
        ];

        paymentOptionsString += '\nPayment Options\n' + '------------------\n';

        paymentMethods.forEach(method => {
            const processingFee = this.calculateProcessingFee(method.title, totalAmount);
            // const platformFee = this.getPlatformFeeFromRules(
            //     totalAmount,
            //     this.stripeProcessingFees.platformFeeRules,
            //     this.stripeProcessingFees?.platformFee
            // );
            const platformFee = 0;


            console.log('[DEBUG] Platform Fee Applied in Receipt String:', {
                    totalAmount: order.totalAmount,
                    platformFee,
                });

            const methodTotal = totalAmount + processingFee + platformFee;

            paymentOptionsString += `${method.displayTitle}: $${methodTotal.toFixed(2)}\n`;
        });
    }

    return paymentOptionsString;
}


    /**
     * Calculate percentage-based tip
     * @param percentage
     * @param subTotal
     * @returns
     */
    private calculatePercentageTip(percentage: number, subTotal: number): number {
      return +(percentage * subTotal / 100).toFixed(2);
    }

    /**
     * Calculate Rounding tip
     * @param subTotal
     * @param taxRate
     * @returns
     */
    private calculateRoundingTip(subTotal: number, taxRate: number): number {
      const tax = +(subTotal * taxRate / 100);
      const totalWithoutTip = subTotal + tax;
      const diff = Math.ceil(totalWithoutTip) - totalWithoutTip;
      const tipWithoutTax = (100 * diff) / (100 + taxRate);
      return +tipWithoutTax.toFixed(2);
    }


    calculateStripeProcessingFee(order: IOrderModel, paymentMethodTitle): number {
        let processingFee = 0;
        let fixed_fee = 0;
        let percent_fee = 0;

        if (paymentMethodTitle.toUpperCase() === 'CARD PAYMENT') {
            fixed_fee = this.stripeProcessingFees.fixedFee;
            percent_fee = this.stripeProcessingFees.percentageFee * 0.01;
        }

        if (paymentMethodTitle.toUpperCase() === 'CARD READER') {
            fixed_fee = this.stripeProcessingFees.inPersonFixedFee;
            percent_fee = this.stripeProcessingFees.inPersonFeePercentageFee * 0.01;
        }

        const platformFee = this.getPlatformFeeFromRules(order.totalAmount, this.stripeProcessingFees.platformFeeRules, this.stripeProcessingFees?.platformFee);

        const amountToPay = ((order.totalAmount + platformFee) + fixed_fee) / (1 - percent_fee);
        processingFee = this.numberUtility.roundToTwo(amountToPay - order.totalAmount);

        return processingFee;
    }


    /**
     * calculate cash amount paid
     * @param totalAmount
     * @returns
     */
    calculateCashAmountPaid(change, totalAmount) {
        let changesValue = parseFloat(change);
        let amountPaid = (totalAmount + changesValue).toFixed(2);
        return parseFloat(amountPaid);
    }


    /**
   * get platformfee from rules
   * @param totalAmount
   * @param platformFeeRules
   * @returns
   */
   getPlatformFeeFromRules(totalAmount: number, platformFeeRules: any[], defaultPlatformFee: number): number {
       let result = 0;
       for (let i = 0; i < platformFeeRules.length; i++) {
           const min = platformFeeRules[i].minOrderAmount;
           const max = platformFeeRules[i].maxOrderAmount;
           if (totalAmount > min && totalAmount <= max) {
               result = platformFeeRules[i].fee;
               break;
           }
       }
       const platformFee = result > 0 ? result : defaultPlatformFee;
       console.log('[DEBUG] Platform Fee Calculation:', {
           totalAmount,
           platformFeeRules,
           calculatedFee: platformFee,
       });
       return platformFee;
   }




    /**
     * subscribe stripe processing fee
     */
    subscribeStripeProcessingFee() {
        this.handleSubscription(this._settingResolver.stripeProcessingFee,
        (res) => {
            this.stripeProcessingFees = res;
        }
        );
    }

    public createReceiptString(order: IOrderModel): string {
        console.log('--- createReceiptString Debugging ---');
        console.log('Order:', order);

        const separator = '************************************************************************************************************';
        const lineBreak = '\n';

        // Fetch basicInfo
        if (!this.basicInfo) {
            this.getBasicInfo();
        }

        if (!this.basicInfo) {
            const basicInfoJSON = window.localStorage.getItem('basic-info');
            if (basicInfoJSON) {
                this.basicInfo = JSON.parse(basicInfoJSON);
            }
        }

        const basicInfo = this.basicInfo;
        console.log('Basic Info:', basicInfo);

        // Format the order date in MM-DD-YYYY HH:mm:ss format
        const orderDate = new Date(order?.orderDate);
        const formattedDate = `${(orderDate.getMonth() + 1).toString().padStart(2, '0')}-${orderDate.getDate().toString().padStart(2, '0')}-${orderDate.getFullYear()} ${orderDate.toLocaleTimeString('en-US')}`;

        let receiptString = '';

        // Online Order Invitation or Loyalty Points Section
        if (basicInfo?.customerPanelUrl) {
            receiptString += separator + lineBreak;
            receiptString += 'Loyalty & Online Ordering' + lineBreak;
            receiptString += separator + lineBreak;
            if (order?.customer?.loyaltyPoints !== undefined) {
                receiptString += `You've earned ${order?.customer?.loyaltyPoints} loyalty points!` + lineBreak;
                receiptString += `Order your favorites online at: ${basicInfo.customerPanelUrl}` + lineBreak;
                // receiptString += `Use your mobile number as your username: xxx-xxx-${order.customer.mobileNumber.slice(-4)}` + lineBreak;
                // receiptString += 'Exclusive deals and discounts await you! Don’t miss out.' + lineBreak;
            } else {
                receiptString += 'Thanks for choosing us! You can also enjoy online ordering and join our loyalty program at: ' + basicInfo.customerPanelUrl + lineBreak;
                receiptString += 'Save on your next visit with our online deals!' + lineBreak;
            }
        }

        // Order Header
        receiptString += separator + lineBreak;
        receiptString += 'Receipt' + lineBreak;
        receiptString += separator + lineBreak;
        // receiptString += 'Order Number: ' + order?.id + lineBreak;

        // Show discount and total before discount only if the discount is greater than 0
        if (order?.discount > 0) {
            receiptString += 'Total Before Discount: $' + (order?.totalAmount + order?.discount).toFixed(2) + lineBreak;
            receiptString += 'Discount: - $' + order?.discount.toFixed(2) + lineBreak;
        }

        receiptString += 'Total Amount: $' + order?.totalAmount?.toFixed(2) + lineBreak;
        receiptString += 'Order Date: ' + formattedDate + lineBreak;
        receiptString += 'Order Type: ' + order?.orderTypeModel?.type + lineBreak;
        // receiptString += 'Status: ' + order?.orderStatusModel?.status + lineBreak;
        receiptString += 'Payment: ' + (order?.paid ? 'Paid' : 'Un-Paid') + lineBreak;

        // Add Changes for all business types when changes > 0
        if (order?.changes > 0) {
            receiptString += 'Changes: $' + order?.changes?.toFixed(2) + lineBreak;
        }

        // Add Name only for cannabis businesses when customer exists
        if (basicInfo?.businessType === 'cannabis' && order?.customer != null) {
            receiptString += 'Name: ' + order?.customer?.firstName + ' ' + order?.customer?.lastName + lineBreak;
        }
        
        // Order Details
        receiptString += separator + lineBreak;
        receiptString += 'Order Details' + lineBreak;
        receiptString += separator + lineBreak;

        order?.orderDetailsModelList?.forEach(item => {
            const itemTotalPrice = item.quantity * item.basePrice;

            // Build product name and barcode line
            let productLine = `${item.quantity} X ${item.productName}`;

            // Add barcode ID for cannabis business type
            if (basicInfo?.businessType === 'cannabis' && item.productCannabisModel?.barcodeId) {
                productLine += ` (${item.productCannabisModel?.barcodeId})`;
            }

            receiptString += productLine + ' - $' + itemTotalPrice.toFixed(2) + lineBreak;
            receiptString += this.getToppingsAndAddonsString(item);
        });

        // Calculate subtotal and taxes
        let subtotal = 0;
        if (basicInfo?.businessType === 'cannabis') {
            subtotal = this._orderUtility.calculateCannabisCartTotalPrice(order?.orderDetailsModelList);
        } else {
            subtotal = this._orderUtility.calculateCartTotalPrice(order?.orderDetailsModelList);
        }
        console.log('Subtotal:', subtotal);

        const taxAmount = this.getTaxAmount(order);
        const exciseTaxAmount = this.getExciseTaxAmount(order);
        console.log('Tax Amount:', taxAmount);
        console.log('Excise Tax Amount:', exciseTaxAmount);

        // Processing Fee
        const processingFee =  order?.processingFee;
        // const processingFee = this.calculateProcessingFee(order?.paymentType, order?.totalAmount || 0);
        console.log('Processing Fee:', processingFee);

        // Footer
        receiptString += separator + lineBreak;
        receiptString += 'Subtotal: $' + subtotal.toFixed(2) + lineBreak;

        // Sales Tax (only for restaurant or if tax is not included for cannabis)
        if (basicInfo?.businessType === 'restaurant' || (basicInfo?.businessType === 'cannabis' && !this.taxIncluded)) {
            receiptString += 'Sales Tax (' + order?.tax + '%): $' + taxAmount.toFixed(2) + lineBreak;
        }

        // Excise Tax for cannabis businesses if taxIncluded is false
        if (basicInfo?.businessType === 'cannabis' && !this.taxIncluded && order?.exciseTax > 0) {
            receiptString += 'Excise Tax (' + order?.exciseTax + '%): $' + exciseTaxAmount.toFixed(2) + lineBreak;
        }

        // Show discount after taxes
        if (order?.discount > 0) {
            receiptString += 'Discount: - $' + order?.discount.toFixed(2) + lineBreak;
        }

        if (processingFee > 0) {
            receiptString += 'Tastio Fee: $' + processingFee.toFixed(2) + lineBreak;
        }

        // Total
        receiptString += 'Total: $' + order?.totalAmount?.toFixed(2) + lineBreak;

        // Add Gratuity Section ONLY if the order is unpaid
        //if (!order?.paid && order?.orderTypeModel?.type === 'Dine In') {
        //     const taxRate = order?.tax || 0;
        //     receiptString += this.getGratuitySectionString(subtotal, taxRate);
        // }

        // Add Payment Options for unpaid orders
        receiptString += this.getPaymentOptionsString(order);

        // Add Tastio Fee Note for Paid Orders
        if (processingFee > 0) {
            receiptString += separator + lineBreak;
            receiptString += 'Tastio Fee helps us operate Tastio & provide owners a free software solution with no card processing or monthly fees.' + lineBreak;
        }

        // Footer
        receiptString += separator + lineBreak;
        receiptString += 'Thank You' + lineBreak;
        receiptString += separator + lineBreak;

        return receiptString;
    }






    public createReceiptHTML(order: IOrderModel): string {
        console.log('--- createReceiptHTML Debugging ---');
        console.log('Order:', order);

        const separator = '************************************************************************************************************';
        let receiptString = '';

        // Fetch basicInfo
        if (!this.basicInfo) {
            this.getBasicInfo();
        }

        if (!this.basicInfo) {
            const basicInfoJSON = window.localStorage.getItem('basic-info');
            if (basicInfoJSON) {
                this.basicInfo = JSON.parse(basicInfoJSON);
            }
        }

        const basicInfo = this.basicInfo;
        console.log('Basic Info:', basicInfo);

        // Format the order date in MM-DD-YYYY HH:mm:ss format
        const orderDate = new Date(order?.orderDate);
        const formattedDate = `${(orderDate.getMonth() + 1).toString().padStart(2, '0')}-${orderDate.getDate().toString().padStart(2, '0')}-${orderDate.getFullYear()} ${orderDate.toLocaleTimeString('en-US')}`;

        // Online Order Invitation or Loyalty Points Section
        let onlineOrderSection = '';
        if (basicInfo?.customerPanelUrl) {
            if (order?.customer?.loyaltyPoints !== undefined) {
                onlineOrderSection = `
                `;
                // <div class="title-row">
                //     ${separator}
                //     <span>Loyalty & Online Ordering</span>
                //     ${separator}
                // </div>
                // <p style="text-align: center;">You've earned ${order?.customer?.loyaltyPoints} loyalty points!</p>
                // <p style="text-align: center;">Order your favorites online at: <strong>${basicInfo.customerPanelUrl}</strong></p>

                // <p style="text-align: center;">Use your mobile number as your username: xxx-xxx-${order.customer.mobileNumber.slice(-4)}</p>
                // <p style="text-align: center;">Exclusive deals and discounts await you! Don’t miss out.</p>

            } else {
                onlineOrderSection = `
                `;
                // <div class="title-row">
                //     ${separator}
                //     <span>Loyalty & Online Ordering</span>
                //     ${separator}
                // </div>
                // <p style="text-align: center;">Thanks for choosing us! You can also enjoy online ordering and join our loyalty program at: <strong>${basicInfo.customerPanelUrl}</strong></p>
                // <p style="text-align: center;">Save on your next visit with our online deals!</p>
            }
        }

        let receiptHeader = `
        <table>
        `;

        // Show discount and total before discount only if the discount is greater than 0
        if (order?.discount > 0) {
            receiptHeader += `
            <tr>
                <td>Total Before Discount:</td>
                <td align="right">$${(order?.totalAmount + order?.discount).toFixed(2)}</td>
            </tr>
            <tr>
                <td>Discount:</td>
                <td align="right">- $${order?.discount.toFixed(2)}</td>
            </tr>
            `;
        }

        receiptHeader += `
            <tr>
                <td>Total Amount:</td>
                <td align="right">$${order?.totalAmount?.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Order Date:</td>
                <td align="right">${formattedDate}</td>
            </tr>
            <tr>
                <td>Order Type:</td>
                <td align="right">${order?.orderTypeModel?.type}</td>
            </tr>
            <tr>
                <td>Payment:</td>
                <td align="right"><strong>${order?.paid ? 'Paid' : 'Un-Paid'}</strong></td>
            </tr>

            ${order?.changes > 0 ? `
                <tr>
                    <td>Changes:</td>
                    <td align="right">$${order?.changes?.toFixed(2)}</td>
                </tr>` : ''}

            ${order?.customer != null && basicInfo?.businessType === 'cannabis' ? `
                <tr>
                    <td>Name:</td>
                    <td align="right">${order?.customer?.firstName} ${order?.customer?.lastName}</td>
                </tr>` : ''}

        </table>
        `;

        // Order Items
        let orderDetails = order?.orderDetailsModelList?.map(item => {
            const itemTotalPrice = item.quantity * item.basePrice;

            // Build product name and barcode line
            let productLine = `${item.quantity} X <strong>${item.productName}</strong>`;

            // Add barcode ID for cannabis business type
            if (basicInfo?.businessType === 'cannabis' && item.productCannabisModel?.barcodeId) {
                productLine += ` <em>(${item.productCannabisModel?.barcodeId})</em>`;
            }

            // Align product line and price
            return `
            <tr>
                <td colspan="2" style="word-wrap: break-word; font-size: 11px;">
                    ${productLine}
                </td>
                <td align="right">$${itemTotalPrice.toFixed(2)}</td>
            </tr>
            ${this.getToppingsAndAddonsHTML(item)}
            `;
        }).join('');

        // Calculate subtotal and taxes
        let subtotal = 0;
        if (basicInfo?.businessType === 'cannabis') {
            subtotal = this._orderUtility.calculateCannabisCartTotalPrice(order?.orderDetailsModelList);
        } else {
            subtotal = this._orderUtility.calculateCartTotalPrice(order?.orderDetailsModelList);
        }
        console.log('Subtotal:', subtotal);

        const taxAmount = this.getTaxAmount(order);
        const exciseTaxAmount = this.getExciseTaxAmount(order);
        console.log('Tax Amount:', taxAmount);
        console.log('Excise Tax Amount:', exciseTaxAmount);

        // Processing Fee
        const processingFee =  order?.processingFee;
        // const processingFee = this.calculateProcessingFee(order?.paymentType, order?.totalAmount || 0);
        console.log('Processing Fee:', processingFee);

        // Footer for the receipt
        let orderFooter = `
        <table>
            <tr>
                <td>Subtotal:</td>
                <td align="right">$${subtotal.toFixed(2)}</td>
            </tr>
        `;

        // Sales Tax (only for restaurant or if tax is not included for cannabis)
        if (basicInfo?.businessType === 'restaurant' || (basicInfo?.businessType === 'cannabis' && !this.taxIncluded)) {
            orderFooter += `
            <tr>
                <td>Sales Tax (${order?.tax}%):</td>
                <td align="right">$${taxAmount.toFixed(2)}</td>
            </tr>
            `;
        }

        // Excise Tax for cannabis businesses if taxIncluded is false
        if (basicInfo?.businessType === 'cannabis' && !this.taxIncluded && order?.exciseTax > 0) {
            orderFooter += `
            <tr>
                <td>Excise Tax (${order?.exciseTax}%):</td>
                <td align="right">$${exciseTaxAmount.toFixed(2)}</td>
            </tr>
            `;
        }

        // Show discount after taxes
        if (order?.discount > 0) {
            orderFooter += `
            <tr>
                <td>Discount:</td>
                <td align="right">- $${order?.discount.toFixed(2)}</td>
            </tr>
            `;
        }

        if (processingFee > 0) {
            orderFooter += `
            <tr>
                <td>Tastio Fee:</td>
                <td align="right">$${processingFee.toFixed(2)}</td>
            </tr>
            `;
        }

        // Total
        orderFooter += `
            <tr>
                <td><strong>Total:</strong></td>
                <td align="right">$${order?.totalAmount?.toFixed(2)}</td>
            </tr>
        </table>
        `;

        // Add Gratuity Section ONLY if the order is unpaid
        // let gratuitySection = '';
        //if (!order?.paid && order?.orderTypeModel?.type === 'Dine In') {
        //     const taxRate = order?.tax || 0;
        //     gratuitySection = this.getGratuitySectionHTML(subtotal, taxRate);
        //     console.log('Gratuity Section:', gratuitySection);
        // }

        // Add Payment Options for unpaid orders
        const paymentOptionsHTML = this.getPaymentOptionsHTML(order);
        console.log('Payment Options HTML:', paymentOptionsHTML);

        // Add Tastio Fee Note for Paid Orders
        let tastioFeeNote = '';

        if (processingFee > 0) {
            tastioFeeNote = `
            <div class="title-row">
                ${separator}
                <p><strong>Tastio Fee</strong> helps us operate Tastio & provide owners a free software solution with no card processing or monthly fees.</p>
            </div>
            `;
        }

        // Complete receipt HTML
        return `
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <title>Receipt</title>
            <style>
                body { font-family: Arial, sans-serif; font-size: 12px; text-align: center; margin: 0; padding: 0; }
                .container { width: 280px; margin: 0 auto; text-align: left; }
                .header { font-size: 18px; font-weight: bold; margin: 10px 0; }
                .separator { margin: 10px 0; font-size: 14px; color: #999; }
                table { width: 100%; border-collapse: collapse; margin-bottom: 10px; }
                th, td { padding: 6px 10px; border-bottom: 1px solid #ddd; }
                th { background-color: #f2f2f2; font-weight: bold; }
                td.description { width: 60%; }
                td.price, td.right { width: 40%; text-align: right; }
                .total { font-weight: bold; }
                .footer { margin-top: 20px; }
                .title-row { max-width: 100%; overflow: hidden; text-align: center; }
                .title-row span { display: block; margin-bottom: 6px; }
                .main-heading { text-align: center; }
                @media print { .no-print { display: none !important; } body { font-size: 10px; } .container { width: 100%; } }
            </style>
        </head>
        <body>
            <div class="container">
                <h2 class="main-heading">${basicInfo?.restaurantName}</h2>
                ${onlineOrderSection}
                <div class="title-row">
                    ${separator}
                    <span>Receipt</span>
                    ${separator}
                </div>
                ${receiptHeader}
                <div class="title-row">
                    ${separator}
                    <span>Order Details</span>
                    ${separator}
                </div>
                <table>${orderDetails}</table>
                <div class="title-row">
                    ${separator}
                </div>
                ${orderFooter}
                ${paymentOptionsHTML}
                ${tastioFeeNote}
                <br><br>
                <div class="title-row">
                    ${separator}
                    <span>Thank You</span>
                </div>
            </div>
        </body>
        </html>
        `;
    }







    /**
     * get toppings and addon html
     * @param orderDetails
     * @returns
     */
     private getToppingsAndAddonsHTML(orderDetails: IOrderDetailsModel): string {
         // Filter out variants with zero price
         let variants = orderDetails.orderDetailsVariantModels
             .filter(v => v.variantPrice > 0) // Only include variants with price > 0
             .map(v => {
                 let variantTotalPrice = v.variantPrice * orderDetails.quantity;
                 let variantPriceHTML = ` +$${variantTotalPrice.toFixed(2)}`;

                 return `
                 <tr>
                     <td style="max-width:180px; word-wrap:break-word;">- ${v.childVariantName}</td>
                     <td align="right">${variantPriceHTML}</td>
                 </tr>
                 `;
             }).join('');

         // Filter out addons with zero price
         let addons = orderDetails.orderDetailsAddOnModelList
             .filter(a => a.addonPrice > 0) // Only include addons with price > 0
             .map(a => {
                 let addonTotalPrice = a.addonPrice * orderDetails.quantity;
                 let addonPriceHTML = ` +$${addonTotalPrice.toFixed(2)}`;

                 return `
                 <tr>
                     <td style="max-width:180px; word-wrap:break-word;">- ${a.addonName}</td>
                     <td align="right">${addonPriceHTML}</td>
                 </tr>
                 `;
             }).join('');

         return variants + addons;
     }



    /**
     * get gratuity and signature html
     * @returns
     */
    private getGratuityAndSignatureHTML(subtotal: number): string {

        // Define the gratuity percentages in an array
        const percentages = [15, 18, 20];

        let gratuityAndSignature = `
            <div class="title-row">
                ************************************************************************************************************************************************************************************************************************************************
                <span>Suggested Gratuity</span>
                ************************************************************************************************************************************************************************************************************************************************
            </div>
            <table class="gratuity-table">
                <tbody>
        `;

        // Loop through the percentages array
        for (const percent of percentages) {
            // Calculate the gratuity amount
            const gratuityAmount = (subtotal * percent) / 100;
            // Calculate the total including the subtotal and gratuity
            const total = subtotal + gratuityAmount;

            // Add a row for the current percentage and its calculated amounts
            gratuityAndSignature += `
                <tr>
                    <td>[ &nbsp; ] ${percent}% - $${gratuityAmount.toFixed(2)}</td>
                    <td>
                        <table class="gtotal">
                            <tbody>
                                <tr>
                                    <td>Total: </td>
                                    <td align="right">$${total.toFixed(2)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            `;
        }

        // Close the table and add signature section
        gratuityAndSignature += `
                <tr>
                    <td>
                        <div class="flex">
                            [ &nbsp; ] $<div class="blank-space" style="margin-right: 15px;"></div>
                        </div>
                    </td>
                    <td>
                        <div class="flex">
                            Total:$<div class="blank-space"></div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
            <br><br>
            <table class="signature-table">
                <tbody>
                    <tr>
                        <td align="center" colspan="2">Signature</td>
                    </tr>
                </tbody>
            </table>
            <br><br>
        `;



          return gratuityAndSignature;
      }

      private getToppingsAndAddonsString(orderDetails) {
          console.log(orderDetails);
          let detailsString = '';

          // Add variants to the details string
          orderDetails.orderDetailsVariantModels
              .filter(v => v.variantPrice > 0) // Only include variants with price > 0
              .forEach(v => {
                  let variantTotalPrice = v.variantPrice * orderDetails.quantity;
                  let variantPriceString = ` +$${variantTotalPrice.toFixed(2)}`;
                  detailsString += `- ${v.childVariantName}${variantPriceString}\n`;
              });

          // Add add-ons to the details string and multiply price by quantity
          orderDetails.orderDetailsAddOnModelList
              .filter(a => a.addonPrice > 0) // Only include addons with price > 0
              .forEach(a => {
                  let addonTotalPrice = a.addonPrice * orderDetails.quantity; // Multiply add-on price by quantity
                  let addonPriceString = ` +$${addonTotalPrice.toFixed(2)}`;
                  detailsString += `- ${a.addonName}${addonPriceString}\n`;
              });

          return detailsString;
      }



private getGratuityAndSignatureString(subtotal) {
    const percentages = [15, 18, 20];
    let gratuityString = '\nSuggested Gratuity\n' + '------------------\n';

    percentages.forEach(percent => {
        const gratuityAmount = (subtotal * percent) / 100;
        const total = subtotal + gratuityAmount;
        gratuityString += `[ ] ${percent}% - $${gratuityAmount.toFixed(2)} | Total: $${total.toFixed(2)}\n`;
    });

    gratuityString += '\n[ ] $___________\n\nTotal: $___________\n\nSignature:\n___________\n\n';

    return gratuityString;
}

/**
 * Generate the gratuity section in HTML
 * @param subTotal
 * @param taxRate
 * @returns
 */
private getGratuitySectionHTML(subTotal: number, taxRate: number): string {
  // Define the tip options (excluding 'Other' since it's custom)
  const tipOptions = this.tips.filter(tip => typeof tip.tip === 'number');

  // Build the gratuity options
  let gratuityOptionsHTML = '';

  tipOptions.forEach(option => {
    const tipAmount = this.calculatePercentageTip(option.tip, subTotal);
    const totalWithTip = subTotal + tipAmount + (subTotal + tipAmount) * taxRate / 100;
    gratuityOptionsHTML += `
      <tr>
        <td>[ &nbsp; ] ${option.tip}% - $${tipAmount.toFixed(2)}</td>
        <td align="right">Total: $${totalWithTip.toFixed(2)}</td>
      </tr>
    `;
  });

  // Commented out the Rounding Option
  // const roundingTip = this.calculateRoundingTip(subTotal, taxRate);
  // const totalWithRoundingTip = subTotal + roundingTip + (subTotal + roundingTip) * taxRate / 100;

  // gratuityOptionsHTML += `
  //   <tr>
  //     <td>[ &nbsp; ] Rounding - $${roundingTip.toFixed(2)}</td>
  //     <td align="right">Total: $${totalWithRoundingTip.toFixed(2)}</td>
  //   </tr>
  // `;

  // Build the gratuity section HTML with the note
  return `
    <div class="title-row">
      ${'*'.repeat(100)}
      <span>Suggested Gratuity</span>
      ${'*'.repeat(100)}
    </div>
    <table>
      ${gratuityOptionsHTML}
      <tr>
        <td>[ &nbsp; ] Other - $___________</td>
        <td align="right">Total: $___________</td>
      </tr>
      <tr>
        <td colspan="2" style="text-align: center; font-size: 12px; color: #555;">
          <em>Note: Totals shown above do not include the Tastio Fee for card payments. These are cash amounts with tips.</em>
        </td>
      </tr>
    </table>
  `;
}


/**
 * Generate the gratuity section in plain text
 * @param subTotal
 * @param taxRate
 * @returns
 */
private getGratuitySectionString(subTotal: number, taxRate: number): string {
  let gratuityString = '\nSuggested Gratuity\n' + '------------------\n';

  // Tip options (excluding 'Other')
  const tipOptions = this.tips.filter(tip => typeof tip.tip === 'number');

  tipOptions.forEach(option => {
    const tipAmount = this.calculatePercentageTip(option.tip, subTotal);
    const totalWithTip = subTotal + tipAmount + (subTotal + tipAmount) * taxRate / 100;
    gratuityString += `[ ] ${option.tip}% - $${tipAmount.toFixed(2)} | Total: $${totalWithTip.toFixed(2)}\n`;
  });

  // Commented out the Rounding Tip
  // const roundingTip = this.calculateRoundingTip(subTotal, taxRate);
  // const totalWithRoundingTip = subTotal + roundingTip + (subTotal + roundingTip) * taxRate / 100;
  // gratuityString += `[ ] Rounding - $${roundingTip.toFixed(2)} | Total: $${totalWithRoundingTip.toFixed(2)}\n`;

  gratuityString += `[ ] Other - $___________ | Total: $___________\n`;

  // Add the note
  gratuityString += '\nNote: Totals shown above do not include the Tastio Fee for card payments. These are cash amounts with tips.\n';

  return gratuityString;
}



printPreview(content:string){
     ///////////////////////////////////////////
    //// Instant Preview (Reciept) [Starts] ///
    ///////////////////////////////////////////
    // console.log('content: ',content)
    const width = 300; // Width in pixels
    const height = 400; // Height in pixels

    // // Open a new popup window
    const printWindow = window.open('', '_blank', `width=${width},height=${height}`);

    if (printWindow) {
      // Write the HTML content to the popup window
      printWindow.document.open();
      printWindow.document.write(content);

    }
    // return

    ///////////////////////////////////////////
    //// Instant Preview (Reciept) [Ends] /////
    ///////////////////////////////////////////
}



}
