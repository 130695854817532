import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';
import { IBiotrackInventory } from 'src/app/appModels/IBiotrackInventory';
import { IStore } from 'src/app/appModels/IStore';
import { StoreResolver } from 'src/app/appResolvers/store.resolver';
import { BiotrackService } from 'src/app/appServices/biotrack/biotrack.service';
import { CannabisSettingsService } from 'src/app/appServices/biotrack/cannabis-settings.service';
import { UserService } from 'src/app/appServices/user/user.service';
import { TextCustomerModalComponent } from './text-customer-modal/text-customer-modal.component';
import { ToastMessageService } from 'src/app/core/utilities/toast-message.service';
import { IUser } from 'src/app/appModels/IUser';

@Component({
  selector: 'app-recall-feature',
  templateUrl: './recall-feature.component.html',
  styleUrls: ['./recall-feature.component.scss'],
})
export class RecallFeatureComponent extends HandleSubscriptionsComponent implements OnInit {
  inventoryItems: any[] = [];
  filteredItems: any[] = [];
  selectedInventory: any[] = [];
  customers: IUser[] = [];
  searchTerm: string = '';
  savedLocationId: string;
  isLoadingInventory: boolean = false;  // Separate loading state for inventory
  isLoadingCustomers: boolean = false;  // Separate loading state for customers

  @Input() data: any;
  store: IStore;

  constructor(
    private modalCtrl: ModalController,
    private biotrackService: BiotrackService,
    private _storeResolver: StoreResolver,
    private cannabisSettingsService: CannabisSettingsService,
    private userService: UserService,
    private toastMessage: ToastMessageService
  ) {
    super();
  }

  ngOnInit() {
    this.isLoadingInventory = true;
    this.checkSelectedInventory();
    this.subscribeSelectedStore();
  }

  subscribeSelectedStore() {
    this._storeResolver.selectedStore.subscribe(res => {
      if (res) {
        this.store = res;
        this.getCannabisLocationId();
      }
    });
  }

  getCannabisLocationId(): void {
    this.cannabisSettingsService.getSettings(this.store.id).subscribe((settings) => {
      if (settings) {
        this.savedLocationId = settings.find((setting) => setting.name === 'licenseNumber').value;
        this.getInventoryItems();
      }
    });
  }

  getInventoryItems() {
    this.handleSubscription(
      this.biotrackService.getBiotrackInventory(this.store.id),
      (res: IBiotrackInventory) => {
        if (res) {
          this.inventoryItems = res.inventory.filter(item => parseFloat(item.remaining_quantity) > 0 && item.location === this.savedLocationId);
          this.filteredItems = this.inventoryItems;
          this.isLoadingInventory = false;  // Stop inventory loading state
        }
      },
      (err) => {
        this.toastMessage.errorToastWithClose('Error loading inventory items.');
        this.isLoadingInventory = false;  // Stop inventory loading state if there's an error
      }
    );
  }

  filterItems() {
    const searchTerm = this.searchTerm.toLowerCase();
    this.filteredItems = this.inventoryItems.filter(item => {
      const productName = item.productname ? item.productname.toLowerCase() : '';
      const id = item.id ? item.id.toLowerCase() : '';
      return productName.includes(searchTerm) || id.includes(searchTerm);
    });
  }

  checkSelectedInventory() {
    if (this.data) this.selectedInventory = [...this.data];
  }

  selectionChange(option: any) {
    const optionSelected = this.checkInventory(option.id);
    if (!optionSelected) this.selectedInventory.push(option);
    else this.selectedInventory = this.selectedInventory.filter(x => x.id !== option.id);
  }

  checkInventory(id: string) {
    return this.selectedInventory.findIndex(e => e.id === id) >= 0;
  }

  onSubmit() {
    if (this.selectedInventory.length > 0) {
      const barcodeSearchRequest = {
        storeId: this.store.id,
        barcodeIds: this.selectedInventory.map(item => item.id)
      };
      this.fetchCustomersByBarcodeIds(barcodeSearchRequest);
    } else {
      this.toastMessage.errorToastWithClose('No items selected!');
    }
  }

  fetchCustomersByBarcodeIds(barcodeSearchRequest: { storeId: number, barcodeIds: string[] }) {
    this.isLoadingCustomers = true;  // Start customer loading state
    this.userService.getUsersByStoreBarcodeTenant(barcodeSearchRequest).subscribe(
      (data: IUser[]) => {
        if (this.isErrorResponse(data)) {
          this.toastMessage.errorToastWithClose('Error: No purchase found with these barcode IDs.');
        } else {
          this.customers = data.filter(customer => customer.mobileNumber || customer.emailId);
        }
        this.isLoadingCustomers = false;  // Stop customer loading state
      },
      (err) => {
        console.log(err);
        this.toastMessage.errorToastWithClose('Error fetching customers.');
        this.isLoadingCustomers = false;  // Stop customer loading state if there's an error
      }
    );
  }

  private isErrorResponse(data: any): boolean {
    return data.length > 0 && data[0].firstName === 'No purchase found with this barcode ID.';
  }

  openTextCustomerModal() {
    this.modalCtrl.create({
      component: TextCustomerModalComponent,
      componentProps: {
        customers: this.customers
      }
    }).then(modal => {
      modal.present();
    });
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
