import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';
import { HttpConfigService } from '../core/httpConfig/httpConfigService';

@Injectable({
  providedIn: 'root'
})
export class CashMgmtService {

  api = this.apiConfig.getBaseUrl;
  intialCash = new BehaviorSubject<any>(null);
  private instantDepositConfigSubject = new BehaviorSubject<any>(null);
  private TermsAndConditionConfigSubject = new BehaviorSubject<any>(null);

  constructor(private apiConfig: HttpConfigService) {
  }
  

  /**
   * Get intial cash
   */
  getIntialCash(storeId:number) {
    this.apiConfig.get(`${this.api}configuration/getByParameter/initialCash/${storeId}`).subscribe(res=>{
      this.intialCash.next(res);
    });
  }


  /**
   * Get configuration
   * @returns 
   */
  getConfiguration(type:string): Observable<any> {
    return this.apiConfig.get(`${this.api}configuration/getByParameter/${type}`);
  }

  /**
   * Get configuration by store id
   * @returns 
   */
  getConfigurationByStore(type:string, storeId:number): Observable<any> {
    return this.apiConfig.get(`${this.api}configuration/getByParameter/${type}/${storeId}`);
  }


  /**
   * Update inital cash
   * @param payload 
   * @returns 
   */
  updateConfiguration(payload:any): Observable<any> {
    return this.apiConfig.put(`${this.api}configuration`, payload, { withCredentials: true });
  }


  /**
   * Get instant deposit config
   * @param type 
   * @returns 
   */
  getInstantDepositConfig(storeId: number): Observable<any> {
    const currentValue = this.instantDepositConfigSubject.value;
    
    if (currentValue) {
      return of(currentValue);
    } else {
      return this.getConfigurationByStore('instantDeposit', storeId).pipe(
        tap((config) => this.instantDepositConfigSubject.next(config))
      );
    }
  }


  /**
   * Update instant deposit config
   * @param config 
   */
  updateInstantDepositConfig(config: any): void {
    this.instantDepositConfigSubject.next(config);
  }


  /**
   * Get terms and condition config
   * @param storeId 
   * @returns 
   */
  getTermsAndConditionConfig(storeId: number): Observable<any> {
    const currentValue = this.TermsAndConditionConfigSubject.value;
    
    if (currentValue) {
      return of(currentValue);
    } else {
      return this.apiConfig.get(`${this.api}configuration/getByParameter/terms_and_conditions/${storeId}`).pipe(
        tap((config) => this.TermsAndConditionConfigSubject.next(config))
      );
    }
  }

  /**
   * Update terms and condition config
   * @param config 
   */
  updateTermsAndConditionConfig(config: any): void {
    this.TermsAndConditionConfigSubject.next(config);
  }




  /**
   * Shift end
   * @param payload 
   * @returns 
   */
  shiftEnd(payload:any): Observable<any> {
    return this.apiConfig.put(`${this.api}user/cashier/shiftEnd`, {
      cashierId: payload.cashierId,
      countCash: payload.countCash
    }, { withCredentials: true });
  }


  /**
   * Get loyalty points
   * @returns 
   */
  getloyaltyPoints(): Observable<any> {
    return this.apiConfig.get(`${this.api}configuration/getByParameter/pointsPerDoller`);
  }

  



}
