import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpConfigService } from '../core/httpConfig/httpConfigService';

@Injectable({
  providedIn: 'root'
})
export class RestaurantInfoService {
  api = this.apiConfig.getBaseUrl + "account-settings";

  constructor(private apiConfig: HttpConfigService) {}

  getBasicInfo(): Observable<any> {
    return this.apiConfig.get(this.api);
  }

  updateCardTermsAndConditions(accepted: boolean): Observable<any> {
    const url = `${this.api}/card-terms-and-conditions/${accepted}`;
    return this.apiConfig.patch(url);
  }
}
