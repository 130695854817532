import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SetLoyaltyPointsComponent } from './set-loyalty-points.component';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { DesignUtilitiesModule } from 'src/app/appModules/design-utilities.module';

@NgModule({
  declarations: [SetLoyaltyPointsComponent],
  imports: [IonicModule, CommonModule, ReactiveFormsModule, DesignUtilitiesModule],
  exports: [SetLoyaltyPointsComponent],
})
export class SetLoyaltyPointsModule {}
