import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';
import { DesignUtilityServices } from '../../utilities/design-utility.service';
import { ToastMessageService } from '../../utilities/toast-message.service';
import { TenantService } from 'src/app/appServices/tenant/tenant.service';

@Component({
  selector: 'app-manage-alias',
  templateUrl: './manage-alias.component.html',
  styleUrls: ['./manage-alias.component.scss'],
})
export class ManageAliasComponent extends HandleSubscriptionsComponent implements OnInit {

  loading: boolean = false;
  Form: UntypedFormGroup;
  validator = this._du.validator;

  constructor(
    private modalCtrl: ModalController,
    private _du: DesignUtilityServices,
    private toaster: ToastMessageService,
    private tenantService: TenantService,
    private _fb: UntypedFormBuilder
  ) {
    super();
  }


  ngOnInit() {
    this.initForm();
    this.getAlias();
  }

  /**
   * Check initial cash
   */
  getAlias(){
    this.handleSubscription(this.tenantService.getAlias(),
    (res) => {
      if(res){
        this.Form.get('alias').patchValue(res.alias);
        }
      },
      (err) => {
        this.toaster.errorToastWithClose('Error: '+ err);
      }
    );
  }


  /**
   * init
   */
  initForm() {
    this.Form = this._fb.group({
      alias: ['', [Validators.required]],
    });
  }

  /**
   * on Submit
   */
  onSubmit() {
    this.loading = true;
    if (this.Form.invalid) {
      return;
    }

    this.handleSubscription(this.tenantService.createAlias(this.Form.value.alias),
      (res) => { 
        this.toaster.successToastWithClose('Alias updated successfully!');
        this.loading = false;
        this.closeModal('success');
      },
      (err) => {
        this.toaster.errorToastWithClose('Error: '+ err);
        this.loading = false;
        this.closeModal('error');
      }
    );

  }



  closeModal(action?:string) {
    this.modalCtrl.dismiss(action || 'closed');
  }


}
