import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';
import { IProfile } from 'src/app/appModels/IProfile';
import { IStore } from 'src/app/appModels/IStore';
import { StoreResolver } from 'src/app/appResolvers/store.resolver';
import { GlobalAuthResolver } from 'src/app/appServices/global-auth/global-auth.resolver';
import { PaymentGatewayService } from 'src/app/appServices/payment-gateway.service';
import { ToastMessageService } from 'src/app/core/utilities/toast-message.service';
import { AddAeropayAccountComponent } from './add-aeropay-account/add-aeropay-account.component';

@Component({
  selector: 'app-aeropay-account-modal',
  templateUrl: './aeropay-account-modal.component.html',
  styleUrls: ['./aeropay-account-modal.component.scss'],
})
export class AeropayAccountModalComponent extends HandleSubscriptionsComponent implements OnInit {
  email: string;
  accounts: any[];
  loading: boolean = false;
  selectedStore: IStore;
  profile: IProfile;

  @Input() selectedAeropayAccount: number;
  
  constructor(
    private modalCtrl: ModalController,
    private _storeResolver: StoreResolver,
    private _globalAuthResolver: GlobalAuthResolver,
    private paymentGatewayService: PaymentGatewayService,
    private toasterService: ToastMessageService,

  ) { 
    super();
  }

  ngOnInit() {
    this.subscribeSelectedStore();
    this.getProfile();
  }
  

  /**
   * Get Profile
   */
  getProfile() {
    this.handleSubscription(this._globalAuthResolver.profile, 
    (res: IProfile) => {
      if (res) {
        this.profile = res;
        this.getAllAccounts(this.profile.id)
      }
    })
  }


  
  /**
   * Get All Accounts
   */
  getAllAccounts(tenantId:number) {
    this.handleSubscription(this.paymentGatewayService.getAllAeropayAccounts(tenantId), 
      (res) =>{
        this.accounts = res;
      });
  }



  /**
  * subscribe selected store
  */
   subscribeSelectedStore() {
    this.handleSubscription(this._storeResolver.selectedStore, 
    (res) => {
      if (res) {
        this.selectedStore= res;
      }
    });
  }


  
   



  async addNewAccount() {
    const modal = await this.modalCtrl.create({
      component: AddAeropayAccountComponent,
      cssClass: 'rounded-modal sml',
      backdropDismiss: false,
      componentProps: {
        profile: this.profile,
      },
    });
    await modal.present();
    const { role, data } = await modal.onWillDismiss();
    if (data=='ACCOUNT_ADDED') {
      this.getAllAccounts(this.profile.id);
    }

  }





  mapToStore(accountId){
    this.handleSubscription(this._storeResolver.mapAeropayAccountWithStore(this.selectedStore.id, accountId), 
    (res) =>{
      this.modalCtrl.dismiss(accountId);
    })
  }

     


  onClose() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  /**
   * Destroy Component
   */
   ngOnDestroy(): void {
  }

}
