import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TenantResolver {
  
  /**
   * set tenant name
   * @param name 
   */
  setTenantName(name: string) {
    localStorage.setItem('tenant', name);
  }

  /**
   * get domain name
   * @returns 
   */
  getTenantName(): string {
    return localStorage.getItem('tenant');
  }

  clearTenantName() {
    localStorage.removeItem('tenant');
  }
  

}
