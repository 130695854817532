import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpConfigService } from '../core/httpConfig/httpConfigService';

@Injectable({
  providedIn: 'root'
})
export class StripePaymentService {
  api = this.apiConfig.getBaseUrl + "payment-gateway/client/stripe";
  constructor(
    private apiConfig: HttpConfigService
  ) { }

  /**
   * Get client secret
   * @param stripeData 
   * @param storeId 
   * @returns 
   */
  getClientSecret(stripeData, storeId): Observable<any> {
    return this.apiConfig.post(this.api + '/client_secret/' + storeId, {
      'totalAmount': stripeData.amount,
      'email': stripeData.email,
      'platformFee': stripeData.platformFee
    }, { withCredentials: true });
  }

  /**
   * Get client secret for platform
   * @param stripeData 
   * @param storeId 
   * @returns 
   */
  getClientSecretForPlatform(stripeData, storeId): Observable<any> {
    return this.apiConfig.post(this.api + '/client_secret/' + storeId +'/platform', {
      'totalAmount': stripeData.amount,
      'email': stripeData.email,
      'platformFee': stripeData.platformFee
    }, { withCredentials: true });
  }

  sendPaymentIntent(paymentIntent): Observable<any> {
    return this.apiConfig.post(this.api + '/capture_payment_intent', paymentIntent, { withCredentials: true });
  }


  /**
   * get all locations
   * @returns 
   */
  getAllLocations(storeId: number): Observable<any> {
    return this.apiConfig.get(this.api + '/location/' + storeId, { withCredentials: true });
  }

  /**
   * create locations
   * @param location 
   * @returns 
   */
  createLocation(location: string, storeId: number): Observable<any> {
    return this.apiConfig.post(this.api + '/location/' + storeId, location, { withCredentials: true });
  }

  registerDevice(location: string, device: string, storeId: number): Observable<any> {
    return this.apiConfig.get(this.api + '/register-reader/' + location + '/' + device + '/' + storeId);
  }
}
