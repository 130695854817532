import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PaymentGatewayService } from 'src/app/appServices/payment-gateway.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentGatewayResolver {
  paymentMethods = new BehaviorSubject<any[]>(null);

  constructor(
    private paymentGatewayService: PaymentGatewayService
  ) {}


  /**
   * get all accounts
   * @returns 
   */
  getAllAccounts(): Observable<any>{
    return this.paymentGatewayService.getAllAccounts();
  }


  /**
   * get all accounts
   * @returns 
   */
  getAllRethinkPayAccounts(): Observable<any>{
    return this.paymentGatewayService.getAllRethinkPayAccounts();
  }


  /**
   * Get Dashboard Url
   * @param stripeAccountId 
   */
  getDashboardUrl(stripeAccountId:string): Observable<any> {
    return this.paymentGatewayService.getDashboardUrl(stripeAccountId);
  }
  
  
  getAccountLink(stripeAccountId:string): Observable<any>{
    return this.paymentGatewayService.getAccountLink(stripeAccountId);
  }
  
  

  setupStripPayment(stripeKey, publicKey): Observable<any>{
    return this.paymentGatewayService.setupStripPayment(stripeKey, publicKey);
  }

  
  accountStatus(stripeAccountId:string): Observable<any>{
    return this.paymentGatewayService.accountStatus(stripeAccountId);
  }

  /**
   * get Payment Methods
   */
  getPaymentMethods(storeId: number) {
    this.paymentGatewayService.getAllPaymentGateways(storeId).subscribe((res) => {
      res && this.paymentMethods.next(res);
    });
  }


  /**
   * add new rethink account
   * @param email
   * @returns 
   */
  addRethinkPaymentAccount(payload): Observable<any>{
    return this.paymentGatewayService.addRethinkPaymentAccount(payload);
  }


  /**
   * Instant deposit daily schedular
   * @param storeId \
   * @returns 
   */
  instantDepositDailySchedular(storeId:number): Observable<any>{
    return this.paymentGatewayService.instantDepositDailySchedular(storeId);
  }

}
