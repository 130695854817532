import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';
import { DesignUtilityServices } from '../../utilities/design-utility.service';
import { CashMgmtService } from 'src/app/appServices/cash-mgmt.service';
import { ToastMessageService } from '../../utilities/toast-message.service';
import { IBasicInfo } from 'src/app/appModels/IBasicInfo';
import { RestaurantInfoResolver } from 'src/app/appResolvers/restaurant-info.resolver';

@Component({
  selector: 'app-set-loyalty-points',
  templateUrl: './set-loyalty-points.component.html',
  styleUrls: ['./set-loyalty-points.component.scss'],
})
export class SetLoyaltyPointsComponent extends HandleSubscriptionsComponent implements OnInit {

  loading: boolean = false;
  Form: UntypedFormGroup;
  validator = this._du.validator;
  basicInfo: IBasicInfo;

  constructor(
    private modalCtrl: ModalController,
    private _du: DesignUtilityServices,
    private toaster: ToastMessageService,
    private cashMgmtService: CashMgmtService,
    private _basicInfoResolver: RestaurantInfoResolver,
    private _fb: UntypedFormBuilder
  ) {
    super();
  }


  ngOnInit() {
    this.initForm();
    this.getloyaltyPoints();
    this.subscribeBasicInfo();
  }

  subscribeBasicInfo() {
    this._basicInfoResolver.restaurantBasicInfo.subscribe(res => {
      this.basicInfo = res;
    });
  }

  /**
   * Get loyalty points
   */
  getloyaltyPoints(){
    this.handleSubscription(this.cashMgmtService.getloyaltyPoints(),
      (res) => {
        // res.value = +res.value;
        this.Form.patchValue(res);
      },
      (err) => {
        this.toaster.errorToastWithClose('Error: '+ err);
      }
    );
  }

  /**
   * init
   */
  initForm() {
    this.Form = this._fb.group({
      id: ['', Validators.required],
      parameter: ['', Validators.required],
      value: ['', [Validators.required, Validators.min(0)]],
    });
  }

  /**
   * on Submit
   */
  onSubmit() {
    this.loading = true;
    if (this.Form.invalid) {
      return;
    }

    this.handleSubscription(this.cashMgmtService.updateConfiguration(this.Form.value),
      (res) => {
        this.toaster.successToastWithClose('Loyalty points set successfully!');
        this.loading = false;
        this.closeModal('success');
      },
      (err) => {
        this.toaster.errorToastWithClose('Error: '+ err);
        this.loading = false;
        this.closeModal('error');
      }
    );

  }



  closeModal(action?:string) {
    this.modalCtrl.dismiss(action || 'closed');
  }


}
