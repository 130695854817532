

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class NumberUtility {

    /**
     * get current epoc time
     * @returns 
     */
    public roundToTwo(numStr: any): number {
        const num = parseFloat(numStr);
        if (Number.isInteger(num)) {
          // If num is an integer, return it as is
          return num;
        } else {
          // If num has decimal places, round it to two decimal places
          return Math.round((num + Number.EPSILON) * 100) / 100;
        }
    }
    // public roundToTwo2(num: number): number {
    //     return Math.round((num + Number.EPSILON) * 100) / 100;
    // }
}