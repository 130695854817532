import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PricingPlansComponent } from './pricing-plans.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { DesignUtilitiesModule } from 'src/app/appModules/design-utilities.module';
import { NgxStripeModule } from 'ngx-stripe';
import { StripeCardPaymentComponent } from './stripe-card-payment/stripe-card-payment.component';

@NgModule({
  declarations: [
    PricingPlansComponent,
    StripeCardPaymentComponent
  ],
  imports: [
    IonicModule, 
    CommonModule, 
    FormsModule, 
    DesignUtilitiesModule,
    NgxStripeModule
  ],
  exports: [PricingPlansComponent],
})
export class PricingPlansModule {}
