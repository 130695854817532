import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface RefreshTokenResponse {
  token: string;
}

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private myTokenCache: Observable<RefreshTokenResponse>;
  private myToken: string;
  constructor() {}

  /**
   * create token header
   * @returns
   */
  public createTokenHeader(): HttpHeaders {
    let reqOptions = new HttpHeaders().set('Content-Type', 'application/json');
    this.token = localStorage.getItem('token');
    if (this.token) {
      reqOptions = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', `Bearer ${this.token}`);
    }
    return reqOptions;
  }

  get tokenStream(): Observable<string> {
    return this.myTokenCache.pipe(map((response) => response.token));
  }

  get token(): string {
    return this.myToken;
  }

  set token(token: string) {
    this.myToken = token;
  }

  public setToken(token) {
    this.setTokenToTokenService(token);
    this.setTokenToCookies(token);
  }

  /**
   * set token to cookies
   * @param token
   */
  private setTokenToCookies(token) {
    localStorage.setItem('token', token);
  }

  /**
   * set token to token Service
   * @param token
   */
  private setTokenToTokenService(token) {
    this.token = token;
  }

  /**
   * clear token
   */
  public clearToken() {
    localStorage.removeItem('token');
  }
}
