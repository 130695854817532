import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';
import { IStore } from 'src/app/appModels/IStore';
import { PaymentGatewayResolver } from 'src/app/appResolvers/payment-gateway.resolver';
import { StoreResolver } from 'src/app/appResolvers/store.resolver';
import { ToastMessageService } from 'src/app/core/utilities/toast-message.service';

@Component({
  selector: 'app-stripe-account-modal',
  templateUrl: './stripe-account-modal.component.html',
  styleUrls: ['./stripe-account-modal.component.scss'],
})
export class StripeAccountModalComponent extends HandleSubscriptionsComponent implements OnInit {
  stripeKey: string;
  publicKey: string;
  adding:boolean = false;
  accounts: any[];
  loading: boolean = false;
  selectedStore: IStore;
  getAllAccountsSub:Subscription;

  @Input() selectedStripeAccount: number;
  
  constructor(
    private modalCtrl: ModalController,
    private _storeResolver: StoreResolver,
    private _paymentGatewayResolver: PaymentGatewayResolver

  ) { 
    super();
  }

  ngOnInit() {
    this.subscribeSelectedStore();
    this.getAllAccounts();

  }
  

  /**
  * subscribe selected store
  */
   subscribeSelectedStore() {
    this.handleSubscription(this._storeResolver.selectedStore, 
    (res) => {
      if (res) {
        this.selectedStore= res;
      }
    });
  }

 

  /**
   * Get All Accounts
   */
  getAllAccounts() {
    this.getAllAccountsSub = this._paymentGatewayResolver.getAllAccounts().subscribe(res => {
      console.log('stripe account ',res)
      this.accounts = res;
    })
  }

 
  addNewAccount() {
    this.loading = true;
    this.handleSubscription(this._paymentGatewayResolver.setupStripPayment(this.stripeKey, this.publicKey), 
    (res) => {
      this.getAllAccounts();
      this.adding = false;
      this.loading = false;
      // this.redirectToStripe(res?.onboardUrl);
    });
  }



  redirectToStripe(url) {
    var popup = window.open(url, "_blank");
    setTimeout(function () {
      if (!popup || popup.outerHeight === 0) {
        //First Checking Condition Works For IE & Firefox
        //Second Checking Condition Works For Chrome
        alert("Click OK to setup strip account...");
        window.location.href = url;
      } else {
        //Popup Blocker Is Disabled
        window.open(url, '_blank');
        window.close();
      }
    }, 25);
  }


  mapToStore(stripeAccountId){
    this.handleSubscription(this._storeResolver.mapAccountWithStore(this.selectedStore.id, stripeAccountId), 
    (res) =>{
      this.modalCtrl.dismiss(stripeAccountId);
    })
  }


  onClose() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  /**
   * Destroy Component
   */
   ngOnDestroy(): void {
    this.getAllAccountsSub.unsubscribe();
  }

}
