import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RestaurantInfoResolver } from 'src/app/appResolvers/restaurant-info.resolver';
import { ToastMessageService } from 'src/app/core/utilities/toast-message.service';
import { ModalController } from '@ionic/angular';
import { OrderService } from 'src/app/appServices/order.service';
import { CartResolver } from 'src/app/appResolvers/cart.resolver';
import { environment } from 'src/environments/environment';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';
import { IStore } from 'src/app/appModels/IStore';
import { StoreResolver } from 'src/app/appResolvers/store.resolver';
import { NumberUtility } from 'src/app/appUtilities/number.utility';

declare global {
  interface Window {
    Square: any;
  }
}

@Component({
  selector: 'app-square-manual-card',
  templateUrl: './square-manual-card.component.html',
  styleUrls: ['./square-manual-card.component.scss'],
})
export class SquareManualCardComponent extends HandleSubscriptionsComponent implements OnInit, AfterViewInit {

  basicInfo: any;
  disablePayButton = false;
  
  selectedStore: IStore = null;
   
  payments: any;
  card: any;
  tokens: string[] = [];
  loading: boolean = false;

  appId:string = environment.squareCredentials.appId;
  locationId:string = environment.squareCredentials.locationId;

  @Input() data: any;

  constructor(
    private fb: UntypedFormBuilder,
    private cartResolver: CartResolver,
    private orderService: OrderService,
    private modalCtrl: ModalController,
    public toastMessage: ToastMessageService,
    private _basicInfoResolver: RestaurantInfoResolver,
    private _storeResolver: StoreResolver,
    private numberUtility: NumberUtility
  ) {
    super();
    
  }


  
  ngOnInit(): void {
    this.subscribeBasicInfo();
    this.subscribeSelectedStore();
  }

  ngAfterViewInit() {
    this.initializePayments();
  }

  /**
   * subscribe basic info
   */
  subscribeBasicInfo() {
    this.handleSubscription(this._basicInfoResolver.restaurantBasicInfo,
      (res) => {
        this.basicInfo = res;
      }
    );
  }

  
  /**
   * Subscribe selected store
   */
  subscribeSelectedStore() {
    this.handleSubscription(this._storeResolver.selectedStore,
      (res) => {
        if (res) {
          this.selectedStore = res;
        }
      }
    )
  }

  async initializePayments() {
    if (!window.Square) {
      throw new Error('Square.js failed to load properly');
    }

    try {
      this.payments = window.Square.payments(this.appId, this.locationId);
      this.card = await this.initializeCard(this.payments);
    } catch (e) {
      console.error('Initialization failed', e);
    }
  }

  async initializeCard(payments) {
    const card = await payments.card();
    await card.attach('#card-container');
    return card;
  }

  async handlePaymentMethodSubmission(event) {
    event.preventDefault();
    
    this.loading = true;
    const cardButton:any = document.getElementById('card-button') as HTMLButtonElement;
    cardButton.disabled = true;

    try {
      for (let i = 0; i < 3; i++) {
        const token = await this.tokenize(this.card);
        this.tokens.push(token);
        }
        // console.log('tokens=> ', this.tokens);
        this.chargeCard(this.tokens)
    } catch (e) {
      cardButton.disabled = false;
      console.error(e.message);
    }
  }

  async tokenize(paymentMethod) {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === 'OK') {
      return tokenResult.token;
    } else {
      throw new Error(`Tokenization failed with status: ${tokenResult.status}`);
    }
  }



  chargeCard(tokens: string[]): void {
    if (this.data.type === "pay by paymentIntent") {
      this.payPartialAmount(tokens);
    } else if (this.data.id) {
      this.paySavedOrderByOrderId(tokens);
    } else {
      this.payOrder(tokens);
    }
  }


  /**
   * pay order with out order id 
   * @param tokens
   */
  payOrder(tokens: string[]) {
    const onlinePaymentModel = {
      totalAmount: this.data.totalAmount,
      orderModel: this.data,
      squarePaymentModel: {
        paymentToken: tokens,
      },
    };
    this.handleSubscription(this.orderService
      .makeCreditCardPayment(onlinePaymentModel, 'Square'),
        (res) => {
          this.loading = false;
          this.data.id = res;
          this.postPayment();
        },
        (err) => {
          this.loading = false;
          this.disablePayButton = false;
          this.toastMessage.errorToastWithClose(
            'Error in creating order, If any money detected from your account will be refund.'
          );
        }
      );
  }




  /**
   * pay saved order
   * @param tokens 
   */
  paySavedOrderByOrderId(tokens: string[]) {
    const onlinePaymentModel = {
      totalAmount: this.data.totalAmount,
      processingFee: this.data.processingFee,
      platformFee: this.data.platformFee,
      squarePaymentModel: {
        paymentToken: tokens,
      }, 
    }
    this.handleSubscription(this.orderService
      .markOrderAsPaidBySquareManualCard(this.data.id, onlinePaymentModel),
        (res) => {
          this.loading = false;
          this.postPayment();
        },
        (err) => {
          this.loading = false;
          this.disablePayButton = false;
          this.toastMessage.errorToastWithClose(
            'Error in creating order, If any money detected from your account will be refund.'
          );
        }
      );
  }




  /**
   * pay partial amount 
   * @param tokens
   */
  payPartialAmount(tokens: string[]) {
    const onlinePaymentModel = {
      amount: this.numberUtility.roundToTwo(this.data.totalAmount),
      processingFee: this.data.processingFee,
      paymentType: 'square',
      platformFee: this.data.platformFee,
      storeId: this.selectedStore.id,
      squarePaymentModel: {
        paymentToken: tokens,
      },
    };

    this.handleSubscription(this.orderService
      .payPartialAmountBySquareManualCard(this.data.id, onlinePaymentModel),
        (res) => {
          this.loading = false;
          this.data.id = res;
          this.postPayment();
        },
        (err) => {
          this.loading = false;
          this.disablePayButton = false;
          this.toastMessage.errorToastWithClose(
            'Error in creating order, If any money detected from your account will be refund.'
          );
        }
      );

  }


  postPayment(): void {
    this.disablePayButton = false;
    this.cartResolver.cart.next([]);
    //this.toastMessage.successToastWithClose('Order is successfully placed');
    this.onCloseModel();
  }



  closeModal(): void {
    this.modalCtrl.dismiss();
  }

  onCloseModel(): void {
    this.modalCtrl.dismiss(this.data);
  }
}
