import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription, map } from 'rxjs';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';
import { IStore } from 'src/app/appModels/IStore';
import { PaymentGatewayResolver } from 'src/app/appResolvers/payment-gateway.resolver';
import { StoreResolver } from 'src/app/appResolvers/store.resolver';

@Component({
  selector: 'app-rethink-account-modal',
  templateUrl: './rethink-account-modal.component.html',
  styleUrls: ['./rethink-account-modal.component.scss'],
})
export class RethinkAccountModalComponent extends HandleSubscriptionsComponent implements OnInit {

  merchantId: string;
  productionKey: string;
  secretKey: string;
  adding:boolean = false;
  accounts: any[];
  loading: boolean = false;
  selectedStore: IStore;
  getAllAccountsSub:Subscription;

  @Input() selectedRethinkAccount: String;
  
  constructor(
    private modalCtrl: ModalController,
    private _storeResolver: StoreResolver,
    private _paymentGatewayResolver: PaymentGatewayResolver

  ) { 
    super();
  }

  ngOnInit() {
    this.subscribeSelectedStore();
    this.getAllAccounts();
  }


  /**
   * subscribe selected store
   */
  subscribeSelectedStore() {
    this.handleSubscription(this._storeResolver.selectedStore, 
    (res) => {
      if (res) {
        this.selectedStore= res;
      }
    });
  }


  /**
   * Get All Accounts
   */
  getAllAccounts() {
    this.getAllAccountsSub = this._paymentGatewayResolver.getAllRethinkPayAccounts()
    .subscribe(res => {
      this.accounts = res;
    })
  }


  /**
   * map rethink account to store
   * @param rethinkAccountId
   */
  mapToStore(rethinkAccountId){
    this.handleSubscription(this._storeResolver.mapRethinkAccountWithStore(this.selectedStore.id, rethinkAccountId), 
    (res) =>{
      this.modalCtrl.dismiss(rethinkAccountId);
    })
  }


  /**
   * add new rethink pay account
   */
  addNewAccount() {
    this.loading = true;
    const payload = {
      "merchantId": this.merchantId,
      "productionKey": this.productionKey,
      "secretKey": this.secretKey
    }
    this.handleSubscription(this._paymentGatewayResolver.addRethinkPaymentAccount(payload), 
    (res) => {
      this.getAllAccounts();
      this.adding = false;
      this.loading = false;
    });
  }


  onClose() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  /**
   * Destroy Component
   */
  ngOnDestroy(): void {
    this.getAllAccountsSub.unsubscribe();
  }

}
