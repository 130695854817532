import { Component, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-card-details',
  templateUrl: './card-details.page.html',
  styleUrls: ['./card-details.page.scss'],
})
export class CardDetailsPage implements OnInit {
  cardNumber: string;
  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
    this.cardNumber = localStorage.getItem("card-reader-number");
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  save() {
    if (!this.cardNumber) return;
    localStorage.setItem('card-reader-number', this.cardNumber);
    this.closeModal();
  }
}
