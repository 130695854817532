import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpConfigService } from 'src/app/core/httpConfig/httpConfigService';

@Injectable({
  providedIn: 'root'
})
export class TenantService {
 
  api2 = environment.baseUrl + "tenant";
  api = this.apiConfig.getBaseUrl + "tenant";


  constructor(private apiConfig: HttpConfigService) { }

  /**
   * Get alias
   * @returns 
   */
  getAlias(): Observable<any> {
    return this.apiConfig.get(`${this.api}/alias`);
  }



  /**
   * Create alias
   * @param alias
   * @returns
   */
  createAlias(alias: string): Observable<any> {
    return this.apiConfig.patch(`${this.api}/alias/${alias}`, null);
  }
    

  

}
