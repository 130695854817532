import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpConfigService } from '../core/httpConfig/httpConfigService';
import { ICartDetails } from '../appModels/ICartDetails';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  api = this.apiConfig.getBaseUrl + "cart-details";


  constructor(private apiConfig: HttpConfigService) {
  }

  getCart(): Observable<any> {
    return this.apiConfig.get(this.api, {withCredentials: true});
  }

  addToCart(item: ICartDetails): Observable<any> {
    return this.apiConfig.post(this.api, item, {withCredentials: true});
  }

  removeProduct(id: number): Observable<any> {
    return this.apiConfig.delete(this.api + "/" + id, {withCredentials: true});
  }

  updateProduct(cart): Observable<any> {
    return this.apiConfig.post(this.api, cart, {withCredentials: true});
  }

  getAllTableNo(): Observable<any> {
    return this.apiConfig.get(this.apiConfig.getBaseUrl+ "table");
  }

  getCouponCodesByClientType(storeId:number, currDate): Observable<any> {
    return this.apiConfig.get(`${this.apiConfig.getBaseUrl}promo-code/client-type/cashier/${storeId}?currDate=${currDate}`);
  }
  
  
  getCouponByCouponCode(storeId:number, couponCode:string, currDate:number): Observable<any>{
    // return this.apiConfig.get(this.apiConfig.getBaseUrl + "promo-code/client-type/" + "cashier?currDate=" +couponCode);

    return this.apiConfig.get(`${this.apiConfig.getBaseUrl}promo-code/cashier/${storeId}/${couponCode}?currDate=${currDate}`);
  }

}
