import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastMessageService {

  constructor(public toastController: ToastController) { }

  async errorToastWithClose(message: string) {
    const toast = await this.toastController.create({
      message: message,
      position: 'bottom',
      color: 'danger',
      duration: 5000,
      cssClass:'toast',
      mode: 'ios',
      htmlAttributes: { tabindex: undefined },
      buttons: [{
        text: 'Close',
        role: 'cancel'
      }
      ]
    });
    await toast.present();
  }

  async errorToastWith15Seconds(message: string) {
    const toast = await this.toastController.create({
      message: message,
      position: 'bottom',
      color: 'danger',
      duration: 15000, // Set duration to 15 seconds
      cssClass:'toast',
      mode: 'ios',
      htmlAttributes: { tabindex: undefined },
      buttons: [{
        text: 'Close',
        role: 'cancel'
      }]
    });
    await toast.present();
  }

  async errorToastWith10Seconds(message: string) {
    const toast = await this.toastController.create({
      message: message,
      position: 'bottom',
      color: 'danger',
      duration: 10000, // Set duration to 10 seconds
      cssClass:'toast',
      mode: 'ios',
      htmlAttributes: { tabindex: undefined },
      buttons: [{
        text: 'Close',
        role: 'cancel'
      }]
    });
    await toast.present();
  }

  async warningToastWithClose(message: string) {
    const toast = await this.toastController.create({
      message: message,
      position: 'bottom',
      color: 'warning',
      duration: 2000,
      cssClass:'toast',
      mode: 'ios',
      htmlAttributes: { tabindex: undefined },
      buttons: [{
        text: 'Close',
        role: 'cancel'
      }
      ]
    });
    await toast.present();
  }

  async warningToastWith15Seconds(message: string) {
    const toast = await this.toastController.create({
      message: message,
      position: 'bottom',
      color: 'warning',
      duration: 15000, // Set duration to 15 seconds
      cssClass:'toast',
      mode: 'ios',
      htmlAttributes: { tabindex: undefined },
      buttons: [{
        text: 'Close',
        role: 'cancel'
      }]
    });
    await toast.present();
  }

  async warningToastWith10Seconds(message: string) {
    const toast = await this.toastController.create({
      message: message,
      position: 'bottom',
      color: 'warning',
      duration: 10000, // Set duration to 10 seconds
      cssClass:'toast',
      mode: 'ios',
      htmlAttributes: { tabindex: undefined },
      buttons: [{
        text: 'Close',
        role: 'cancel'
      }]
    });
    await toast.present();
  }

  async successToastWith15Seconds(message: string) {
  const toast = await this.toastController.create({
    message: message,
    position: 'bottom',
    color: 'success',
    duration: 15000, // Set duration to 15 seconds
    cssClass:'toast',
    mode: 'ios',
    htmlAttributes: { tabindex: undefined },
    buttons: [{
      text: 'Close',
      role: 'cancel'
    }]
  });
  await toast.present();
}

async successToastWith10Seconds(message: string) {
   const toast = await this.toastController.create({
     message: message,
     position: 'bottom',
     color: 'success',
     duration: 10000, // Set duration to 10 seconds
     cssClass:'toast',
     mode: 'ios',
     htmlAttributes: { tabindex: undefined },
     buttons: [{
       text: 'Close',
       role: 'cancel'
     }]
   });
   await toast.present();
 }


  async successToastWithClose(message: string) {
    const toast = await this.toastController.create({
      message: message,
      position: 'bottom',
      color: 'success',
      duration: 2000,
      cssClass:'toast',
      mode: 'ios',
      htmlAttributes: { tabindex: undefined },
      buttons: [{
        text: 'Close',
        role: 'cancel'
      }
      ]
    });
    await toast.present();
  }

  async successToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      position: 'bottom',
      color: 'success',
      duration: 2000,
      mode: 'ios',
      cssClass:'toast',
      htmlAttributes: { tabindex: undefined },
    });
    await toast.present();
  }

  closeToaster() {
    this.toastController.dismiss();
  }

  async toast(message: string, button: string) {
    const toast = await this.toastController.create({
      message: message,
      position: 'bottom',
      duration: 5000,
      cssClass:'toast',
      mode: 'ios',
      htmlAttributes: { tabindex: undefined },
      buttons: [
        {
          side: 'end',
          text: button,
          role: 'button',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ]
    });
    await toast.present();

    // const { role } = await toast.onDidDismiss();
    return await toast.onDidDismiss();
  }

}
